import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { getUser } from "../../services/User";
import Menu from "../../components/Menu";
import BotMenu from "../../components/BotMenu";
import BottomMenu from "../../components/BottomMenu";
import NotificationBar from "../../components/NotificationBar";
import Loader from "../../components/Loader";
import MissingPage from "../../components/Missing";
import ScrollButton from "../../components/ScrollButton";
import Login from "./components/user/Login";
import LoginHash from "./components/user/login-hash/LoginHash";
import Forgot from "./components/user/Forgot";
import Register from "./components/user/Register";
import Profile from "./components/user/Profile";
import ProductHomePage from "./components/product/Home";
import ProductListPage from "./components/product/List";
import ProductCreatePage from "./components/product/Create";
import ProductEditPage from "./components/product/Edit";
import PromosListPage from "./components/promo/List";
import TrackerListPage from "./components/tracker/List";
import CouponListPage from "./components/coupon/List";
import CouponCreatePage from "./components/coupon/Create";
import CouponEditPage from "./components/coupon/Edit";
import BillingListPage from "./components/billing/List";
import ApprovalListPage from "./components/approval/List";
import AdminHomePage from "./components/admin/Home";
import AdminLogsPage from "./components/admin/logs/Logs";
import AdminBulkPage from "./components/admin/bulk/Bulk";
import AdminUsersPage from "./components/admin/users/Users";
import UserPendingPage from "./components/admin/users/Pending";
import AdminPaymentPage from "./components/admin/payment/Payment";
import PaymentMethodEditPage from "./components/admin/payment/Edit";
import AdminWebLandingPage from "./components/admin/web-landing/WebLanding";
import WebLandingEditPage from "./components/admin/web-landing/Edit";
import AdminPredictContestPage from "./components/admin/predict-contest/List";
import PredictContestEditPage from "./components/admin/predict-contest/Edit";
import PredictContestCreatePage from "./components/admin/predict-contest/Create";
import PredictionsPage from "./components/admin/predict-contest/Predictions";

const STATE = {
  user: { meta: {} },
};

const App = () => {
  const [publicView, setPublicView] = useState(true);
  const [isModerating, setIsModerating] = useState(false);
  const [user, setUser] = useState(STATE.user);
  const [isLoading, setLoader] = useState(false);
  const [notifications, notify] = useState({ visible: false, text: "" });

  const setAlert = (text) => notify({ visible: true, text });
  const unsetAlert = () => notify({ visible: false, text: "" });

  useEffect(() => {
    getUser().then((user) => {
      if (user.moderating) {
        setUser(user.moderating);
        setIsModerating(true);
      } else {
        setUser(user);
        setIsModerating(false);
      }
    });

    setPublicView(/login|register|forgot/.test(window.location.pathname));
  }, []);

  return (
    <>
      <Menu user={user} isModerating={isModerating} setAlert={setAlert} />
      <div className="separator"></div>
      <Routes>
        <Route
          path="/"
          element={
            <ProductHomePage setAlert={setAlert} setLoader={setLoader} />
          }
        />
        <Route path="/login" element={<Login setAlert={setAlert} />} />
        <Route
          path="/login-hash"
          element={<LoginHash setLoader={setLoader} setAlert={setAlert} />}
        />
        <Route path="/forgot" element={<Forgot setAlert={setAlert} />} />
        <Route path="/forgot/:hash" element={<Forgot setAlert={setAlert} />} />
        <Route path="/register" element={<Register setAlert={setAlert} />} />
        <Route
          path="/edit-profile"
          element={<Profile setLoader={setLoader} setAlert={setAlert} />}
        />
        <Route
          path="/products"
          element={
            <ProductHomePage setAlert={setAlert} setLoader={setLoader} />
          }
        />
        <Route
          path="/suscriptions"
          element={<ProductListPage setAlert={setAlert} type="suscription" />}
        />
        <Route
          path="/tips"
          element={<ProductListPage setAlert={setAlert} type="tip" />}
        />
        <Route
          path="/products/create/:type"
          element={
            <ProductCreatePage setLoader={setLoader} setAlert={setAlert} />
          }
        />
        <Route
          path="/products/:productId"
          element={<ProductEditPage setAlert={setAlert} />}
        />
        <Route
          path="/promos"
          element={<PromosListPage setAlert={setAlert} />}
        />
        <Route
          path="/trackers"
          element={<TrackerListPage setAlert={setAlert} />}
        />
        <Route
          path="/coupons"
          element={<CouponListPage setLoader={setLoader} setAlert={setAlert} />}
        />
        <Route
          path="/coupons/create"
          element={
            <CouponCreatePage setLoader={setLoader} setAlert={setAlert} />
          }
        />
        <Route
          path="/coupons/:couponId"
          element={<CouponEditPage setLoader={setLoader} setAlert={setAlert} />}
        />
        <Route
          path="/approvals"
          element={
            <ApprovalListPage setLoader={setLoader} setAlert={setAlert} />
          }
        />
        <Route
          path="/billing"
          element={<BillingListPage setAlert={setAlert} />}
        />
        <Route
          path="/admin"
          element={<AdminHomePage setLoader={setLoader} setAlert={setAlert} />}
        />
        <Route
          path="/admin/logs"
          element={<AdminLogsPage setAlert={setAlert} />}
        />
        <Route
          path="/admin/bulk"
          element={<AdminBulkPage setAlert={setAlert} />}
        />
        <Route
          path="/admin/users"
          element={<AdminUsersPage setAlert={setAlert} />}
        />
        <Route
          path="/admin/users/:userId"
          element={
            <UserPendingPage setLoader={setLoader} setAlert={setAlert} />
          }
        />
        <Route
          path="/admin/web-landing"
          element={<AdminWebLandingPage setAlert={setAlert} />}
        />
        <Route
          path="/admin/web-landing/:id"
          element={
            <WebLandingEditPage setLoader={setLoader} setAlert={setAlert} />
          }
        />
        <Route
          path="/admin/predict-contest"
          element={
            <AdminPredictContestPage
              setLoader={setLoader}
              setAlert={setAlert}
            />
          }
        />
        <Route
          path="/admin/predict-contest/create"
          element={
            <PredictContestCreatePage
              setLoader={setLoader}
              setAlert={setAlert}
            />
          }
        />
        <Route
          path="/admin/predict-contest/:id"
          element={
            <PredictContestEditPage setLoader={setLoader} setAlert={setAlert} />
          }
        />
        <Route
          path="/admin/predict-contest/:id/predictions"
          element={
            <PredictionsPage setLoader={setLoader} setAlert={setAlert} />
          }
        />
        <Route
          path="/admin/payment"
          element={<AdminPaymentPage setAlert={setAlert} />}
        />
        <Route
          path="/admin/payment/:methodName"
          element={
            <PaymentMethodEditPage setLoader={setLoader} setAlert={setAlert} />
          }
        />

        {/* catch all */}
        <Route path="*" element={<MissingPage />} />
      </Routes>
      <ScrollButton />
      <div style={{ height: 90 }} />
      {!publicView && user.email ? <BotMenu user={user} /> : ""}
      {!publicView && user.email ? <BottomMenu /> : ""}
      <Loader isLoading={isLoading} />
      <NotificationBar {...notifications} unsetAlert={unsetAlert} />
    </>
  );
};

export default App;
