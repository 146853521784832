import { getApiUri } from "../Utils";

export const fetchOrder = async (type, payload) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/landings/" + type;

  const response = await fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};
