import { useLanguage } from "../languages/useLanguage";

const MissingPage = () => {
  const { language } = useLanguage();

  return (
    <div className="container">
      <h1 className="text-warning mt-5">404</h1>
      <h3 className="text-info mt-2">
        {language.words.components.general.missing.PAGE_NOT_FOUND}
      </h3>
    </div>
  );
};

export default MissingPage;
