import React, { useState } from "react";
import config from "../../../../Config";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../../../../services/User";
import { sendLog } from "../../../../services/App";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useLanguage } from "../../../../languages/useLanguage";

export default function RegisterPage({ setAlert }) {
  const { language } = useLanguage();
  const [form, setState] = useState({});
  const navigate = useNavigate();

  const update = (event) => {
    const payload = { ...form };

    payload[event.target.name] = event.target.value;

    setState(payload);
  };

  const send = async () => {
    try {
      if (form.password && form.password !== form._password) {
        return setAlert(
          language.words.components.user.register.PASSWORDS_NOT_MATCH
        );
      }

      if (!form.email || !form.password || !form.fullNumber) {
        return setAlert(
          language.words.components.user.register.COMPLETE_FIELDS
        );
      }

      await register(form);

      setAlert(language.words.components.user.register.ALERT_EMAIL_SENT);

      navigate(config.client.uris.login);
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message || error);
    }
  };

  return (
    <div className="login-page" onKeyDown={(e) => e.key === "Enter" && send()}>
      <div className="card mx-auto border-0">
        <div className="card-header border-bottom-0 bg-transparent">
          <ul
            className="nav nav-tabs justify-content-center pt-5"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item">
              <Link className="nav-link" to="/login">
                {language.words.components.user.register.LOGIN}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link active"
                id="pills-register-tab"
                to="/register"
              >
                {language.words.components.user.register.NEW_USER}
              </Link>
            </li>
          </ul>
        </div>
        <div className="container">
          <div className="gray-block">
            <div className="card-body pb-4">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane show active"
                  id="pills-register"
                  role="tabpanel"
                  aria-labelledby="pills-register-tab"
                >
                  <div className="form-group">
                    <label className="control-label" htmlFor="firstname">
                      {language.words.components.user.register.NAME_AND_SURNAME}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder={
                        language.words.components.user.register.ENTER_FULL_NAME
                      }
                      onChange={update}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="nick">
                      {language.words.components.user.register.NICK}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="nick"
                      id="nick"
                      placeholder={
                        language.words.components.user.register.ENTER_NICK
                      }
                      onChange={update}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="phoneNumber">
                      {`${language.words.components.user.register.PHONE_NUMBER} *`}
                    </label>
                    <div className="form-control">
                      <PhoneInput
                        id="phoneNumber"
                        name="fullNumber"
                        onChange={(value) =>
                          update({ target: { name: "fullNumber", value } })
                        }
                        defaultCountry="ES"
                        value={form.fullNumber}
                        placeholder={
                          language.words.components.user.register.PHONE_NUMBER
                        }
                      />
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label className="control-label" htmlFor="betsfyId">
                      {`${language.words.components.user.register.BETSFY_ID} *`}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="betsfyId"
                      id="betsfyId"
                      placeholder={
                        language.words.components.user.register.ENTER_BETSFY_ID
                      }
                      onChange={update}
                    />
                  </div> */}
                  <div className="form-group">
                    <label className="control-label" htmlFor="email">
                      {`${language.words.components.user.register.EMAIL} *`}
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      id="email"
                      placeholder={
                        language.words.components.user.register.EMAIL
                      }
                      onChange={update}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="password">
                      {`${language.words.components.user.register.PASSWORD} *`}
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      id="password"
                      placeholder={
                        language.words.components.user.register.PASSWORD
                      }
                      onChange={update}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="_password">
                      {`${language.words.components.user.register.REPEAT_PASSWORD} *`}
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      name="_password"
                      id="_password"
                      placeholder={
                        language.words.components.user.register.REPEAT_PASSWORD
                      }
                      onChange={update}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="btn btn-primary submit"
                      type="button"
                      value={language.words.components.user.register.REGISTER}
                      onClick={send}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
