import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../../services/User";
import { sendLog } from "../../../../services/App";
import { getPromosLanding } from "../../../../services/Promo";
import Card from "./components/Card";
import "./List.css";
import { useLanguage } from "../../../../languages/useLanguage";
import PromoInfoModal from "../tracker/components/PromoInfoModal";
import CountryFilterModal from "../tracker/components/CountryFilterModal";
import usePromosFilter from "./hooks/usePromosFilter";

export default function PromoListPage() {
  const { language } = useLanguage();
  const [promos, setPromos] = useState([]);
  const navigate = useNavigate();

  const { filter, setFilter, filteredPromos } = usePromosFilter(promos || []);
  const [showCountryFilter, setShowCountryFilter] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  const [promoId, setPromoId] = useState("");

  const fetchPromos = async () => {
    const user = await getUser();

    if (!user) throw new Error("Invalid user");

    const _promos = await getPromosLanding("spain");

    return _promos;
  };

  const refreshPromos = () => {
    fetchPromos()
      .then(setPromos)
      .catch((error) => {
        sendLog("error", error);
      });
  };

  useEffect(refreshPromos, []);

  return (
    <div className="container main promos home list">
      <PromoInfoModal
        {...{
          show: showPromo,
          setShow: setShowPromo,
          promoId,
        }}
      />
      <CountryFilterModal
        {...{
          show: showCountryFilter,
          setShow: setShowCountryFilter,
          filter,
          setFilter,
        }}
      />
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div
            className="h1-header"
            style={{ backgroundColor: "#121723 !important" }}
          >
            {language.words.components.promo.list.AVAILABLE_PROMOS}
          </div>
          <div className="separator" />
        </div>
        <div className="trackers-filters">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4"></div>
            <div className="col-4">
              <div
                className={
                  "filter" +
                  (filter.country.length === 0 ? " clear" : " active")
                }
                onClick={() => setShowCountryFilter(true)}
              >
                {`${language.words.components.tracker.list.COUNTRY}: `}
                <span
                  className={
                    "bi bi-caret-" + (showCountryFilter ? "up" : "down")
                  }
                ></span>
              </div>
            </div>
          </div>
        </div>
        {filteredPromos.map((promo) => (
          <Card
            key={"promo-" + promo._id}
            promo={promo}
            setShowPromo={setShowPromo}
            setPromoId={setPromoId}
          />
        ))}
      </div>
    </div>
  );
}
