import Logo from "../../../../../../components/Logo";
import "./Header.css";

const Header = () => {
  return (
    <div className="header">
      <div className="logo">
        <Logo />
        <div className="separator" />
      </div>
    </div>
  );
};

export default Header;
