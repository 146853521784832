import moment from "moment";
import { useLanguage } from "../../../../../languages/useLanguage";
import CardOrderDetails from "./CardOrderDetails";
import "./DetailsModal.css";

export default function BillingDetailsModal({ show, setShow, orders, range }) {
  const { language } = useLanguage();

  const toggleShow = () => {
    setShow((prev) => !prev);
  };

  return (
    <div
      className={"billing modal fade" + (show && " show")}
      style={{
        display: "block",
        visibility: show ? "visible" : "hidden",
        maxHeight: "100vh",
        maxWidth: "100vw",
      }}
      onClick={toggleShow}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <div className="container-fluid">
              <div className="row">
                <div className="container-fluid">
                  <div className="separator" />
                  <div className="h1-header">
                    {`${language.words.components.billing.details.SALES_OF} ${orders[0]?.product?.title}`}
                  </div>
                  <div className="separator" />
                  <div>
                    {`${
                      language.words.components.billing.details.FROM
                    } ${moment(range.from).format("DD/MM/YYYY HH:mm:ss")} ${
                      language.words.components.billing.details.TO
                    } ${moment(range.to).format("DD/MM/YYYY HH:mm:ss")}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-body"
            style={{
              maxHeight: "65vh",
              overflowY: "auto",
            }}
          >
            <div className="gray-block">
              {orders && orders.length > 0 && (
                <div className="container-fluid">
                  <div className="row">
                    <div className="container-fluid">
                      {orders.map((order, index) => {
                        return (
                          <CardOrderDetails
                            key={"order-detail-" + index}
                            order={order}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={toggleShow}
            >
              {language.words.components.billing.details.CLOSE}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
