import { saveAs } from "file-saver";
import { getApiUri } from "../Utils";

export const downloadLogs = async () => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/logs/download", {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const filename = "logs_" + new Date().toLocaleString().replace(/(, )/, "_");

  saveAs(await response.blob(), filename);
};

export const getLogs = async () => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/logs", {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const exportCSV = async (type) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/bulk/" + type + "/export", {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const blob = await response.blob();

  const fileDict = {
    billing: "pedidos",
    promos: "acuerdos",
    trackers: "trackers",
    users: "usuarios",
    "affiliation-reports": "informe_afiliación",
  };

  const filename = `${fileDict[type]}-${new Date()
    .toLocaleString()
    .replace(/(\/|,|:)/g, "-")
    .replace(/( )/g, "")}.csv`;

  saveAs(blob, filename);
};

export const importCSV = async (type, formData) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/bulk/" + type + "/import", {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: formData,
  });

  if (response.status !== 200) {
    console.log(response.status);
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const banUser = async (user) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/ban/" + user, {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const getUsers = async () => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/users", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const updateUser = async (userId, payload) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/users/" + userId + "/update", {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const moderateUser = async (userId) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/users/moderate", {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ userId }),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const stopModeration = async () => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/users/moderate", {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const getUserById = async (userId) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/users/" + userId, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const setUserPendingIncomplete = async (userId, payload) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/users/" + userId, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const setUserPendingActive = async (userId, payload) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(
    apiUri + "/admin/users/" + userId + "/pending/activate",
    {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(payload),
    }
  );

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const setUserActive = async (userId) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(
    apiUri + "/admin/users/" + userId + "/activate",
    {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({}),
    }
  );

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const getPaymentMethods = async () => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/payment-methods", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const getPaymentMethod = async (methodName) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(
    apiUri + "/admin/payment-methods/" + methodName,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const updatePaymentMethod = async (methodName, payload) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(
    apiUri + "/admin/payment-methods/" + methodName,
    {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(payload),
    }
  );

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const getWebLandings = async () => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/web-landing", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const getWebLanding = async (id) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/web-landing/" + id, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const updateWebLanding = async (id, payload) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/admin/web-landing/" + id, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const getPredictContests = async () => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/predict-contest", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const createPredictContest = async (payload) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/predict-contest";

  const response = await fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const getPredictContest = async (id) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/predict-contest/" + id, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const updatePredictContest = async (id, payload) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/predict-contest/" + id;

  const response = await fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const deletePredictContest = async (id) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/predict-contest/" + id;

  const response = await fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const getActivePredictContests = async () => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/predict-contest/active", {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const createPrediction = async (id, payload) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/predict-contest/" + id + "/predictions";

  const response = await fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const exportPredictions = async (id) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(
    apiUri + "/predict-contest/" + id + "/predictions/download",
    {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const blob = await response.blob();

  const filename = `predicciones-${new Date()
    .toLocaleString()
    .replace(/(\/|,|:)/g, "-")
    .replace(/( )/g, "")}.csv`;

  saveAs(blob, filename);
};
