import { useEffect } from "react";
import packageJson from "../package.json";
import { getApp } from "./Utils";
import languages from "./languages/languages";
import { LanguageProvider } from "./languages/useLanguage";
import "./App.css";

const App = () => {
  const CurrentApp = getApp();
  const defaultLanguage = languages[0];

  useEffect(() => {
    let version = localStorage.getItem("version");

    if (version !== packageJson.version) {
      if ("caches" in window) {
        console.log(caches);
        caches.keys().then((names) => {
          // delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // reload page
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem("version", packageJson.version);
    }
  }, []);

  return (
    <LanguageProvider
      defaultValue={defaultLanguage}
      persisted
      languages={languages}
    >
      <div className="App">
        <CurrentApp />
      </div>
    </LanguageProvider>
  );
};

export default App;
