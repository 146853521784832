import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../../../../languages/useLanguage";
import {
  exportPredictions,
  getPredictContest,
} from "../../../../../services/Admin";
import { getUser } from "../../../../../services/User";
import "./Predictions.css";

const PredictionsPage = ({ setLoader, setAlert }) => {
  const { language } = useLanguage();
  const [contest, setContest] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  const downloadPredictions = async () => {
    try {
      await exportPredictions(id);
    } catch (error) {
      setAlert(error?.message || error);
    }
  };

  // get contest
  useEffect(() => {
    const fetchContest = async () => {
      try {
        setLoader(true);
        const user = await getUser();

        if (!user) new Error(language.words.errors.INVALID_USER);

        const response = await getPredictContest(id);

        setContest(response);
      } catch (error) {
        setAlert(error?.message || error);
      } finally {
        setLoader(false);
      }
    };

    if (id) {
      fetchContest();
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container main products list">
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        {contest._id && (
          <>
            <div className="container-fluid">
              <div className="separator" />
              <div className="h1-header">
                {moment(contest.expirationDate).format("DD/MM/YYYY hh:mm")}
              </div>
              <div className="separator" />
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="container-fluid">
                  {contest.predictions && contest.predictions.length > 0 ? (
                    <>
                      <button
                        className="btn btn-primary"
                        onClick={downloadPredictions}
                      >
                        {
                          language.words.components.admin.predictContest
                            .predictions.DOWNLOAD
                        }
                      </button>
                      <div className="separator" />
                      <div className="table-responsive">
                        <table className="table table-hover table-striped">
                          <thead
                            className="bg-primary user-select-none"
                            style={{ position: "sticky", top: 0 }}
                          >
                            <tr className="text-center">
                              <th scope="col">
                                {
                                  language.words.components.admin.predictContest
                                    .predictions.EMAIL
                                }
                              </th>
                              <th scope="col">
                                {
                                  language.words.components.admin.predictContest
                                    .predictions.TIPSTER_ID
                                }
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {contest.predictions.map((prediction) => {
                              return (
                                <tr key={prediction._id}>
                                  <td>{prediction.email}</td>
                                  <td className="text-center">{prediction.referrer}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <div className="text-center">
                      <span className="text-info">
                        {
                          language.words.components.admin.predictContest
                            .predictions.NO_PREDICTIONS
                        }
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PredictionsPage;
