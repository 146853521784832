import "./Footer.css";

const Footer = () => {
  return (
    <div className="container-fluid">
      <div className="footer">
        <div className="legal">
          <a
            href="https://www.juegoseguro.es/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/jugarseguro-logo.png`}
              alt="jugarseguro"
            />
          </a>
          <a
            href="https://www.ordenacionjuego.es/es/rgiaj"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/autoprohibicion-logo-blanco.png`}
              alt="autoprohibición"
            />
          </a>
          <a href="https://jugarbien.es/" target="_blank" rel="noreferrer">
            <img
              src={`${process.env.PUBLIC_URL}/images/jugarbien-logo-blanco.webp`}
              alt="jugarbien.es"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
