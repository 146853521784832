import React, { useState } from "react";
import { Link } from "react-router-dom";
import { login } from "../../../../services/User";
import { sendLog } from "../../../../services/App";
import config from "../../../../Config";
import { useLanguage } from "../../../../languages/useLanguage";

export default function LoginPage({ setAlert }) {
  const { language } = useLanguage();
  const [form, setState] = useState({});

  const update = (event) => {
    const payload = { ...form };

    payload[event.target.name] = event.target.value;

    setState(payload);
  };

  const send = async () => {
    try {
      if (!form.email || !form.password) {
        return setAlert(language.words.components.user.login.COMPLETE_FIELDS);
      }

      const user = await login(form);

      if (!user)
        return setAlert(language.words.components.user.login.INVALID_DATA);

      window.location.replace(config.client.uris.products);
    } catch (error) {
      sendLog("error", error);

      setAlert(error.message);
    }
  };

  return (
    <div className="login-page" onKeyDown={(e) => e.key === "Enter" && send()}>
      <div className="card mx-auto border-0">
        <div className="card-header border-bottom-0 bg-transparent">
          <ul
            className="nav nav-tabs justify-content-center pt-5"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item">
              <Link className="nav-link active" to="/login">
                {language.words.components.user.login.LOGIN}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" id="pills-register-tab" to="/register">
                {language.words.components.user.login.NEW_USER}
              </Link>
            </li>
          </ul>
        </div>
        <div className="container">
          <div className="gray-block">
            <div className="card-body pb-4">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane show active"
                  id="pills-login"
                  role="tabpanel"
                  aria-labelledby="pills-login-tab"
                >
                  <div className="form-group">
                    <label className="control-label" htmlFor="email">
                      {language.words.components.user.login.EMAIL}
                    </label>
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      name="email"
                      placeholder={
                        language.words.components.user.login.WRITE_YOUR_EMAIL
                      }
                      autoFocus={true}
                      onChange={update}
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label" htmlFor="passord">
                      {`${language.words.components.user.login.PASSWORD} *`}
                    </label>
                    <input
                      className="form-control"
                      id="password"
                      type="password"
                      name="password"
                      placeholder="Pon tu contraseña"
                      onChange={update}
                    />
                    <div className="text-center pt-3">
                      <Link className="text-white" to="/forgot">
                        {language.words.components.user.login.RECOVER_PASSWORD}
                      </Link>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      className="btn btn-primary submit"
                      type="button"
                      value={language.words.components.user.login.LOGIN}
                      onClick={send}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
