import React from "react";
import "./NotificationBarB.css";

export default function NotificationBar({ visible, text, unsetAlert }) {
  if (!visible || !text) return "";

  return (
    <div className="notification-b-container">
      <div className="notification-b-bar">
        <input
          type="button"
          className="btn btn-primary inverted close-notification"
          onClick={unsetAlert}
          defaultValue="Cerrar"
        />
        <div className="row">
          <div className="container-fluid">
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </div>
      </div>
    </div>
  );
}
