import { AppRouter, BRouter, PayRouter } from "./routers";
import config from "./Config";

export const randomIntBy = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const copyToClipboard = (event, text) => {
  const currentValue = event.target.value;

  navigator.clipboard.writeText(text);

  event.target.value = "Copiado";
  event.target.style.background = "#3DBA86";
  event.target.parentNode.style.background = "#3DBA86";

  setTimeout(() => {
    event.target.value = currentValue;
    event.target.style.background = "#ffffff";
    event.target.parentNode.style.background = "#ffffff";
  }, 1500);
};

export const APPS = [
  {
    subdomain: config.client.subdomains.bot.value,
    app: AppRouter,
    main: true,
  },
  {
    subdomain: config.client.subdomains.pay.value,
    app: PayRouter,
    main: false,
  },
  {
    subdomain: config.client.subdomains.b.value,
    app: BRouter,
    main: false,
  },
];

export const getApp = () => {
  const subdomain = getSubdomain(window.location.host);

  const main = APPS.find((app) => app.main);

  if (!main) throw new Error("Must have main app");

  if (subdomain === "") return main.app;

  const app = APPS.find((app) => subdomain === app.subdomain);

  if (!app) return main.app;

  return app.app;
};

export const getSubdomain = (location) => {
  const locationParts = location.split(".");

  let sliceTill = -2;

  // for localhost
  const isLocalhost = /localhost/.test(locationParts.slice(-1)[0]);

  if (isLocalhost) sliceTill = -1;

  return locationParts.slice(0, sliceTill).join("");
};

export const getDomain = (location) => {
  const locationParts = location.split(".");

  const isLocalhost = /localhost/.test(locationParts.slice(-1)[0]);

  let sliceFrom = 1;
  let sliceTill = -1;

  if (isLocalhost) sliceTill = null;

  let secondLevelDomain = sliceTill
    ? locationParts.slice(sliceFrom, sliceTill).join(".")
    : locationParts.slice(sliceFrom).join(".");

  if (secondLevelDomain === "") {
    secondLevelDomain = "localhost";
  }

  let domain = locationParts.slice(sliceFrom).join(".");

  if (domain === "") {
    domain = locationParts.slice(-1)[0];
  }

  return { domain, secondLevelDomain };
};

export const getApiUri = (hostname) => {
  const domain = getDomain(hostname).secondLevelDomain;

  return config.server[domain].uri;
};

export const getUploadUri = (hostname) => {
  const domain = getDomain(hostname).secondLevelDomain;

  return config.server[domain].upload;
};

export const getPublicUri = (hostname) => {
  const domain = getDomain(hostname).secondLevelDomain;

  return config.server[domain].publicStatic;
};

export const getPrivateUri = (hostname) => {
  const domain = getDomain(hostname).secondLevelDomain;

  return config.server[domain].privateStatic;
};
