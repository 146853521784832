import { Link } from "react-router-dom";
import config from "../../../../../../Config";
import { useLanguage } from "../../../../../../languages/useLanguage";
import { getPublicUri } from "../../../../../../Utils";
import "./Card.css";

const CardComponent = ({ webLanding }) => {
  const { language } = useLanguage();

  return (
    <div className="list-card">
      <div className="row">
        <div className="container-fluid">
          <div className="h1-header">{webLanding.name}</div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="container-fluid">
          <div>
            {`${language.words.components.admin.webLandingCard.HEADER_IMAGE}: `}
            <b>
              {webLanding.header.image ? (
                <img
                  className="header-image"
                  src={
                    getPublicUri(window.location.hostname) +
                    "/" +
                    webLanding.header.image
                  }
                  alt={webLanding.name}
                />
              ) : (
                language.words.components.admin.webLandingCard.NO_HEADER_IMAGE
              )}
            </b>
          </div>
          <div>
            {`${language.words.components.admin.webLandingCard.HEADER_BACKGROUND}: `}
            <span
              className="color-sample"
              style={{ backgroundColor: webLanding.header.background }}
            >
              &nbsp;
            </span>
            <b>{webLanding.header.background}</b>
          </div>
          <div>
            {`${language.words.components.admin.webLandingCard.CONTENT_BACKGROUND}: `}
            <span
              className="color-sample"
              style={{ backgroundColor: webLanding.content.background }}
            >
              &nbsp;
            </span>
            <b>{webLanding.content.background}</b>
          </div>
          <div>
            {`${language.words.components.admin.webLandingCard.CARD_TYPE}: `}
            <b>{webLanding.content.card}</b>
          </div>
        </div>
      </div>
      <div className="separator" />
      <div className="status-wrapper">
        <div className="status-info success">
          {`${language.words.components.admin.webLandingCard.TIPSTERS}: 5`}
        </div>
        <div className="status-wrapper">
          <Link
            className="btn btn-success"
            to={config.client.uris.adminWebLanding + "/" + webLanding._id}
          >
            <span className="bi bi-pencil"></span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
