import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../../../Config";
import { getUser } from "../../../../services/User";
import { sendLog } from "../../../../services/App";
import { getApprovals } from "../../../../services/Approval";
import Card from "./components/Card";
import ApprovalDetailsModal from "./components/DetailsModal";
import "./List.css";
import { useLanguage } from "../../../../languages/useLanguage";

export default function ApprovalListPage({ setLoader, setAlert }) {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [approvals, setApprovals] = useState([]);
  const [menu, setMenu] = useState("in_progress");

  const [showDetails, setShowDetails] = useState(false);
  const [approvalId, setApprovalId] = useState("");

  const fetchInProgressApprovals = async () => {
    const user = await getUser();

    if (!user) throw new Error(language.words.errors.INVALID_USER);

    const _approvals = await getApprovals("in_progress");

    return _approvals;
  };

  const fetchApprovals = async () => {
    const user = await getUser();

    if (!user) throw new Error(language.words.errors.INVALID_USER);

    const _approvals = await getApprovals();

    return _approvals;
  };

  useEffect(() => {
    if (menu === "in_progress") {
      fetchInProgressApprovals()
        .then(setApprovals)
        .catch((error) => {
          sendLog("error", error);

          window.location.replace(config.client.uris.login);
        });
    } else if (menu === "history") {
      fetchApprovals()
        .then(setApprovals)
        .catch((error) => {
          sendLog("error", error);

          window.location.replace(config.client.uris.login);
        });
    }
  }, [menu, showDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container main approvals home list">
      <ApprovalDetailsModal
        {...{
          setLoader,
          setAlert,
          show: showDetails,
          setShow: setShowDetails,
          approvalId,
        }}
      />
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="home-header">
        <div
          className={
            "h1-header home-header-title home-header-pill" +
            (menu === "in_progress" ? " active" : " inactive")
          }
          onClick={() => setMenu("in_progress")}
        >
          {language.words.components.approval.list.MY_REQUESTS}
        </div>
        <div
          className={
            "h1-header home-header-title home-header-pill" +
            (menu === "history" ? " active" : " inactive")
          }
          onClick={() => setMenu("history")}
        >
          {language.words.components.approval.list.REQUEST_HISTORY}
        </div>
        <div
          className={
            "gray-block" +
            (menu === "in_progress" ? " left-menu" : " right-menu")
          }
        >
          {approvals.map((approval) => (
            <Card
              key={"approval-" + approval._id}
              approval={approval}
              setShowDetails={setShowDetails}
              setApprovalId={setApprovalId}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
