import { useEffect } from "react";
import { useState } from "react";

const usePromosFilter = (promos) => {
  const [filteredPromos, setFilteredPromos] = useState(promos);
  const [filter, setFilter] = useState({
    countries: [],
    country: [],
    sites: [],
    site: [],
    types: [],
    type: [],
  });

  useEffect(() => {
    setFilteredPromos(
      promos.filter((promo) => {
        const countryFilter =
          filter.country.length > 0 ? filter.country : filter.countries;
        const siteFilter = filter.site.length > 0 ? filter.site : filter.sites;
        const typeFilter = filter.type.length > 0 ? filter.type : filter.types;

        return (
          countryFilter.some((country) => country === promo.country) &&
          siteFilter.some((site) => site.bettingSite === promo.bettingSite) &&
          typeFilter.some((type) => type === promo.type)
        );
      })
    );
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const countries = [];
    const sites = [];
    const types = [];

    promos.forEach((promo) => {
      if (!countries.some((country) => country === promo.country)) {
        countries.push(promo.country);
      }
      if (!sites.some((site) => site.bettingSite === promo.bettingSite)) {
        sites.push({
          bettingSite: promo.bettingSite,
          image: promo.image,
        });
      }
      if (!types.some((type) => type === promo.type)) {
        types.push(promo.type);
      }
    });

    setFilter((prev) => {
      return {
        countries: countries,
        country: prev.country,
        sites: sites,
        site: prev.site,
        types: types,
        type: prev.type,
      };
    });
  }, [promos]);

  return { filter, setFilter, filteredPromos };
};

export default usePromosFilter;
