import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../../../../../Config";
import { useLanguage } from "../../../../../languages/useLanguage";
import { getPredictContests } from "../../../../../services/Admin";
import { getUser } from "../../../../../services/User";
import Card from "./components/Card";

const AdminPredictContestPage = ({ setLoader, setAlert }) => {
  const { language } = useLanguage();
  const [contests, setContests] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContests = async () => {
      try {
        const user = await getUser();

        if (!user) new Error(language.words.errors.INVALID_USER);

        const response = await getPredictContests();

        setContests(response);
      } catch (error) {
        setAlert(error?.message || error);
      }
    };

    fetchContests();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container main products list">
      <div
        className="btn-navigate-back"
        onClick={() => navigate(config.client.uris.admin)}
      >
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {
              language.words.components.admin.predictContest.list
                .PREDICT_CONTESTS
            }
          </div>
          <div className="separator" />
        </div>
        {contests &&
          contests.length > 0 &&
          contests.map((contest) => {
            return (
              <Card
                key={"predict-contest-" + contest._id}
                _contest={contest}
                setLoader={setLoader}
                setAlert={setAlert}
              />
            );
          })}
      </div>
      <Link type="button" className="btn btn-primary sticky-button" to="create">
        {
          language.words.components.admin.predictContest.list
            .CREATE_PREDICT_CONTEST
        }
      </Link>
    </div>
  );
};

export default AdminPredictContestPage;
