import { getPublicUri } from "../../../../../Utils";
import "./Card.css";

export default function CardComponent({ promo, setPromoId, setShowPromo }) {
  return (
    <div className="list-card-promo promo">
      <div className="betting-site">
        <div className="betting-site-image">
          <img
            src={
              getPublicUri(window.location.hostname) +
              "/betting-sites/" +
              promo?.image
            }
            alt={promo?.bettingSite}
          />
        </div>
      </div>
      <div className="promo-info">
        <div className="country-i">
          <div className="country-image">
            <img
              src={
                getPublicUri(window.location.hostname) +
                "/countries/" +
                promo?.country?.replace(/ /g, "_") +
                ".png"
              }
              alt={promo?.country}
            />
          </div>
          <div className="info-icon-wrapper">
            <span
              className="info-icon"
              onClick={() => {
                setPromoId(promo?._id || "");
                setShowPromo(true);
              }}
            >
              i
            </span>
          </div>
        </div>
        <div className="conditions">
          <p>{promo?.summary?.replace(/<br>/g, "\n")}</p>
        </div>
      </div>
    </div>
  );
}
