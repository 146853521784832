import { sendLog } from "./App";
import config from "../Config";
import { getApiUri } from "../Utils";

export const getUser = async () => {
  try {
    const apiUri = getApiUri(window.location.hostname);
    const uri = apiUri + "/users";

    const response = await fetch(uri, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status !== 200) {
      if (
        !/login|register|payments|forgot|unknown|age-verification/.test(
          window.location.pathname
        )
      ) {
        return window.location.replace(config.client.uris.login);
      }

      throw new Error(`Received ${response.status} from ${uri}`);
    }

    const user = await response.json();

    if (!user?.meta?.isTipster) throw new Error("Invalid user");

    if (
      user.status === "pending" &&
      !/login-hash/.test(window.location.pathname)
    ) {
      localStorage.removeItem("token");

      return window.location.replace(config.client.uris.login);
    }

    return user;
  } catch (error) {
    sendLog("error", error);

    localStorage.removeItem("token");

    return { meta: {} };
  }
};

export const getUserProfile = async () => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/users/profile";

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) throw new Error("Error getting user profile");

  const user = await response.json();

  return user;
};

export const getUserTrackers = async () => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/users/trackers";

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) throw new Error("Error getting trackers");

  const trackers = await response.json();

  return trackers?.trackers || trackers;
};

export const getUserGroups = async () => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/users/groups";

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) throw new Error("Error getting groups");

  const groups = await response.json();

  return groups?.groups || groups;
};

export const checkCredentials = async () => {
  try {
    const user = await getUser();

    if (!user) throw new Error("Invalid user");
  } catch (error) {
    sendLog("error", error);

    window.location.replace(config.client.uris.login);
  }
};

export const login = async (form) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/users/login", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
    body: new URLSearchParams(form),
  });

  if (response.status !== 200) {
    const error = await response.json();

    throw new Error(error);
  }

  const user = await response.json();

  localStorage.setItem("token", user.token);

  return user;
};

export const register = async (form) => {
  const apiUri = getApiUri(window.location.hostname);
  const response = await fetch(apiUri + "/users/register", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
    },
    body: new URLSearchParams(form),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }
};

export const forgot = async (form) => {
  const apiUri = getApiUri(window.location.hostname);
  const response = await fetch(apiUri + "/users/forgot", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(form),
  });

  if (response.status !== 200) {
    const error = await response.json();

    throw new Error(error);
  }
};

export const restorePassword = async (hash, password) => {
  const apiUri = getApiUri(window.location.hostname);
  const response = await fetch(apiUri + "/users/forgot/" + hash, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ password }),
  });

  if (response.status !== 200) {
    const error = await response.json();

    throw new Error(error);
  }
};

export const logout = async () => {
  localStorage.removeItem("token");
  window.location.replace(config.client.uris.login);
};

export const updateUser = async (payload) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/users/update", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const user = await response.json();

  return user;
};

export const getBilling = async (from, to) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/users/billing";

  const response = await fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ from, to }),
  });

  if (response.status !== 200) throw new Error("Error getting billing");

  const billing = await response.json();

  return billing?.billing || billing;
};

export const getBillingById = async (groupId) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/users/billing/" + groupId;

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200)
    throw new Error("Error getting billing by group");

  const billing = await response.json();

  return billing?.billing || billing;
};

export const getAffiliationReport = async (date) => {
  const apiUri = getApiUri(window.location.hostname);

  const uri = new URL(apiUri + "/users/affiliation-reports");

  uri.searchParams.append("date", date);

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200)
    throw new Error("Error getting affiliation report");

  const affiliationReport = await response.json();

  return affiliationReport;
};

export const loginHash = async (payload) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/users/login-hash", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const error = await response.json();

    throw new Error(error);
  }

  const user = await response.json();

  localStorage.setItem("token", user.token);

  return user;
};

export const registerHash = async (payload) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/users/register-hash", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const user = await response.json();

  return user;
};

export const resetApiToken = async () => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/users/api-token", {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const user = await response.json();

  return user;
};
