import Logo from "../../../../../components/Logo";

export default function ShopErrorLayout({ error, action, buttonValue }) {
  return (
    <div className="container">
      <div className="row">
        <div className="container-fluid">
          <div className="landing-logo">
            <Logo />
          </div>
          <div className="separator" />
        </div>
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">{error.message}</div>
          <div className="separator" />
        </div>
      </div>
    </div>
  );
}
