import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../../../Config";
import { getUser } from "../../../../services/User";
import { sendLog } from "../../../../services/App";
import { useLanguage } from "../../../../languages/useLanguage";

export default function AdminHomePage({ setLoader, setAlert }) {
  const { language } = useLanguage();
  const [user, setUser] = useState({});
  const [roles, setRoles] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isManager, setIsManager] = useState(false);

  const fetchUser = async () => {
    const user = await getUser();

    if (!user?.meta?.isAdmin && !user?.meta?.isManager) {
      return window.location.replace(config.client.uris.profile);
    }

    setRoles(
      ["isAdmin", "isManager", "isTipster"].filter((key) => user?.meta[key])
    );

    return user;
  };

  const refreshUser = () => {
    fetchUser()
      .then(setUser)
      .catch((error) => {
        sendLog("error", error);

        window.location.replace(config.client.uris.login);
      });
  };

  useEffect(refreshUser, []);

  useEffect(() => {
    setIsAdmin(roles.some((role) => role === "isAdmin"));
    setIsManager(roles.some((role) => role === "isManager"));
  }, [roles]);

  if (!user) return "";

  return (
    <div className="container main products list home admin">
      <div className="gray-block">
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <div className="separator" />
              <div className="h1-header">
                {language.words.components.admin.home.ADMINISTRATION}
              </div>
              <div className="separator" />
            </div>
          </div>
          <ul>
            {(isAdmin || isManager) && (
              <li className="nav-item">
                <Link className="nav-link" to={config.client.uris.adminBulk}>
                  <span className="nav-tag bi bi-arrow-right"></span>
                  {language.words.components.admin.home.BULK}
                </Link>
              </li>
            )}
            {(isAdmin || isManager) && (
              <li className="nav-item">
                <Link className="nav-link" to={config.client.uris.adminUsers}>
                  <span className="nav-tag bi bi-arrow-right"></span>
                  {language.words.components.admin.home.USERS}
                </Link>
              </li>
            )}
            {(isAdmin || isManager) && (
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={config.client.uris.adminWebLanding}
                >
                  <span className="nav-tag bi bi-arrow-right"></span>
                  {language.words.components.admin.home.WEB_LANDING}
                </Link>
              </li>
            )}
            {(isAdmin || isManager) && (
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={config.client.uris.adminPredictContest}
                >
                  <span className="nav-tag bi bi-arrow-right"></span>
                  {language.words.components.admin.home.PREDICT_CONTESTS}
                </Link>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item">
                <Link className="nav-link" to={config.client.uris.adminPayment}>
                  <span className="nav-tag bi bi-arrow-right"></span>
                  {language.words.components.admin.home.PAYMENT_METHODS}
                </Link>
              </li>
            )}
            {isAdmin && (
              <li className="nav-item">
                <Link className="nav-link" to={config.client.uris.adminLogs}>
                  <span className="nav-tag bi bi-arrow-right"></span>
                  {language.words.components.admin.home.LOGS}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
