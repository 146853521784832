import { useState } from "react";

const useCheckbox = (initValue) => {
  const [checked, setChecked] = useState(initValue);
  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };
  const checkboxAttributes = {
    checked,
    onChange: (e) => toggleChecked(),
  };

  return { checked, setChecked, checkboxAttributes };
};

export default useCheckbox;
