import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../../../languages/useLanguage";
import { getTrackerVerified } from "../../../../services/Redirect";
import { getPublicUri } from "../../../../Utils";
import "./AgeVerification.css";

const AgeVerification = () => {
  const { language } = useLanguage();
  const { hash } = useParams();
  const [payload, setPayload] = useState({});
  const navigate = useNavigate();

  const verificateAge = async () => {
    try {
      const url = await getTrackerVerified(payload.ageVerificationHash);

      window.location.replace(url);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    try {
      const decoded = JSON.parse(atob(decodeURIComponent(hash)));

      setPayload(decoded);
    } catch (error) {
      navigate("unknow");
    }
  }, [hash]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container age-verification">
      <div className="landing-user">
        <div className="profile-pic">
          {payload?.profilePic && (
            <div className="profile-pic-wrapper">
              <img
                src={`${getPublicUri(window.location.hostname)}/${
                  payload.profilePic
                }`}
                alt="Profile pic"
              />
            </div>
          )}
        </div>
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {language.words.components.redirect.ageVerification.OVER_18}
          </div>
          <div className="separator" />
        </div>
        <div className="container-fluid">
          <div className="info">
            {language.words.components.redirect.ageVerification.TO_ACCESS}
            {payload?.bettingSite
              ? ` ${payload.bettingSite.toUpperCase()} `
              : " "}
            {language.words.components.redirect.ageVerification.MUST_BE_OVER_18}
          </div>
          <div className="confirm-age">
            <button
              className="btn btn-danger"
              onClick={() => {
                window.location.assign("https://jugarbien.es/");
              }}
            >
              {language.words.components.redirect.ageVerification.NO}
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                verificateAge();
              }}
            >
              {language.words.components.redirect.ageVerification.YES}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgeVerification;
