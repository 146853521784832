import { getApiUri } from "../Utils";

export const getCoupons = async () => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/coupons";

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const createCoupon = async (payload) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/coupons";

  const response = await fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const getCoupon = async (couponId) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/coupons/" + couponId;

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const coupon = await response.json();

  return coupon;
};

export const updateCoupon = async (couponId, payload) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/coupons/" + couponId;

  const response = await fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const coupon = await response.json();

  return coupon;
};

export const getDiscount = async (tipsterId, key, productId = null) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(
    apiUri +
      "/coupons/" +
      tipsterId +
      "/discount/" +
      key +
      (productId ? "?productId=" + productId : ""),
    {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    }
  );

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const discount = await response.json();

  return discount;
};
