import { useRef } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { getTrackerRedirect } from "../../../../services/Redirect";

const Tracker = () => {
  // boolean ref to only render once in delevopment mode
  const isRedirectedRef = useRef(false);

  const params = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isRedirectedRef.current) return;

    isRedirectedRef.current = true;

    const userBetsfyId = params.userBetsfyId;
    const promoBetsfyId = params.promoBetsfyId;
    const referrer = document.referrer;
    const source = searchParams.get("s");

    const redirectToTracker = async () => {
      try {
        const url = await getTrackerRedirect(
          userBetsfyId,
          promoBetsfyId,
          referrer,
          source
        );
        
        window.location.replace(url);
      } catch (error) {
        console.error(error);
      }
    };

    if (userBetsfyId && promoBetsfyId) {
      redirectToTracker();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div>Tracker</div>;
};

export default Tracker;
