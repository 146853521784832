import moment from "moment";
import { useLanguage } from "../../../../../languages/useLanguage";

const CardOrderComponent = ({ order }) => {
  const { language } = useLanguage();

  return (
    <div className="orders-row-info">
      <div className="title-orders-span">
        <h5 style={{ fontSize: "20px", color: "#000000" }}>{order.title}</h5>
        <span>{language.words.components.billing.cardOrder.PRODUCT_SALES}</span>
      </div>
      <div
        className="date-orders"
        style={{ fontSize: "14px", color: "#9498a7" }}
      >
        <span>{moment(order.updatedAt).format("DD/MM/YYYY HH:mm:ss")}</span>
        {order.orderCount ? (
          <span>{`${order.orderCount} ${language.words.components.billing.cardOrder.SALES}`}</span>
        ) : (
          ""
        )}
      </div>
      <div className="date-orders">
        <span></span>
        <span style={{ fontSize: "14px" }}>
          {order.approvalTotalCount && order.approvalTotalCount > 0 ? (
            <span>
              {`${order.approvalTotalCount} ${
                order.approvalTotalCount === 1
                  ? language.words.components.billing.cardOrder.APPROVAL
                  : language.words.components.billing.cardOrder.APPROVALS
              }`}
            </span>
          ) : (
            ""
          )}
          {order.approvalCount?.map((approval) => {
            return (
              <div
                key={`bettingSite-${approval.bettingSite}-${approval.count}`}
              >
                {`${approval.count} ${approval.bettingSite}`}
              </div>
            );
          })}
        </span>
      </div>
      <div className="orders-total">
        <span>
          {language.words.components.billing.cardOrder.PRODUCT_EARNINGS}
        </span>
        {new Intl.NumberFormat("es-ES", {
          style: "currency",
          currency: "EUR",
        }).format(order.total)}
      </div>
    </div>
  );
};

export default CardOrderComponent;
