import { getApiUri } from "../Utils";

export const getImages = async (type, tipsterId = null) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = new URL(apiUri + "/images");

  const params = {
    type: type,
    tipsterId: tipsterId,
  };

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      uri.searchParams.append(key, params[key]);
    }
  });

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const data = await response.json();

  return data;
};

export const uploadImage = async (image, type, tipsterId) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = new URL(apiUri + "/images");

  const params = {
    type: type,
    tipsterId: tipsterId,
  };

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      uri.searchParams.append(key, params[key]);
    }
  });

  let formData = new FormData();

  formData.append("image", image);

  const response = await fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: formData,
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const data = await response.json();

  return data;
};

export const deleteImage = async (id) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = new URL(apiUri + "/images/" + id);

  const response = await fetch(uri, {
    method: "DELETE",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const data = await response.json();

  return data;
};
