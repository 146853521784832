import { useState } from "react";

const useSelect = (initValue, initText) => {
  const [value, setValue] = useState(initValue);
  const [text, setText] = useState(initText || "");
  const selectAttributes = {
    value,
    onChange: (e) => {
      setValue(e.target.value);
      setText(e.target.options[e.target.selectedIndex].text);
    },
  };

  return { value, setValue, text, setText, selectAttributes };
};

export default useSelect;
