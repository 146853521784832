import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import NotificationBar from "../../components/NotificationBar";
import Loader from "../../components/Loader";
import MissingPage from "../../components/Missing";
import RedsysShopPage from "./components/landing/redsys/Shop";
import PaypalShopPage from "./components/landing/paypal/Shop";
import AstropayShopPage from "./components/landing/astropay/Shop";

const App = () => {
  const [isLoading, setLoader] = useState(false);
  const [notifications, notify] = useState({ visible: false, text: "" });

  const setAlert = (text) => notify({ visible: true, text });
  const unsetAlert = () => notify({ visible: false, text: "" });

  return (
    <>
      <div className="separator" />
      <Routes>
        <Route
          path="/payments/redsys/:hash"
          element={<RedsysShopPage setAlert={setAlert} setLoader={setLoader} />}
        />
        <Route
          path="/payments/paypal/:hash"
          element={<PaypalShopPage setAlert={setAlert} setLoader={setLoader} />}
        />
        <Route
          path="/payments/astropay/:hash"
          element={
            <AstropayShopPage setAlert={setAlert} setLoader={setLoader} />
          }
        />

        {/* catch all */}
        <Route path="*" element={<MissingPage />} />
      </Routes>
      <Loader isLoading={isLoading} />
      <NotificationBar {...notifications} unsetAlert={unsetAlert} />
    </>
  );
};

export default App;
