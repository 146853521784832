import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ShopLayout from "../components/Layout";
import ShopErrorLayout from "../components/ErrorLayout";
import { fetchOrder } from "../../../../../services/Order";
import { getDiscount } from "../../../../../services/Coupon";
import { getProductToBuy } from "../../../../../services/Product";
import { getPaymentMethod } from "../../../../../services/Admin";
import "../Landing.css";
import "./Shop.css";
import { useLanguage } from "../../../../../languages/useLanguage";

const RedsysForm = ({ form, product, type }) => {
  const { language } = useLanguage();

  return (
    <form
      id="redsysForm"
      className="form"
      action={form.url}
      method="POST"
      onLoad={(event) => event.target.submit()}
    >
      <input
        type="hidden"
        id="Ds_SignatureVersion"
        name="Ds_SignatureVersion"
        defaultValue={form.body.Ds_SignatureVersion}
      />
      <input
        type="hidden"
        id="Ds_MerchantParameters"
        name="Ds_MerchantParameters"
        defaultValue={form.body.Ds_MerchantParameters}
      />
      <input
        type="hidden"
        id="Ds_Signature"
        name="Ds_Signature"
        defaultValue={form.body.Ds_Signature}
      />
      {type === "bizum" ? (
        <input
          type="hidden"
          id="Ds_Merchant_PayMethods"
          name="Ds_Merchant_PayMethods"
          defaultValue="z"
        />
      ) : (
        ""
      )}
      <div className="form-group">
        <input
          className="btn btn-primary"
          type="submit"
          value={language.words.components.shop.landing.GO_TO_PAYMENT_GATEWAY}
        />
      </div>
    </form>
  );
};

export default function RedsysShopPage({ setLoader, setAlert }) {
  const { language } = useLanguage();
  const [discount, setDiscount] = useState(0);
  const [payload, setPayload] = useState({});
  const [product, setProduct] = useState(null);
  const [form, setForm] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState({});
  const params = useParams({});

  const setCoupon = async () => {
    const node = document.querySelector("#couponCode");

    try {
      setLoader(true);
      const discount = await getDiscount(product.user, node.value, product._id);

      setDiscount(discount);
    } catch (error) {
      node.value = "";

      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  const send = async () => {
    try {
      setLoader(true);

      const order = await fetchOrder("redsys", {
        ...payload,
        discountId: discount?._id,
      });

      setForm(order);
    } catch (error) {
      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const decoded = JSON.parse(atob(decodeURIComponent(params.hash)));

    const type = decoded.type;
    const productId = decoded.productId;
    const userId = decoded.userId;

    setPayload({
      type: type,
      productId: productId,
      userId: userId,
    });

    const fetchProduct = async () => {
      try {
        setLoader(true);

        const _product = await getProductToBuy(productId);

        setProduct(_product);
      } catch (error) {
        console.error(error);
        setAlert(error?.message || error);
      } finally {
        setLoader(false);
      }
    };

    if (productId) {
      fetchProduct();
    }
  }, [params.hash]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      try {
        const paymentMethod = await getPaymentMethod("redsys");

        setPaymentMethod(paymentMethod);
      } catch (error) {
        console.error(error);
        setAlert(error?.message || error);
      }
    };

    fetchPaymentMethod();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  setTimeout(() => {
    if (form) {
      const formNode = document.getElementById("redsysForm");

      formNode.submit();
    }
  }, 200);

  const decoratedType =
    payload.type === "card"
      ? language.words.components.shop.landing.CARD
      : language.words.components.shop.landing.BIZUM;

  return !product?._id || !payload.type ? (
    <ShopErrorLayout
      error={{
        message: language.words.components.shop.landing.PRODUCT_NOT_FOUND,
      }}
    />
  ) : (
    <Fragment>
      {form && product && payload.type ? (
        <RedsysForm form={form} product={product} type={payload.type} />
      ) : (
        <ShopLayout
          product={product}
          type={payload.type}
          send={send}
          setCoupon={setCoupon}
          discount={discount}
          buttonValue={`${language.words.components.shop.landing.PAY_WITH} ${decoratedType}`}
          minPrice={paymentMethod.minValue}
        />
      )}
    </Fragment>
  );
}
