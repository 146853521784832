import { useState, useRef } from "react";

const useTextarea = (initValue) => {
  const [value, setValue] = useState(initValue);
  const ref = useRef(null);
  const textareaAttributes = {
    value,
    onChange: (e) => setValue(e.target.value),
    ref,
  };

  return { value, setValue, textareaAttributes, ref };
};

export default useTextarea;
