import moment from "moment";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  deleteProduct,
  getProduct,
  updateProduct,
} from "../../../../../services/Product";
import { randomIntBy } from "../../../../../Utils";
import config from "../../../../../Config";
import TelegramModal from "./TelegramModal";
import "./Card.css";
import { useLanguage } from "../../../../../languages/useLanguage";

export default function CardComponent({ productInit, setAlert }) {
  const { language } = useLanguage();
  const [product, setProduct] = useState(productInit);

  const updateStatus = async (productId, status) => {
    const body = JSON.stringify({ status });
    const headers = {
      "Content-Type": "application/json",
    };

    const _product = await updateProduct(productId, body, headers);

    if (!_product)
      return setAlert(language.words.components.product.card.UPDATE_ERROR);

    setProduct(_product);
  };

  const deleteProductStatus = async (productId, status) => {
    const _product = await deleteProduct(productId, status);

    if (!_product)
      return setAlert(language.words.components.product.card.DELETE_ERROR);

    window.location.reload();
  };

  const lookForStatusChanging = async (productId) => {
    const _product = await getProduct(productId);

    if (_product.status === product.status) {
      await new Promise((resolve) =>
        setTimeout(resolve, randomIntBy(2, 5) * 1000)
      );

      return lookForStatusChanging(productId);
    }

    setProduct(_product);
  };

  useEffect(() => {
    const checkCandidate =
      (product.status === "publishing" && product.type === "suscription") ||
      (product.status === "unreleased" &&
        new Date(product.startDate).getTime() - Date.now() < 300000 &&
        new Date(product.startDate).getTime() - Date.now() > -300000) ||
      (product.status !== "expired" &&
        new Date(product.expirationDate).getTime() - Date.now() < 300000 &&
        new Date(product.expirationDate).getTime() - Date.now() > -300000);

    if (checkCandidate) {
      new Promise((resolve) =>
        setTimeout(resolve, randomIntBy(2, 5) * 200)
      ).then(() => {
        lookForStatusChanging(product._id);
      });
    } else {
      setProduct(product);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="list-card">
      <div className="row">
        <div className="container-fluid">
          <div className="h1-header">{product.title}</div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-6">
          {((!product.isPromo && !product.isPayment) || product.isPayment) && (
            <div>
              {`${language.words.components.product.card.PRICE}: `}
              <b>{product.price} €</b>
            </div>
          )}
          {product.isPromo && (
            <div>
              {`${language.words.components.product.card.SIGN_UP}: `}
              <b>
                {product.trackers.map((tracker) => {
                  return (
                    <div key={tracker._id}>{tracker.promo.bettingSite}</div>
                  );
                })}
              </b>
            </div>
          )}
        </div>
        {product.type === "suscription" ? (
          <div className="col-6">
            {`${language.words.components.product.card.DURATION}: `}
            <b>
              {product.days < 36500 ? product.days : "\u221e"}{" "}
              {language.words.components.product.card.DAYS}
            </b>
          </div>
        ) : (
          ""
        )}
      </div>
      {product.startDate && product.status === "unreleased" && (
        <div className="row">
          <div className="container-fluid">
            {`${language.words.components.product.card.STARTS_ON}: `}
            <b>{moment(product.startDate).format("DD-MM-YYYY HH:mm:ss")}</b>
          </div>
        </div>
      )}
      {product.expirationDate ? (
        <div className="row">
          <div className="container-fluid">
            {`${language.words.components.product.card.EXPIRES_ON}: `}
            <b>
              {moment(product.expirationDate).format("DD-MM-YYYY HH:mm:ss")}
            </b>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="separator" />
      <div className="status-wrapper">
        <div
          className={
            "status-info" +
            (product.status === "in_progress"
              ? " warning"
              : product.status === "unreleased"
              ? " warning"
              : product.status === "published"
              ? " success"
              : product.status === "unpublished"
              ? " warning"
              : product.status === "expired"
              ? " warning"
              : product.status === "errored"
              ? " danger"
              : " danger")
          }
        >
          {product.status === "in_progress" ? (
            <>
              {language.words.components.product.card.IN_PROGRESS}
              <span className="bi bi-arrow-clockwise"></span>
            </>
          ) : product.status === "unreleased" ? (
            <>
              {language.words.components.product.card.UNRELEASED}
              <span className="bi bi-stopwatch"></span>
            </>
          ) : product.status === "publishing" ? (
            <>
              {language.words.components.product.card.PUBLISHING}
              <span className="bi bi-pause-circle"></span>
            </>
          ) : product.status === "published" ? (
            <>
              {language.words.components.product.card.PUBLISHED}
              <span className="bi bi-check-circle"></span>
            </>
          ) : product.status === "unpublished" ? (
            <>
              {language.words.components.product.card.UNPUBLISHED}
              <span className="bi bi-stopwatch"></span>
            </>
          ) : product.status === "expired" ? (
            <>
              {language.words.components.product.card.EXPIRED}
              <span className="bi bi-calendar-check"></span>
            </>
          ) : product.status === "errored" ? (
            <>
              {language.words.components.product.card.ERRORED}
              <span className="bi bi-x-circle"></span>
            </>
          ) : (
            <>
              {language.words.components.product.card.UNKNOWN}
              <span className="bi bi-question-circle"></span>
            </>
          )}
        </div>
        <div className="status-wrapper">
          {product.status === "published" ? (
            <div
              className="action-button"
              onClick={() => {
                updateStatus(product._id, "unpublished");
              }}
            >
              <span className="bi bi-pause-circle"></span>
              {language.words.components.product.card.PAUSE}
            </div>
          ) : product.status === "unpublished" ||
            product.status === "unreleased" ? (
            <div
              className="action-button"
              onClick={() => {
                updateStatus(product._id, "published");
              }}
            >
              <span className="bi bi-play-circle"></span>
              {product.status === "unpublished"
                ? language.words.components.product.card.RESUME
                : language.words.components.product.card.START}
            </div>
          ) : (
            ""
          )}
          {product.status === "errored" ? (
            <button
              className="btn btn-danger"
              onClick={() => deleteProductStatus(product._id)}
            >
              <span className="bi bi-trash"></span>
            </button>
          ) : (
            <Link
              className="btn btn-success"
              to={config.client.uris.products + "/" + product._id}
            >
              <span className="bi bi-pencil"></span>
            </Link>
          )}
        </div>
      </div>
      {["published", "unpublished", "unreleased"].indexOf(product.status) < 0 &&
      !product?.meta?.telegramCode &&
      !product?.meta?.error ? (
        <TelegramModal product={product} setAlert={setAlert} />
      ) : (
        ""
      )}
    </div>
  );
}
