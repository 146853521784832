import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { useLanguage } from "../../../../../languages/useLanguage";
import "./ProductSelection.css";

const Card = ({ product, preselection, updatePreselection }) => {
  const { language } = useLanguage();

  const selected = preselection.find((_product) => _product._id === product._id)
    ? "selected"
    : "";

  return (
    <div
      className={`list-card product-selector ${selected}`}
      onClick={() => updatePreselection(product)}
    >
      <div className="row">
        <div className="container-fluid">
          <div className="h1-header">{product.title}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div>
            {`${language.words.components.product.selection.PRICE}: `}
            <b>{product.price} €</b>
          </div>
        </div>
        {product.type === "suscription" ? (
          <div className="col-6">
            {`${language.words.components.product.selection.DURATION}: `}
            <b>
              {product.days < 36500 ? product.days : "\u221e"}{" "}
              {language.words.components.product.selection.DAYS}
            </b>
          </div>
        ) : (
          ""
        )}
      </div>
      {product.expirationDate ? (
        <div className="row">
          <div className="container-fluid">
            {`${language.words.components.product.selection.EXPIRES_ON}: `}
            <b>
              {moment(product.expirationDate).format("DD-MM-YYYY HH:mm:ss")}
            </b>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="status-wrapper">
        <div
          className={
            "status-info" +
            (product.status === "in_progress"
              ? " warning"
              : product.status === "published"
              ? " success"
              : product.status === "unpublished"
              ? " warning"
              : product.status === "expired"
              ? " danger"
              : product.status === "errored"
              ? " danger"
              : " danger")
          }
        >
          {product.status === "in_progress" ? (
            <>
              {language.words.components.product.selection.IN_PROGRESS}
              <span className="bi bi-arrow-clockwise"></span>
            </>
          ) : product.status === "publishing" ? (
            <>
              {language.words.components.product.selection.PUBLISHING}
              <span className="bi bi-pause-circle"></span>
            </>
          ) : product.status === "published" ? (
            <>
              {language.words.components.product.selection.PUBLISHED}
              <span className="bi bi-check-circle"></span>
            </>
          ) : product.status === "unpublished" ? (
            <>
              {language.words.components.product.selection.UNPUBLISHED}
              <span className="bi bi-stopwatch"></span>
            </>
          ) : product.status === "expired" ? (
            <>
              {language.words.components.product.selection.EXPIRED}
              <span className="bi bi-calendar-check"></span>
            </>
          ) : product.status === "errored" ? (
            <>
              {language.words.components.product.selection.ERRORED}
              <span className="bi bi-x-circle"></span>
            </>
          ) : (
            <>
              {language.words.components.product.selection.UNKNOWN}
              <span className="bi bi-question-circle"></span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const SelectProduct = ({
  show,
  setShow,
  products,
  selectedProducts,
  setSelectedProducts,
}) => {
  const { language } = useLanguage();
  const [preselection, setPreselection] = useState([]);

  const toggleShow = () => {
    setShow((prev) => !prev);
  };

  const updatePreselection = (_product) => {
    setPreselection((prev) => {
      const exists = prev.some((__product) => __product._id === _product._id);

      if (exists) {
        return prev.filter((__product) => __product._id !== _product._id);
      } else {
        return [...prev, _product];
      }
    });
  };

  const applySelection = () => {
    setSelectedProducts(preselection);
    setShow(false);
  };

  useEffect(() => {
    setPreselection(selectedProducts);
  }, [show, selectedProducts]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (show) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [show]);

  return (
    <div
      className={"modal fade" + (show && " show")}
      style={{
        display: "block",
        visibility: show ? "visible" : "hidden",
        maxHeight: "100vh",
        maxWidth: "100wh",
      }}
      onClick={toggleShow}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div
          className="modal-content gray-block"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-header">
            <div className="h1-header">
              {language.words.components.product.selection.SELECT_PRODUCTS}
            </div>
          </div>
          <div
            className="modal-body"
            style={{ maxHeight: "60vh", overflowY: "auto" }}
          >
            {products?.map((product) => {
              return (
                <Card
                  key={product._id}
                  className={
                    "selection-choice" +
                    (preselection?.some(
                      (_product) => _product._id === product._id
                    )
                      ? " active"
                      : " inactive")
                  }
                  role="button"
                  {...{ product, preselection, updatePreselection }}
                />
              );
            })}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary-like btn-warning"
              onClick={() => setShow(false)}
            >
              {language.words.components.product.selection.CANCEL}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => applySelection()}
            >
              {language.words.components.product.selection.APPLY}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectProduct;
