const languages = [
  {
    title: "Español",
    value: "es",
    words: {
      errors: {
        INVALID_USER: "Usuario no válido",
      },
      navigation: {
        BACK: "Volver",
      },
      components: {
        general: {
          botMenu: {
            SELLING_BOT: "Tu bot de ventas",
            PROMO_BOT: "Tu bot de promo",
          },
          bottomMenu: {
            PRODUCTS: "Productos",
            BILLING: "Banca",
            MY_PROFILE: "Mi perfil",
          },
          menu: {
            MY_PRODUCTS: "Mis Productos",
            MY_REQUESTS: "Mis Solicitudes",
            MY_COUPONS: "Mis Cupones",
            MY_BILLING: "Mi Banca",
            PROMOS: "Acuerdos Disponibles",
            MY_DEALS: "Mis Acuerdos",
            MY_PROFILE: "Mi Perfil",
            ADMIN: "Admin",
            LOGOUT: "Cerrar sesión",
            LOGIN: "Entrar",
          },
          missing: {
            PAGE_NOT_FOUND: "Esta página no existe",
          },
          scrollButton: {
            GO_BACK_UP: "Volver arriba",
          },
        },
        admin: {
          home: {
            ADMINISTRATION: "Administrción",
            BULK: "Bulk",
            USERS: "Usuarios",
            WEB_LANDING: "Landing web",
            PREDICT_CONTESTS: "Porras",
            PAYMENT_METHODS: "Métodos de pago",
            LOGS: "Logs",
          },
          bulk: {
            BULK: "Bulk",
            ORDERS: "PEDIDOS",
            EXPORT_ORDERS: "EXPORTAR PEDIDOS",
            USERS: "USUARIOS",
            EXPORT_USERS: "EXPORTAR USUARIOS",
            IMPORT_USERS: "IMPORTAR USUARIOS",
            TRACKERS: "TRACKERS",
            EXPORT_TRACKERS: "EXPORTAR TRACKERS",
            IMPORT_TRACKERS: "IMPORTAR TRACKERS",
            PROMOS: "ACUERDOS",
            EXPORT_PROMOS: "EXPORTAR ACUERDOS",
            IMPORT_PROMOS: "IMPORTAR ACUERDOS",
            AFFILIATION_REPORT: "INFORME AFILIACIÓN",
            EXPORT_AFFILIATION_REPORT: "EXPORTAR INFORME AFILIACIÓN",
            IMPORT_AFFILIATION_REPORT: "IMPORTAR INFORME AFILIACIÓN",
            OMITTED_LINES: "Se han omitido las lineas",
            UPDATED_DATA: "Datos actualizados",
          },
          logs: {
            LOGS: "Logs",
            DOWNLOAD_LOGS: "DESCARGAR LOGS",
          },
          payment: {
            PAYMENT_METHODS: "Métodos de pago",
          },
          paymentCard: {
            MIN_PAYMENT: "Pago mínimo",
            ENABLED: "Activado",
            ENABLE: "Activar",
            DISABLED: "Desactivado",
            DISABLE: "Desactivar",
            UNKNOWN: "Desconocido",
          },
          paymentEdit: {
            EDIT_PAYMENT: "Editar método de pago",
            MIN_PAYMENT_AMOUNT: "Cantidad mínima de transacción",
            EDIT: "Editar",
          },
          users: {
            USERS: "Usuarios",
            NO_USER_FOUND: "Ningún usuario encontrado",
          },
          userCard: {
            BETSFY_ID: "Betsfy ID",
            EMAIL: "email",
            PHONE_NUMBER: "Teléfono",
            NICKNAME: "Nick",
            DOMAIN: "Dominio",
            PENDING: "Pendiente",
            INACTIVE: "Inactivo",
            ACTIVE: "Activo",
            BANNED: "Baneado",
            REVIEW: "Revisar",
            ACTIVATE: "Activar",
            BAN: "Banear",
            UNBAN: "Desbanear",
            API_TOKEN: "API token",
            API_TOKEN_SHOW: "Mostrar token",
            API_TOKEN_HIDE: "Ocultar token",
            API_TOKEN_COPY: "Copiar token en portapapeles",
            API_TOKEN_INFO:
              "Siempre debes tener cuidado con tu token, ya que con él, cualquier persona podrá ver tu cuenta y modificarla. Los tokens de acceso personal funcionan como una combinación de nombre y contraseña para autenticar una API.",
            API_TOKEN_RESET: "Restablecer API token",
            API_TOKEN_RESET_CONFIRM:
              "¿Estás seguro de restablecer el token de acceso personal? Esto hará que dejen de funcionar las integraciones con el token actual.",
          },
          userPending: {
            PENDING_USER: "Usuario pendiente de activar",
            NO_PENDING_USER: "Este usuario no está pendiente de activar",
            AWAITING_REGISTER:
              "Esperando que el usuario envíe la información de registro",
            NOTES: "Información para completar los datos",
            ACTIVATE: "Activar",
            INCOMPLETE: "Pedir más datos",
          },
          webLanding: {
            WEB_LANDING: "Landing web",
          },
          webLandingCard: {
            HEADER_IMAGE: "Imagen de cabecera",
            NO_HEADER_IMAGE: "Sin imagen de cabecera",
            HEADER_BACKGROUND: "Color fondo de cabecera",
            CONTENT_BACKGROUND: "Color fondo del contenido",
            CARD_TYPE: "Tipo de tarjeta",
            TIPSTERS: "Tipsters",
          },
          webLandingEdit: {
            EDIT_WEB_LANDING: "Editar landing web",
            NAME: "Nombre",
            HEADER_IMAGE: "Imagen de cabecera",
            NO_HEADER_IMAGE: "Sin imagen de cabecera",
            HEADER_BACKGROUND: "Color fondo de cabecera",
            REMOVE_HEADER_IMAGE: "Eliminar imagen de cabecera",
            CONTENT_BACKGROUND: "Color fondo del contenido",
            CARD_TYPE: "Tipo de tarjeta",
            EDIT: "Editar",
          },
          predictContest: {
            list: {
              PREDICT_CONTESTS: "Porras",
              CREATE_PREDICT_CONTEST: "Crear porra",
            },
            create: {
              CREATE_PREDICT_CONTEST: "Crear porra",
              HEADER: "Cabecera",
              NO_HEADER_IMAGE: "Sin imagen de cabecera",
              MATCHS: "Partidos",
              MATCH: "Partido",
              HOME_TEAM: "Equipo local",
              AWAY_TEAM: "Equipo visitante",
              NAME: "Nombre",
              NAME_HOME: "Nombre local",
              NAME_AWAY: "Nombre visitante",
              BADGE: "Escudo",
              BADGE_HOME: "Escudo local",
              BADGE_AWAY: "Escudo visitante",
              NO_BADGE_IMAGE: "Sin escudo",
              ADD_MATCH: "Añadir partido",
              REMOVE_MATCH: "Quitar partido",
              LEGAL: "Términos y condiciones",
              FOOTER: "Pie",
              NO_FOOTER_IMAGE: "Sin imagen de pie",
              START_DATE: "Fecha de inicio",
              EXPIRATION_DATE: "Fecha de caducidad",
            },
            card: {
              HEADER: "Cabecera",
              NO_HEADER_IMAGE: "Sin imagen de cabecera",
              BADGE: "Escudo",
              NO_BADGE_IMAGE: "Sin escudo",
              FOOTER: "Pie",
              NO_FOOTER_IMAGE: "Sin imagen de pie",
              STARTS_ON: "Se activa el",
              EXPIRES_ON: "Se desactiva el",
              USERS: "Participantes",
              PREDICTIONS_LIST: "Lista de predicciones",
              UNRELEASED: "Próximamente",
              ENABLED: "Activo",
              DISABLED: "Inactivo",
              EXPIRED: "Finalizado",
              UNKNOWN: "Desconocido",
              DISABLE: "Desactivar",
              ENABLE: "Activar",
              START: "Iniciar",
            },
            edit: {
              EDIT_PREDICT_CONTEST: "Editar porra",
              HEADER: "Cabecera",
              NO_HEADER_IMAGE: "Sin imagen de cabecera",
              HOME_TEAM: "Equipo local",
              AWAY_TEAM: "Equipo visitante",
              NAME: "Nombre",
              BADGE: "Escudo",
              NO_BADGE_IMAGE: "Sin escudo",
              LEGAL: "Términos y condiciones",
              FOOTER: "Pie",
              NO_FOOTER_IMAGE: "Sin imagen de pie",
              START_DATE: "Fecha de inicio",
              EXPIRATION_DATE: "Fecha de caducidad",
              DELETE_CONFIRM: "¿Estás seguro de eliminar esta porra?",
              DELETE_PREDICT_CONTEST: "Eliminar porra",
            },
            predictions: {
              DOWNLOAD: "Descargar predicciones",
              EMAIL: "email",
              TIPSTER_ID: "ID landing del tipster",
              NO_PREDICTIONS: "No hay predicciones para mostrar",
            },
          },
        },
        approval: {
          list: {
            MY_REQUESTS: "Mis solicitudes",
            REQUEST_HISTORY: "Historial",
          },
          card: {
            NO_PRODUCT_APPROVAL: "Verificación sin producto",
            BETTING_SITE: "Casa de apuestas",
            BETTING_SITE_USERNAME: "Nick en casa de apuestas",
            BETTING_SITE_EMAIL: "Email cliente",
            TELEGRAM_USERNAME: "Nick en telegram",
            PENDING: "Pendiente",
            DECLINED: "Denegada",
            ACCEPTED: "Aceptada",
            CHECK: "Revisar",
            DETAILS: "Detalles",
          },
          details: {
            COMPLETE_FIELDS: "Por favor, complete todos los campos",
            NO_PRODUCT_APPROVAL: "Verificación sin producto",
            BETTING_SITE: "Casa de apuestas",
            CONDITIONS: "Condiciones",
            BETTING_SITE_EMAIL: "Email cliente",
            BETTING_SITE_USERNAME: "Nick en casa de apuestas",
            TELEGRAM_USERNAME: "Nick en telegram",
            ACCEPT: "Aceptar",
            DECLINE: "Denegar",
            REQUEST_INFO: "Solicitar información",
            PENDING: "Pendiente",
            DECLINED: "Denegada",
            ACCEPTED: "Aceptada",
            CLOSE: "Cerrar",
          },
        },
        billing: {
          list: {
            MY_SALES: "Mis Ventas",
            MY_AFFILIATIONS: "Mis Afiliaciones",
            MY_BILLING: "Mi Banca",
            SALES_INCOME: "Ingresos de ventas",
            AFFILIATIONS_INCOME: "Ingresos de afiliaciones",
          },
          cardOrder: {
            PRODUCT_SALES: "Ventas del producto",
            SALES: "Ventas",
            APPROVAL: "Validación",
            APPROVALS: "Validadiones",
            PRODUCT_EARNINGS: "Ganancias totales",
          },
          cardOrderDetails: {
            USER: "Usuario",
            TYPE: "Tipo",
            APPROVAL_AT: "Validación en",
            GROSS_EARNINGS: "Bruto",
            NET_EARNINGS: "Neto",
            DISCOUNT_COUPON: "Cupón descuento",
          },
          cardAffiliation: {
            DESCRIPTION: "Descripción",
            CLICKS: "Clicks",
            REGISTERS: "Registros",
            CPA: "CPA",
            DEPOSITS: "Depositados",
            OTHER_INCOME: "Otros ingresos",
            INCOME: "Ganancia",
          },
          datePicker: {
            FROM: "Desde",
            TO: "Hasta",
          },
          monthPicker: {
            MONTH: "Mes",
          },
          details: {
            SALES_OF: "Ventas de",
            FROM: "Del",
            TO: "al",
            CLOSE: "Cerrar",
          },
        },
        coupon: {
          list: {
            MY_COUPONS: "Mis Cupones",
            CREATE_COUPON: "Crear cupón",
          },
          create: {
            CREATE_COUPON: "Crear cupón",
            KEY: "Código",
            DISCOUNT: "Descuento",
            FLAT_DISCOUNT: "Descuento Fijo",
            PERCENTAGE_DISCOUNT: "Descuento en Porcentaje",
            NUMBER_OF_COUPONS: "Número de cupones",
            ZERO_UNLIMITED: "0 = ilimitados",
            START_DATE: "Fecha de inicio",
            EXPIRATION_DATE: "Fecha de caducidad",
            EMPTY_NO_EXPIRE: "vacío = nunca caduca",
            APPLICABLE_TO: "Aplicable a",
            ALL_PRODUCTS: "todos los productos",
            ONE_PRODUCT: "1 producto",
            PRODUCTS: "productos",
            SELECT_PRODUCTS: "Seleccionar productos",
          },
          edit: {
            EDIT_COUPON: "Editar cupón",
            KEY: "Código",
            DISCOUNT: "Descuento",
            FLAT_DISCOUNT: "Descuento Fijo",
            PERCENTAGE_DISCOUNT: "Descuento en Porcentaje",
            NUMBER_OF_COUPONS: "Número de cupones",
            ZERO_UNLIMITED: "0 = ilimitados",
            START_DATE: "Fecha de inicio",
            EXPIRATION_DATE: "Fecha de caducidad",
            EMPTY_NO_EXPIRE: "vacío = nunca caduca",
            APPLICABLE_TO: "Aplicable a",
            ALL_PRODUCTS: "todos los productos",
            ONE_PRODUCT: "1 producto",
            PRODUCTS: "productos",
            SELECT_PRODUCTS: "Seleccionar productos",
          },
          card: {
            UPDATE_ERROR: "Error al actualizar el estado del cupón",
            DISCOUNT: "Descuento",
            REDEMPTIONS: "Canjeos",
            OF: "de",
            START_DATE: "Se activa el",
            EXPIRATION_DATE: "Caduca el",
            ENABLED: "Activado",
            DISABLED: "Desactivado",
            UNRELEASED: "Próximamente",
            EXPIRED: "Caducado",
            SPENT: "Gastado",
            UNKNOWN: "Desconocido",
            DISABLE: "Desactivar",
            ENABLE: "Activar",
          },
        },
        images: {
          input: {
            SELECT_IMAGE: "Seleccionar imagen",
            REMOVE_IMAGE: "Quitar imagen",
          },
          modal: {
            IMAGES: "Imágenes",
            SELECT: "Seleccionar",
            DELETE: "Eliminar",
            DELETE_CONFIRM: "¿Estás seguro de eliminar esta imagen?",
            UPLOAD_IMAGE: "Subir imagen",
            CANCEL: "Cancelar",
          },
        },
        product: {
          home: {
            MY_PRODUCTS: "Mis Productos",
            ACTIVE_SUBSCRIPTIONS: "Suscripciones activas",
            CREATE_SUBSCRIPTION: "Crear Suscripción",
            ACTIVE_TIPS: "Pronósticos activos",
            CREATE_TIP: "Crear Pronóstico",
            REQUESTS: "Solicitudes",
            ACTIVE_TRACKERS: "Trackers activos",
          },
          list: {
            MY_SUBSCRIPTIONS: "Mis Suscripciones",
            MY_TIPS: "Mis Pronósticos",
            CREATE_SUBSCRIPTION: "Crear Suscripción",
            CREATE_TIP: "Crear Pronóstico",
          },
          create: {
            PRODUCT_IMAGE: "Imagen de producto",
            PRODUCT_SCREENSHOT: "Imagen de pronóstico",
            UNSELECTED_TRACKERS: "No tienes trackers asignados",
            BUYER_NEVER_EXPELLED_CONFIRM:
              "Nunca se expulsará al comprador del grupo. ¿Es correcto?",
            MUST_SELECT_EXPIRATION_DATE:
              "Debe seleccionar una fecha de finalización",
            HIGHER_EXPIRATION_DATE:
              "Debes seleccionar una fecha superior a la actual",
            MUST_SELECT_PRICE: "Debe seleccionar un precio para el producto",
            MUST_SPECIFY_GROUP:
              "Debe especificar un grupo existente o escribir un nuevo nombre",
            MUST_SPECIFY_GROUP_NAME:
              "Debe especificar un nombre para el producto",
            MUST_SPECIFY_RESULT:
              "Debe especificar un resultado para el producto",
            MUST_SPECIFY_DESCRIPTION:
              "Debe especificar una descripción para el producto",
            CREATE_GROUP: "Crear grupo",
            CREATE_SUBSCRIPTION: "Crear Suscripción",
            CREATE_TIP: "Crear Pronóstico",
            IMAGE: "Imagen",
            PRODUCT_TITLE: "Título del producto",
            TITLE: "Título",
            TIP: "Pronóstico",
            DESCRIPTION: "Descripción",
            SUBSCRIPTION_DAYS: "Días de suscripción",
            ENTER_DURATION_DAYS: "Introduce duración en días",
            TELEGRAM_GROUP: "Grupo en telegram",
            PRODUCT: "Producto",
            SCREENSHOT_CLIENT: "Captura para el cliente",
            GROUP_NAME: "Nombre del grupo",
            PRICE: "Precio",
            MAX_PRICE: "Máximo 350€",
            START_DATE: "Inicio venta producto",
            EXPIRATION_DATE: "Fin venta producto",
          },
          edit: {
            DELETE_ERROR: "Error al eliminar el producto",
            PRODUCT_IMAGE: "Imagen de producto",
            PRODUCT_SCREENSHOT: "Imagen de pronóstico",
            EDIT_TIP: "Editar Pronóstico",
            EDIT_SUBSCRIPTION: "Editar Suscripción",
            PRODUCT: "Producto",
            IMAGE: "Imagen",
            PRODUCT_TITLE: "Título del producto",
            TITLE: "Título",
            TIP: "Pronóstico",
            DESCRIPTION: "Descripción",
            SCREENSHOT_CLIENT: "Captura para el cliente",
            SHOW_TRACKERS: "Mostrar Trackers",
            PRICE: "Precio",
            MAX_PRICE: "Máximo 350€",
            START_DATE: "Inicio venta producto",
            EXPIRATION_DATE: "Fin venta producto",
            UPDATE_PRODUCT: "Actualizar producto",
            DELETE_PRODUCT: "Eliminar producto",
          },
          card: {
            UPDATE_ERROR: "Error al actualizar el estado del producto",
            DELETE_ERROR: "Error al eliminar el producto",
            PRICE: "Precio",
            SIGN_UP: "Registrarse en",
            DURATION: "Duración",
            DAYS: "días",
            STARTS_ON: "Se activa el",
            EXPIRES_ON: "Vence el",
            IN_PROGRESS: "Creando",
            UNRELEASED: "Próximamente",
            PUBLISHING: "Publicando",
            PUBLISHED: "Publicado",
            UNPUBLISHED: "Parado",
            EXPIRED: "Finalizado",
            ERRORED: "En error",
            UNKNOWN: "Desconocido",
            PAUSE: "Pausar",
            RESUME: "Reanudar",
            START: "Iniciar",
          },
          creationTypeSelection: {
            CREATE_SUBSCRIPTION: "Crear Suscripción",
            CREATE_TIP: "Crear Pronóstico",
            SUBSCRIPTION_PAYMENT_TEXT:
              "Crea una suscripción usando los métodos de pago habituales: Tarjeta de crédito, Paypal, Astropay...",
            TIP_PAYMENT_TEXT:
              "Crea un pronóstico usando los métodos de pago habituales: Tarjeta de crédito, Paypal, Astropay...",
            PAYMENT_BUTTON: "Mediante método de pago",
            SUBSCRIPTION_TRACKER_TEXT:
              "Crear una suscripción usando un tracker",
            TIP_TRACKER_TEXT: "Crear un pronóstico usando un tracker",
            TRACKER_BUTTON: "Mediante afiliación",
            BOTH: "Dos en uno",
            BOTH_TEXT: "Para un mismo producto, ambos métodos",
            BOTH_BUTTON: "Método de pago y afiliación",
          },
          selection: {
            PRICE: "Precio",
            DURATION: "Duración",
            DAYS: "Días",
            EXPIRES_ON: "Vence el",
            IN_PROGRESS: "Creando",
            UNRELEASED: "Próximamente",
            PUBLISHING: "Publicando",
            PUBLISHED: "Publicado",
            UNPUBLISHED: "Parado",
            EXPIRED: "Finalizado",
            ERRORED: "En error",
            UNKNOWN: "Desconocido",
            SELECT_PRODUCTS: "Selecciona productos",
            CANCEL: "Cancelar",
            APPLY: "Aplicar",
          },
          telegram: {
            COMPLETE_FIELDS: "Por favor, complete todos los campos",
            PRODUCT_PUBLICATION: "Publicación de producto",
            CODE_WARNING_TEXT:
              "Vas a recibir en telegram un código para crear el canal",
            TWO_STEP_VERIFICATION_TEXT:
              "Si tienes verificación de dos pasos activada, introduce también la contraseña de verificación",
            TELEGRAM_CODE: "Código recibido en telegram",
            ENTER_CODE: "Indroduce el código",
            TWOFA_CODE: "Código 2FA (si lo tienes activado)",
            ENTER_2FA_CODE: "Introduce tu código 2FA",
            STEPS_LIST: "Pasos para comprobar",
            STEP_ONE: "1º Ir a telegram a ajustes",
            STEP_TWO: "2º Privacidad y seguridad",
            STEP_THREE: "3º Verificación en dos pasos",
            STEP_FOUR:
              "Si lo tienes activado, necesitas poner en este campo el código",
            CANCEL: "Cancelar",
          },
          trackerSelection: {
            CONDITIONS: "Condiciones",
            TRACKERS_DELECTION: "Selección de trackers",
            SAVE_CHANGES: "Guardar cambios",
          },
        },
        promo: {
          list: {
            AVAILABLE_PROMOS: "Acuerdos Disponibles",
          },
          card: {
            CONDITIONS: "Condiciones",
          },
          promoInfo: {
            BETTING_SITE_CONDITIONS: "Condiciones de la casa",
            CLIENT_CONDITIONS: "Condiciones para el cliente",
            LEGAL: "Información legal",
            LOADING: "Cargando",
            CLOSE: "Cerrar",
          },
        },
        tracker: {
          list: {
            TOP_PROMOS: "Top Acuerdos",
            PROMOS: "Acuerdos",
            YOUR_LANDING: "Tu Landing",
            COPY_LANDING: "Copiar Landing",
            COUNTRY: "País",
            BETTING_SITE: "Casa",
            TYPE: "Tipo",
          },
          card: {
            COPY_TRACKER: "Copiar Tracker",
            TELEGRAM: "Telegram",
            WEB: "Web",
            HIDE: "Ocultar",
            SHOW: "Mostrar",
            SORT: "Ordenar",
            CONDITIONS: "Condiciones",
          },
          filter: {
            COUNTRY_FILTER: "Filtrar por país",
            BETTING_SITE_FILTER: "Filtrar por casa",
            TYPE_FILTER: "Filtar por tipo",
            CLEAR_ALL: "Clear all",
            SELECT_ALL: "Seleccionar todo",
            DESELECT_ALL: "Deseleccionar todo",
            APPLY: "Aplicar",
          },
          promoInfo: {
            BETTING_SITE_CONDITIONS: "Condiciones de la casa",
            CLIENT_CONDITIONS: "Condiciones para el cliente",
            LEGAL: "Información legal",
            LOADING: "Cargando",
            CLOSE: "Cerrar",
          },
          sort: {
            SORT_PROMOS: "Ordenar acuerdos en",
            COUNTRY: "País",
            BETTING_SITE: "Casa",
            TYPE: "Tipo",
            CLOSE: "Cerrar",
          },
        },
        user: {
          forgot: {
            ALERT_NEW_PASSWORD: "Debe escribir una nueva contraseña",
            ALERT_ACCOUNT_RESTORED: "Cuenta restaurada con éxito",
            ERROR_CHANGING_PASSWORD:
              "Se ha producido un error al cambiar la contraseña",
            COMPLETE_FIELDS: "Por favor, complete todos los campos",
            ALERT_EMAIL_SENT:
              "Te hemos enviado un correo electrónico para activar tu cuenta",
            NEW_PASSWORD: "Nueva contraseña",
            CHANGE_PASSWORD: "Cambiar contraseña",
            EMAIL: "Email",
            WRITE_YOUR_EMAIL: "Pon tu correo electrónico",
            RECOVER_PASSWORD: "Recuperar contraseña",
          },
          login: {
            COMPLETE_FIELDS: "Por favor, complete todos los campos",
            INVALID_DATA: "Datos incorrectos",
            LOGIN: "Entrar",
            NEW_USER: "Nuevo usuario",
            EMAIL: "Email",
            WRITE_YOUR_EMAIL: "Pon tu correo electrónico",
            PASSWORD: "Contraseña",
            RECOVER_PASSWORD: "Recuperar contraseña",
          },
          loginHash: {
            MY_PROFILE: "Mi perfil",
            PENDING: "Verificación pendiente",
            DATA_UPDATED: "Datos actualizados",
            AVATAR: "Avatar",
            NO_AVATAR: "Avatar necesario",
            NAME: "Nombre completo",
            USERNAME: "Nick",
            BIOGRAPHY: "Biografía",
            PHONE_NUMBER: "Teléfono",
            EMAIL: "Email",
            BOT_AVATAR: "Avatar del bot",
            NO_BOT_AVATAR: "Avatar del bot necesario",
            BOT_NAME: "Nombre del bot de ventas (debe terminar en bot)",
            PROMO_BOT_NAME: "Nombre del bot de promos (debe terminar en bot)",
            SEND: "Enviar datos",
            REVIEWING: "Los datos de tu perfil están siendo revisados",
          },
          profile: {
            MY_PROFILE: "Mi perfil",
            PASSWORDS_NOT_MATCH: "Las contraseñas no coinciden",
            DATA_UPDATED: "Datos actualizados",
            YOUR_LANDING: "Tu Landing",
            COPY_LANDING: "Copiar Landing",
            AVATAR: "Avatar",
            NAME: "Nombre completo",
            USERNAME: "Nick",
            BIOGRAPHY: "Biografía",
            PHONE_NUMBER: "Teléfono",
            EMAIL: "Email",
            NEW_PASSWORD: "Nueva contraseña",
            REPEAT_NEW_PASSWORD: "Repetir nueva contraseña",
            AFFILIATE_ID: "ID de afiliado",
            BOT_TOKEN: "Bot token",
            PROMO_BOT_TOKEN: "Promo bot token",
            UPDATE: "Actualizar",
          },
          register: {
            PASSWORDS_NOT_MATCH: "Las contraseñas no coinciden",
            COMPLETE_FIELDS: "Por favor, complete todos los campos",
            ALERT_EMAIL_SENT:
              "Te hemos enviado un correo electrónico para activar tu cuenta",
            LOGIN: "Entrar",
            NEW_USER: "Nuevo usuario",
            NAME_AND_SURNAME: "Nombre y apellidos",
            ENTER_FULL_NAME: "Introduce tu nombre completo",
            NICK: "Nick",
            ENTER_NICK: "Introduce tu nick",
            PHONE_NUMBER: "Teléfono",
            BETSFY_ID: "ID de Betsfy",
            ENTER_BETSFY_ID: "Introduce tu id de afiliado de betsfy",
            EMAIL: "Email",
            PASSWORD: "Contraseña",
            REPEAT_PASSWORD: "Repetir contraseña",
            REGISTER: "Registrarse",
          },
        },
        redirect: {
          ageVerification: {
            OVER_18: "¿Tienes más de 18 años?",
            TO_ACCESS: "Para acceder a",
            MUST_BE_OVER_18: "debes tener más de 18 años",
            NO: "NO",
            YES: "SÍ",
          },
          trackerCard: {
            OPTION: "Opción",
            ADD: "Publicidad",
            CONDITIONS: "Condiciones",
            SIGN_UP_HERE: "Regístrate aquí",
          },
          predictContest: {
            EMAIL_LABEL:
              "Indicanos tu dirección de correo electrónico para ponernos en contacto contigo en caso de resultar ganador",
            EMAIL_PLACEHOLDER: "Dirección de email",
            LEGAL_LABEL: "He leido y acepto los",
            LEGAL_LABEL_SPAN: "términos y condiciones",
            SEND: "Enviar",
            SENDED: "Enviado",
            LEGAL_MODAL: "Términos y condiciones",
            CLOSE: "Cerrar",
            NOTIFICATION_SUCCESS: "¡GRACIAS POR PARTICIPAR!",
            NOTIFICATION_SUCCESS_DETAILS:
              "Tu resultado ha sido enviado con éxito. En caso de ser ganador recibiriás un correo electrónico a la dirección proporcionada. ¡Mucha suerte!",
            NOTIFICATION_ERROR: "HA OCURRIDO UN ERROR",
          },
        },
        shop: {
          landing: {
            PRODUCT_NOT_FOUND: "No se ha podido obtener el producto",
            PAY_WITH_ASTROPAY: "Pagar con Astropay",
            PAY_WITH_PAYPAL: "Pagar con Paypal",
            GO_TO_PAYMENT_GATEWAY: "Ir a la pasarela de pagos",
            PAY_WITH: "Pagar con",
            CARD: "Tarjeta",
            BIZUM: "bizum",
            PRODUCT_DATA: "Datos del producto",
            PRODUCT: "Producto",
            DESCRIPTION: "Descripción",
            DURATION: "Duración",
            EXPIRATION_DATE: "Vence el",
            PRICE: "Precio",
            DISCOUNT_KEY: "Código de descuento",
            FINAL_PRICE: "Precio final",
            MIN_PRICE: "El importe mínimo para este método de pago es",
            ENTER_DISCOUNT_KEY: "Introduce tu código de descuento",
            WRITE_IT_HERE: "Escríbelo aquí",
            APPLY_DISCOUNT: "Aplicar descuento",
          },
        },
      },
    },
  },
];

export default languages;
