import { getPublicUri } from "../../../../../Utils";
import { useLanguage } from "../../../../../languages/useLanguage";

const CardAffiliationComponent = ({ affiliation }) => {
  const { language } = useLanguage();

  return (
    <div className="orders-row-info">
      <div className="title-orders-span">
        <img
          src={
            getPublicUri(window.location.hostname) +
            "/betting-sites/" +
            affiliation?.image
          }
          height={30}
          alt={affiliation.bettingSite}
        />
        <span>{affiliation.description}</span>
        <img
          src={
            getPublicUri(window.location.hostname) +
            "/countries/" +
            affiliation.country?.replace(/ /g, "_") +
            ".png"
          }
          height={20}
          alt={affiliation.country}
        />
      </div>
      <div className="date-orders">
        <span>
          <div style={{ fontSize: "14px", color: "#9498a7" }}>
            {language.words.components.billing.cardAffiliation.CLICKS}
          </div>
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {affiliation.clicks}
          </div>
        </span>
        <span>
          <div style={{ fontSize: "14px", color: "#9498a7" }}>
            {language.words.components.billing.cardAffiliation.REGISTERS}
          </div>
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {affiliation.registers}
          </div>
        </span>
        <span>
          <div style={{ fontSize: "14px", color: "#9498a7" }}>
            {language.words.components.billing.cardAffiliation.DEPOSITS}
          </div>
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {affiliation.deposits}
          </div>
        </span>
        <span>
          <div style={{ fontSize: "14px", color: "#9498a7" }}>
            {language.words.components.billing.cardAffiliation.CPA}
          </div>
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {affiliation.CPA}
          </div>
        </span>
      </div>
      <div className="orders-total">
        <span style={{ fontSize: "14px" }}>
          {language.words.components.billing.cardAffiliation.OTHER_INCOME}
        </span>
        <span style={{ fontSize: "16px", color: "#ffffff" }}>
          {new Intl.NumberFormat("es-ES", {
            style: "currency",
            currency: "EUR",
          }).format(affiliation.otherIncome)}
        </span>
        <span style={{ fontSize: "14px" }}>
          {language.words.components.billing.cardAffiliation.INCOME}
        </span>
        <span style={{ fontSize: "16px", color: "#ffffff" }}>
          {new Intl.NumberFormat("es-ES", {
            style: "currency",
            currency: "EUR",
          }).format(affiliation.income)}
        </span>
      </div>
    </div>
  );
};

export default CardAffiliationComponent;
