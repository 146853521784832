import { useEffect, useState } from "react";
import moment from "moment";
import CardOrder from "./components/CardOrder";
import DatePicker from "./components/DatePicker";
import {
  getUser,
  getBilling,
  getAffiliationReport,
} from "../../../../services/User";
import { useNavigate } from "react-router-dom";
import BillingDetailsModal from "./components/DetailsModal";
import { useLanguage } from "../../../../languages/useLanguage";
import MonthPicker from "./components/MonthPicker";
import CardAffiliation from "./components/CardAffiliation";

const RANGE = {
  from: moment().subtract(30, "days"),
  to: new Date(),
};

export default function BillingListPage() {
  const { language } = useLanguage();
  const [billing, setBilling] = useState({});
  const [{ from, to }, setRange] = useState(RANGE);
  const [showDetails, setShowDetails] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [date, setDate] = useState(new Date());
  const [affiliationReport, setAffiliationReport] = useState({});
  const [totalAffiliation, setTotalAffiliation] = useState(0);
  const [menu, setMenu] = useState("affiliations");
  const navigate = useNavigate();

  const setDates = (event) => {
    const payload = { from, to };

    payload[event.target.name] = +new Date(event.target.value);

    setRange(payload);
  };

  const setMonth = (monthDate) => {
    setDate(new Date(monthDate));
  };

  const fetchBilling = async (range) => {
    const user = await getUser();

    if (!user) throw new Error(language.words.errors.INVALID_USER);

    const _billing = await getBilling(range.from, range.to);

    return _billing;
  };

  const fetchAffiliationReport = async (date) => {
    const user = await getUser();

    if (!user) throw new Error(language.words.errors.INVALID_USER);

    const _affiliationReport = await getAffiliationReport(date);

    return _affiliationReport;
  };

  useEffect(() => {
    fetchBilling({ from, to }).then(setBilling);
  }, [from, to]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchAffiliationReport(date).then(setAffiliationReport);
  }, [date]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTotalAffiliation(() => {
      let total = 0;
      affiliationReport.report?.forEach((report) => {
        total += report.otherIncome + report.income;
      });
      return total;
    });
  }, [affiliationReport]);

  return (
    <div className="container main billing home list">
      <BillingDetailsModal
        {...{
          show: showDetails,
          setShow: setShowDetails,
          orders: orderDetails,
          range: { from, to },
        }}
      />
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="home-header">
        <div
          className={
            "h1-header home-header-title home-header-pill" +
            (menu === "affiliations" ? " active" : " inactive")
          }
          onClick={() => setMenu("affiliations")}
        >
          {language.words.components.billing.list.MY_AFFILIATIONS}
        </div>
        <div
          className={
            "h1-header home-header-title home-header-pill" +
            (menu === "sales" ? " active" : " inactive")
          }
          onClick={() => setMenu("sales")}
        >
          {language.words.components.billing.list.MY_SALES}
        </div>
      </div>
      <div
        className={
          "gray-block" + (menu === "sales" ? " right-menu" : " left-menu")
        }
      >
        {menu === "sales" ? (
          <div className="container-fluid">
            <div className="row">
              <div className="container-fluid">
                <h5 className="ingresos">{`${language.words.components.billing.list.SALES_INCOME}:`}</h5>
                <div className="ingresos-div">
                  <span className="ingresos-total">
                    {new Intl.NumberFormat("es-ES", {
                      style: "currency",
                      currency: "EUR",
                    })
                      .format(billing.total)
                      .slice(0, -2)}
                  </span>
                  <span className="moneda">€</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="container-fluid">
                <DatePicker setDates={setDates} from={from} to={to} />
              </div>
            </div>
            <div className="row">
              <div className="container-fluid">
                <table
                  className="table table-striped billing-list"
                  id="dataTable"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Info</th>
                      <th>Info2</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billing.orders &&
                      billing.orders.length > 0 &&
                      billing.orders.map((order, index) => {
                        return (
                          <tr
                            id={`order-${order._id}`}
                            key={`order-${index}`}
                            className="container-fluid"
                          >
                            <td style={{ display: "none" }}>
                              {+new Date(order.updatedAt)}
                            </td>
                            <td
                              onClick={() => {
                                setOrderDetails(
                                  order.details.sort(
                                    (a, b) =>
                                      new Date(b.updatedAt) -
                                      new Date(a.updatedAt)
                                  )
                                );
                                setShowDetails(true);
                              }}
                            >
                              <CardOrder order={order} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="container-fluid">
                <h5 className="ingresos">{`${language.words.components.billing.list.AFFILIATIONS_INCOME}:`}</h5>
                <div className="ingresos-div">
                  <span className="ingresos-total">
                    {new Intl.NumberFormat("es-ES", {
                      style: "currency",
                      currency: "EUR",
                    })
                      .format(totalAffiliation)
                      .slice(0, -2)}
                  </span>
                  <span className="moneda">€</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="container-fluid">
                <MonthPicker setMonth={setMonth} date={date} />
              </div>
            </div>
            <div className="row">
              <div className="container-fluid">
                <table
                  className="table table-striped billing-list"
                  id="dataTable"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>Info</th>
                      <th>Info2</th>
                    </tr>
                  </thead>
                  <tbody>
                    {affiliationReport.report &&
                      affiliationReport.report.length > 0 &&
                      affiliationReport.report.map((report, index) => {
                        return (
                          <tr
                            id={`report-${report._id}`}
                            key={`report-${index}`}
                            className="container-fluid"
                          >
                            <td style={{ display: "none" }}>
                              {+new Date(affiliationReport.date)}
                            </td>
                            <td>
                              <CardAffiliation affiliation={report} />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
