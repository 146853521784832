import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../../../languages/useLanguage";
import { getUsers } from "../../../../../services/Admin";
import { getUser } from "../../../../../services/User";
import Card from "./components/Card";
import "./Users.css";

const AdminUsersPage = ({ setAlert }) => {
  const { language } = useLanguage();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const user = await getUser();

        if (!user) throw new Error(language.words.errors.INVALID_USER);

        const response = await getUsers();

        setUsers(response);
      } catch (error) {
        setAlert(error?.message || error);
      }
    };

    fetchUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filterRegExp = new RegExp(filter, "i");
    setFilteredUsers(
      users.filter((user) => {
        return (
          user.firstname?.match(filterRegExp) ||
          user.nick?.match(filterRegExp) ||
          user.email?.match(filterRegExp) ||
          user.betsfyId?.match(filterRegExp)
        );
      })
    );
  }, [users, filter]);

  return (
    <div className="container main products list users">
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {language.words.components.admin.users.USERS}
          </div>
          <div className="separator" />
        </div>
        <div className="container-fluid">
          <div className="search">
            <input
              type="search"
              placeholder="buscar"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          <div className="separator" />
        </div>
        <div className="container-fluid">
          {filteredUsers.length > 0
            ? filteredUsers.map((user) => {
                return (
                  <Card key={user._id} userInit={user} setAlert={setAlert} />
                );
              })
            : language.words.components.admin.users.NO_USERS_FOUND}
        </div>
      </div>
    </div>
  );
};

export default AdminUsersPage;
