import { useEffect, useState } from "react";
import useCheckbox from "../../../../../components/hooks/useCheckbox";
import useInput from "../../../../../components/hooks/useInput";
import { useLanguage } from "../../../../../languages/useLanguage";
import { createPrediction } from "../../../../../services/Admin";
import { getPublicUri } from "../../../../../Utils";
import Match from "./Match";
import "./PredictContest.css";

const PredictContest = ({ setLoader, contest, setShowLegal, setLegal }) => {
  const { language } = useLanguage();
  const [showNotification, setShowNotification] = useState(false);

  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationContent, setNotificationContent] = useState("");

  const [predictions, setPredictions] = useState([]);
  const email = useInput("");
  const legal = useCheckbox(false);

  const showNotificationMessage = (title, content) => {
    setNotificationTitle(title);
    setNotificationContent(content);
    setShowNotification(true);
  };

  const resetContestForm = () => {
    email.setValue("");
    legal.setChecked(false);
  };

  const send = async (e, contestId) => {
    e.preventDefault();
    try {
      setLoader(true);

      const referrer = document.location.pathname.replace(/\//g, "");

      await createPrediction(contestId, {
        email: email.value,
        matchs: predictions,
        referrer,
      });

      showNotificationMessage(
        language.words.components.redirect.predictContest.NOTIFICATION_SUCCESS,
        language.words.components.redirect.predictContest
          .NOTIFICATION_SUCCESS_DETAILS
      );

      resetContestForm();
    } catch (error) {
      console.log(
        language.words.components.redirect.predictContest.NOTIFICATION_ERROR
      );
      showNotificationMessage(
        language.words.components.redirect.predictContest.NOTIFICATION_ERROR,
        error?.message || error
      );
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    setPredictions(() =>
      contest.matchs?.map((match) => {
        return {
          match: match._id,
          home: {
            name: match.home.name,
            score: "",
          },
          away: {
            name: match.away.name,
            score: "",
          },
        };
      })
    );
  }, [contest.matchs]);

  return (
    <div className="contest">
      {contest.header?.image && (
        <div className="header-image">
          <img
            src={
              getPublicUri(window.location.hostname) +
              "/" +
              contest.header?.image
            }
            alt="contest-header"
          />
        </div>
      )}
      <div className="match-wapper">
        <div
          className="notification"
          style={{ visibility: showNotification ? "visible" : "hidden" }}
        >
          <div className="notification-title">{notificationTitle}</div>
          <div className="notification-content">{notificationContent}</div>
          <button
            className="notification-button"
            onClick={() => setShowNotification(false)}
          >
            {language.words.components.redirect.predictContest.CLOSE}
          </button>
        </div>
        <form onSubmit={(e) => send(e, contest._id)}>
          {predictions?.map((prediction, index) => {
            return (
              <div key={"match-" + index}>
                <Match
                  prediction={prediction}
                  setPredictions={setPredictions}
                  index={index}
                />
              </div>
            );
          })}
          <div className="email-wrapper">
            <label htmlFor="email">
              {language.words.components.redirect.predictContest.EMAIL_LABEL}
            </label>
            <input
              type="text"
              id="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              required
              placeholder={
                language.words.components.redirect.predictContest
                  .EMAIL_PLACEHOLDER
              }
              {...email.inputAttributes}
            />
          </div>
          <div className="legal-wrapper form-check">
            <input
              type="checkbox"
              id="legal"
              className="form-check-input"
              required
              {...legal.checkboxAttributes}
            />
            <label htmlFor="legal" className="form-check-label">
              {language.words.components.redirect.predictContest.LEGAL_LABEL}{" "}
              <span
                onClick={(e) => {
                  e.preventDefault();
                  setLegal(contest.legal);
                  setShowLegal(true);
                }}
              >
                {
                  language.words.components.redirect.predictContest
                    .LEGAL_LABEL_SPAN
                }
              </span>
            </label>
          </div>
          <button type="submit" className="submit-button">
            {language.words.components.redirect.predictContest.SEND}
          </button>
        </form>
      </div>
      {contest.footer?.image && (
        <div className="footer-image">
          <img
            src={
              getPublicUri(window.location.hostname) +
              "/" +
              contest.footer?.image
            }
            alt="contest-footer"
          />
        </div>
      )}
    </div>
  );
};

export default PredictContest;
