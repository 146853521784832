import moment from "moment";
import { useLanguage } from "../../../../../languages/useLanguage";
import "./CardDetails.css";

const BETMEDIANEXT_DATE = "2023-04-01T00:00:00";

const CardOrderDetails = ({ order }) => {
  const { language } = useLanguage();

  return (
    <div className="order-details">
      <div className="order-date">
        <span>{moment(order.updatedAt).format("DD/MM/YYYY HH:mm:ss")}</span>
      </div>
      <div>{`${language.words.components.billing.cardOrderDetails.USER}: ${order.user.firstname}`}</div>
      <div>
        {`${language.words.components.billing.cardOrderDetails.TYPE}: `}
        {order.tracker || order.meta?.tracker
          ? `${language.words.components.billing.cardDetails.APPROVAL_AT} ` +
            (order.tracker?.promo?.bettingSite || order.meta?.tracker)
          : order.type}
      </div>
      {order.value !== null && order.value !== undefined && (
        <>
          <div>
            {`${language.words.components.billing.cardOrderDetails.GROSS_EARNINGS}: `}
            {new Intl.NumberFormat("es-ES", {
              style: "currency",
              currency: "EUR",
            }).format(order.value)}
          </div>
          <div>
            {`${language.words.components.billing.cardOrderDetails.NET_EARNINGS}: `}
            {new Intl.NumberFormat("es-ES", {
              style: "currency",
              currency: "EUR",
            }).format(
              order.value *
                (new Date(order.updatedAt) < new Date(BETMEDIANEXT_DATE)
                  ? 0.85
                  : 0.9)
            )}
          </div>
          {order.meta.coupon && (
            <div>
              {`${language.words.components.billing.cardOrderDetails.DISCOUNT_COUPON}: `}
              {order.meta.coupon.key +
                ": -" +
                order.meta.coupon.value +
                (/%/.test(order.meta.coupon.value) ? "" : "€")}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CardOrderDetails;
