import { useEffect, useState } from "react";
import { useLanguage } from "../../../../../languages/useLanguage";
import { getPromo } from "../../../../../services/Promo";
import { getUser } from "../../../../../services/User";
import "./PromoInfoModal.css";

export default function PromoInfoModal({ show, setShow, promoId }) {
  const { language } = useLanguage();
  const [promo, setPromo] = useState({});

  const toggleShow = () => {
    setShow((prev) => !prev);
  };

  const fetchPromo = async (promoId) => {
    if (!promoId) return {};

    const user = await getUser();

    if (!user) throw new Error(language.words.errors.INVALID_USER);

    const _promo = await getPromo(promoId);

    return _promo;
  };

  useEffect(() => {
    fetchPromo(promoId).then(setPromo);
  }, [show, promoId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const body = document.querySelector("body");

    if (show) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [show]);

  return (
    <div
      className={"promo modal fade" + (show && " show")}
      style={{
        display: "block",
        visibility: show ? "visible" : "hidden",
        maxHeight: "100vh",
        maxWidth: "100vw",
      }}
      onClick={toggleShow}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header"></div>
          <div
            className="modal-body"
            style={{ maxHeight: "65vh", overflowY: "auto" }}
          >
            <div className="gray-block">
              <div className="container-fluid">
                {promo._id ? (
                  <div className="form">
                    <h5 className="promo-title">
                      {promo._id
                        ? promo.bettingSite?.toUpperCase()
                        : "Cargando promo"}
                    </h5>
                    <div className="separator" />
                    <div className="promo-conditions-label">
                      {`${language.words.components.tracker.promoInfo.BETTING_SITE_CONDITIONS}:`}
                    </div>
                    <p className="promo-conditions-body">
                      {promo.summary.replace(/<br>/g, "\n")}
                    </p>
                    <div className="separator" />
                    <div className="promo-conditions-label">
                      {`${language.words.components.tracker.promoInfo.CLIENT_CONDITIONS}:`}
                    </div>
                    <p className="promo-conditions-body">
                      {promo.conditions.replace(/<br>/g, "\n")}
                    </p>
                    <div className="separator" />
                    <div className="promo-conditions-label">
                      {`${language.words.components.tracker.promoInfo.LEGAL}:`}
                    </div>
                    <p className="promo-conditions-body">
                      {promo.legal.replace(/<br>/g, "\n")}
                    </p>
                  </div>
                ) : (
                  language.words.components.tracker.promoInfo.LOADING
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={toggleShow}
            >
              {language.words.components.tracker.promoInfo.CLOSE}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
