import { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useInput from "../../../../../components/hooks/useInput";
import InputImage from "../../../../../components/InputImage";
import config from "../../../../../Config";
import { useLanguage } from "../../../../../languages/useLanguage";
import { loginHash, registerHash } from "../../../../../services/User";
import { getPublicUri } from "../../../../../Utils";
import "./LoginHash.css";

const LoginHashPage = ({ setLoader, setAlert }) => {
  // boolean ref to only render once in delevopment mode
  const isRedirectedRef = useRef(false);

  const navigate = useNavigate();

  const { language } = useLanguage();
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState(null);

  const firstname = useInput("");
  const nick = useInput("");
  const [profilePic, setprofilePic] = useState("");
  const biography = useInput("");
  const phoneNumber = useInput("");
  const [botAvatar, setBotAvatar] = useState("");
  const botName = useInput("");
  const promoBotName = useInput("");

  const setAllValues = (data) => {
    setprofilePic(data.profilePic || "");
    firstname.setValue(data.firstname || "");
    nick.setValue(data.nick || "");
    biography.setValue(data.biography || "");
    phoneNumber.setValue(data.phoneNumber || "");
    setBotAvatar(data.pending?.botAvatar || "");
    botName.setValue(data.pending?.botName || "");
    promoBotName.setValue(data.pending?.promoBotName || "");
  };

  const send = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        hash: searchParams.get("hash"),
        user: {
          email: searchParams.get("email"),
          profilePic: profilePic,
          firstname: firstname.value,
          nick: nick.value,
          biography: biography.value,
          phoneNumber: phoneNumber.value,
          pending: {
            botAvatar: botAvatar,
            botName: botName.value,
            promoBotName: promoBotName.value,
          },
        },
      };

      const _user = await registerHash(payload);

      setUser(_user);
    } catch (error) {
      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (isRedirectedRef.current) return;

    isRedirectedRef.current = true;

    const fetchLoginHash = async () => {
      try {
        setLoader(true);

        const payload = {
          betsfyId: searchParams.get("betsfyId"),
          email: searchParams.get("email"),
          nickname: searchParams.get("nickname"),
          hash: searchParams.get("hash"),
        };

        const _user = await loginHash(payload);

        setUser(_user);

        if (_user.status === "active") {
          navigate(config.client.uris.products);
        }
      } catch (error) {
        console.error(error);

        setAlert(error?.message || error);
      } finally {
        setLoader(false);
      }
    };

    fetchLoginHash();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?._id) {
      setAllValues(user);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return user?._id ? (
    <div className="container login-hash">
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {language.words.components.user.loginHash.MY_PROFILE}
          </div>
          <div className="separator" />
          {user.pending?.status === "incomplete" && (
            <form onSubmit={send}>
              <div className="notes">
                <label className="control-label" htmlFor="image">
                  {user.pending.notes}
                </label>
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="image">
                  {language.words.components.user.loginHash.AVATAR}
                </label>
                <div className="profile-pic">
                  <div className="profile-pic-wrapper">
                    <img
                      src={
                        profilePic
                          ? `${getPublicUri(
                              window.location.hostname
                            )}/${profilePic}`
                          : "https://dummyimage.com/100x100?text=" +
                            language.words.components.user.loginHash.AVATAR
                      }
                      alt={language.words.components.user.loginHash.AVATAR}
                    />
                  </div>
                  <InputImage
                    {...{
                      type: "profile",
                      tipsterId: user._id,
                      setImage: setprofilePic,
                      setAlert,
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="firstname">
                  {language.words.components.user.loginHash.NAME}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="firstname"
                  id="firstname"
                  required={true}
                  {...firstname.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="nick">
                  {language.words.components.user.loginHash.USERNAME}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="nick"
                  id="nick"
                  {...nick.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="biography">
                  {language.words.components.user.loginHash.BIOGRAPHY}
                </label>
                <textarea
                  className="form-control"
                  name="biography"
                  id="biography"
                  maxLength={255}
                  style={{ height: "150px" }}
                  {...biography.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="phoneNumber">
                  {`${language.words.components.user.loginHash.PHONE_NUMBER} *`}
                </label>
                <input
                  className="form-control"
                  id="phoneNumber"
                  type="tel"
                  name="phoneNumber"
                  required={true}
                  {...phoneNumber.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="image">
                  {language.words.components.user.loginHash.BOT_AVATAR}
                </label>
                <div className="profile-pic">
                  <div className="profile-pic-wrapper">
                    <img
                      src={
                        botAvatar
                          ? `${getPublicUri(
                              window.location.hostname
                            )}/${botAvatar}`
                          : "https://dummyimage.com/100x100?text=" +
                            language.words.components.user.loginHash.BOT_AVATAR
                      }
                      alt={language.words.components.user.loginHash.BOT_AVATAR}
                    />
                  </div>
                  <InputImage
                    {...{
                      type: "profile",
                      tipsterId: user._id,
                      setImage: setBotAvatar,
                      setAlert,
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="botName">
                  {language.words.components.user.loginHash.BOT_NAME}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="botName"
                  id="botName"
                  {...botName.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="promoBotName">
                  {language.words.components.user.loginHash.PROMO_BOT_NAME}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="promoBotName"
                  id="promoBotName"
                  {...promoBotName.inputAttributes}
                />
              </div>
              <div className="form-group">
                <input
                  className="btn btn-primary"
                  type="submit"
                  value={language.words.components.user.loginHash.UPDATE}
                />
              </div>
            </form>
          )}
          {user.pending?.status === "reviewing" && (
            <div className="notes">
              <label className="control-label" htmlFor="image">
                {language.words.components.user.loginHash.REVIEWING}
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="container-fluid"></div>
  );
};

export default LoginHashPage;
