import { useEffect } from "react";
import useInput from "../../../../../components/hooks/useInput";

const Match = ({ prediction, setPredictions, index }) => {
  const scoreHome = useInput(prediction.home.score);
  const scoreAway = useInput(prediction.away.score);

  useEffect(() => {
    setPredictions((prev) => {
      if (prev) {
        prev[index].home.score = scoreHome.value;
        prev[index].away.score = scoreAway.value;

        return [...prev];
      }
    });
  }, [scoreHome.value, scoreAway.value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    scoreHome.setValue(prediction.home.score);
    scoreAway.setValue(prediction.away.score);
  }, [prediction]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="match row">
      <div className="home col-5">
        <div className="name">{prediction.home.name}</div>
        <div className="score">
          <input
            type="number"
            id="scoreHome"
            min={0}
            step={1}
            max={20}
            required
            placeholder="0"
            {...scoreHome.inputAttributes}
          />
        </div>
      </div>
      <div className="vs col-2">
        <span>VS</span>
      </div>
      <div className="away col-5">
        <div className="name">{prediction.away.name}</div>
        <div className="score">
          <input
            type="number"
            id="scoreAway"
            min={0}
            step={1}
            max={20}
            placeholder="0"
            required
            {...scoreAway.inputAttributes}
          />
        </div>
      </div>
    </div>
  );
};

export default Match;
