import { sendLog } from "./App";
import { getApiUri } from "../Utils";

export const getProduct = async (productId) => {
  try {
    const apiUri = getApiUri(window.location.hostname);

    const response = await fetch(apiUri + "/products/" + productId, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status !== 200) throw new Error("Error getting product");

    const product = await response.json();

    return product;
  } catch (error) {
    sendLog("error", error);

    return null;
  }
};

export const getProductToBuy = async (productId) => {
  try {
    const apiUri = getApiUri(window.location.hostname);

    const response = await fetch(apiUri + "/products/" + productId + "/buy", {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    });

    if (response.status !== 200) throw new Error("Error getting product");

    const product = await response.json();

    return product;
  } catch (error) {
    sendLog("error", error);

    return null;
  }
};

export const getProducts = async (type) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/products" + (type ? `?type=${type}` : "");

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) throw new Error("Error getting products");

  const products = await response.json();

  return products?.products || products;
};

export const deleteProduct = async (productId) => {
  try {
    const apiUri = getApiUri(window.location.hostname);

    const response = await fetch(apiUri + "/products/" + productId, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status !== 200) throw new Error("Error deleting product");

    const product = await response.json();

    return product;
  } catch (error) {
    sendLog("error", error);

    return null;
  }
};

export const updateProduct = async (productId, body, headers = {}) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/products/" + productId, {
    method: "PUT",
    mode: "cors",
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body,
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const product = await response.json();

  return product;
};

export const createProduct = async (formData) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/products/", {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: formData,
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const product = await response.json();

  return product;
};
