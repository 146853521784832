import { useEffect } from "react";
import { useLanguage } from "../../../../../languages/useLanguage";
import "./LegalModal.css";

const LegalModal = ({ show, setShow, legal }) => {
  const { language } = useLanguage();

  const toggleShow = () => {
    setShow((prev) => !prev);
  };

  useEffect(() => {
    const body = document.querySelector("body");

    if (show) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [show]);

  return (
    <div
      className={"promo modal fade" + (show && " show")}
      style={{
        display: "block",
        visibility: show ? "visible" : "hidden",
        maxHeight: "100vh",
        maxWidth: "100vw",
      }}
      onClick={toggleShow}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <div className="h1-header">
              {language.words.components.redirect.predictContest.LEGAL_MODAL}
            </div>
          </div>
          <div
            className="modal-body"
            style={{ maxHeight: "65vh", overflowY: "auto" }}
          >
            <div className="gray-block">
              <div className="container-fluid">
                <div className="legal">
                  {legal?.split(/\r?\n|\r|\n/g).map((paragraph, index) => {
                    return <p key={"paragraph-" + index}>{paragraph}</p>;
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={toggleShow}
            >
              {language.words.components.redirect.predictContest.CLOSE}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalModal;
