import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { forgot, restorePassword } from "../../../../services/User";
import { sendLog } from "../../../../services/App";
import config from "../../../../Config";
import { useLanguage } from "../../../../languages/useLanguage";

export default function ForgotPage({ setAlert }) {
  const { language } = useLanguage();
  const [form, setState] = useState({});
  const params = useParams({ hash: null });
  const navigate = useNavigate();

  const redirect = () =>
    setTimeout(() => window.location.replace(config.client.uris.login), 2500);

  const update = (event) => {
    const payload = { ...form };

    payload[event.target.name] = event.target.value;

    setState(payload);
  };

  const sendPassword = async () => {
    try {
      const node = document.getElementById("password");

      if (!node.value)
        return setAlert(
          language.words.components.user.forgot.ALERT_NEW_PASSWORD
        );

      await restorePassword(params.hash, node.value);

      setAlert(language.words.components.user.forgot.ALERT_ACCOUNT_RESTORED);
      redirect();
    } catch (error) {
      sendLog("error", error);

      alert(language.words.components.user.forgot.ERROR_CHANGING_PASSWORD);
      redirect();
    }
  };

  const send = async () => {
    try {
      if (!form.email)
        return setAlert(language.words.components.user.forgot.COMPLETE_FIELDS);

      await forgot(form);

      setAlert(language.words.components.user.forgot.ALERT_EMAIL_SENT);
    } catch (error) {
      sendLog("error", error);

      setAlert(error.message);
    }
  };

  return (
    <div className="login-page">
      <div className="card mx-auto border-0">
        <div className="container">
          <div className="btn-navigate-back" onClick={() => navigate(-1)}>
            {`<< ${language.words.navigation.BACK}`}
          </div>
          <div className="card-body pb-4">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane show active"
                id="pills-login"
                role="tabpanel"
                aria-labelledby="pills-login-tab"
              >
                {params.hash ? (
                  <Fragment>
                    <div className="form-group">
                      <label className="control-label" htmlFor="email">
                        {language.words.components.user.forgot.NEW_PASSWORD}
                      </label>
                      <input
                        className="form-control"
                        id="password"
                        type="password"
                        name="password"
                        placeholder={
                          language.words.components.user.forgot.NEW_PASSWORD
                        }
                        autoFocus={true}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        className="btn btn-primary submit"
                        type="button"
                        value={
                          language.words.components.user.forgot.CHANGE_PASSWORD
                        }
                        onClick={sendPassword}
                      />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="form-group">
                      <label className="control-label" htmlFor="email">
                        {language.words.components.user.forgot.EMAIL}
                      </label>
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder={
                          language.words.components.user.forgot.WRITE_YOUR_EMAIL
                        }
                        autoFocus={true}
                        onChange={update}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        className="btn btn-primary submit"
                        type="button"
                        value={
                          language.words.components.user.forgot.RECOVER_PASSWORD
                        }
                        onClick={send}
                      />
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
