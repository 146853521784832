import React from 'react'
import './Loader.css'

export default function LoaderComponent ({ isLoading }) {
  if (!isLoading) return ''

  return (
    <div className='loader-wrapper'>
      <div className='loader-icon' />
    </div>
  )
}
