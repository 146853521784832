import { useState } from "react";
import { updateProduct } from "../../../../../services/Product";
import { sendLog } from "../../../../../services/App";
import { useLanguage } from "../../../../../languages/useLanguage";

export default function TelegramModal({ product, setAlert }) {
  const { language } = useLanguage();
  const [form, updateForm] = useState({});
  const [closed, closeModal] = useState(false);

  const close = () => {
    closeModal(true);
  };

  const update = (event) => {
    const payload = { ...form };

    payload[event.target.name] = event.target.value;

    updateForm(payload);
  };

  const send = async () => {
    try {
      if (!form.telegramCode)
        return setAlert(
          language.words.components.product.telegram.COMPLETE_FIELDS
        );

      const body = JSON.stringify({
        status: "publishing",
        meta: { ...product.meta, ...form },
      });
      const headers = {
        "Content-Type": "application/json",
      };

      await updateProduct(product._id, body, headers);

      setTimeout(() => {
        window.location.reload();
        close();
      }, 1000);
      close();
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message || error);
    }
  };

  if (closed) return "";

  return (
    <div
      className="modal fade"
      id="publishModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="publishModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog dates">
        <div className="modal-content" style={{ backgroundColor: "#343945" }}>
          <div className="modal-header">
            <h4 className="modal-title" id="publishModalLabel">
              {language.words.components.product.telegram.PRODUCT_PUBLICATION}
            </h4>
          </div>
          <div className="modal-body publish-form-info">
            <p>{`${language.words.components.product.telegram.CODE_WARNING_TEXT}.`}</p>
            <small>
              {`${language.words.components.product.telegram.TWO_STEP_VERIFICATION_TEXT}.`}
            </small>
            <br />
            <br />
            <div className="form-signin create-chanel-product">
              <div className="form-group">
                <input
                  style={{ display: "none" }}
                  type="text"
                  name="fakecode"
                />
                <label htmlFor="code">
                  {language.words.components.product.telegram.TELEGRAM_CODE}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="telegramCode"
                  id="code"
                  onChange={update}
                  placeholder={
                    language.words.components.product.telegram.ENTER_CODE
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">
                  {language.words.components.product.telegram.TWOFA_CODE}
                </label>
                <input
                  className="form-control"
                  type="password"
                  name="telegramPassword"
                  id="code"
                  onChange={update}
                  placeholder={
                    language.words.components.product.telegram.ENTER_2FA_CODE
                  }
                  autoComplete="new-password"
                  autoFocus={true}
                />
                <br />
                <br />
                <div className="popup">
                  <h4>
                    {language.words.components.product.telegram.STEPS_LIST}
                  </h4>
                  <p>{language.words.components.product.telegram.STEP_ONE}</p>
                  <p>{language.words.components.product.telegram.STEP_TWO}</p>
                  <p>{language.words.components.product.telegram.STEP_THREE}</p>
                  <p className="activada-pass">
                    {language.words.components.product.telegram.STEP_FOUR}
                  </p>
                </div>
              </div>
              <div className="form-group">
                <input
                  className="btn btn-primary publish"
                  type="button"
                  value="Crear canal y publicar producto"
                  onClick={send}
                />
              </div>
            </div>
            <span className="bottom-close"></span>
            <button
              className="close btn btn-primary"
              type="button"
              onClick={close}
            >
              {language.words.components.product.telegram.CANCEL}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
