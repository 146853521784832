import { useEffect, useState } from "react";
import { useLanguage } from "../languages/useLanguage";
import { deleteImage, getImages, uploadImage } from "../services/Images";
import { getUser } from "../services/User";
import { getPublicUri } from "../Utils";
import "./ImageModal.css";

const ImageModal = ({ show, setShow, type, tipsterId, setImage, setAlert }) => {
  const { language } = useLanguage();
  const [images, setImages] = useState([]);

  const [imageUpload, setImageUpload] = useState(null);

  const toggleShow = () => {
    setShow((prev) => !prev);
  };

  const fetchImages = async () => {
    try {
      const user = await getUser();

      if (!user) throw new Error(language.words.errors.INVALID_USER);

      const response = await getImages(type, tipsterId);

      setImages(response);
    } catch (error) {
      setAlert(error?.message || error);
    }
  };

  const uploadImg = async () => {
    try {
      const user = await getUser();

      if (!user) throw new Error(language.words.errors.INVALID_USER);

      await uploadImage(imageUpload, type, tipsterId);

      fetchImages();
    } catch (error) {
      setAlert(error?.message || error);
    }
  };

  const deleteImg = async (id) => {
    try {
      const user = await getUser();

      if (!user) throw new Error(language.words.errors.INVALID_USER);

      await deleteImage(id);

      fetchImages();
    } catch (error) {
      setAlert(error?.message || error);
    }
  };

  // update images
  useEffect(() => {
    if (show) {
      fetchImages();
    }
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  // anti-scroll page under modal
  useEffect(() => {
    const body = document.querySelector("body");

    if (show) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [show]);

  return (
    <div
      className={"modal fade" + (show && " show")}
      style={{
        display: "block",
        visibility: show ? "visible" : "hidden",
        maxHeight: "100vh",
        maxWidth: "100vw",
      }}
      onClick={toggleShow}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <h4>{language.words.components.images.modal.IMAGES}</h4>
          </div>
          <div
            className="modal-body"
            style={{ maxHeight: "65vh", overflowY: "auto" }}
          >
            <div className="container-fluid card-group">
              {images?.map((image) => {
                return (
                  <div key={image._id}>
                    <div
                      className="card"
                      style={{ width: "260px", margin: "10px" }}
                    >
                      <img
                        src={
                          getPublicUri(window.location.hostname) +
                          "/" +
                          image.filename
                        }
                        alt={type}
                        className="card-img-top"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "200px",
                          objectFit: "contain",
                        }}
                      />
                      <div className="card-body text-center row">
                        <button
                          className="btn btn-primary col-6"
                          type="button"
                          onClick={(e) => {
                            setImage(image.filename || null);
                            toggleShow();
                          }}
                        >
                          {language.words.components.images.modal.SELECT}
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger col-6"
                          onClick={() => {
                            if (
                              window.confirm(
                                language.words.components.images.modal
                                  .DELETE_CONFIRM
                              )
                            ) {
                              deleteImg(image._id || "");
                            }
                          }}
                        >
                          {language.words.components.images.modal.DELETE}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-footer">
            <input
              type="file"
              onChange={(e) => {
                if (e.target.files) {
                  setImageUpload(e.target.files[0]);
                }
              }}
            />
            <button
              type="button"
              className="btn btn-success"
              onClick={uploadImg}
            >
              {language.words.components.images.modal.UPLOAD_IMAGE}
            </button>
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={toggleShow}
            >
              {language.words.components.images.modal.CANCEL}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
