import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../../../languages/useLanguage";
import { getPaymentMethods } from "../../../../../services/Admin";
import { getUser } from "../../../../../services/User";
import Card from "./components/Card";

const AdminPaymentPage = ({ setAlert }) => {
  const { language } = useLanguage();
  const [methods, setMethods] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const user = await getUser();

        if (!user) throw new Error(language.words.errors.INVALID_USER);

        const response = await getPaymentMethods();

        setMethods(response);
      } catch (error) {
        setAlert(error?.message || error);
      }
    };

    fetchPaymentMethods();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container main products list">
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {language.words.components.admin.payment.PAYMENT_METHODS}
          </div>
          <div className="separator" />
        </div>
        {methods &&
          methods.length > 0 &&
          methods.map((method) => {
            return (
              <Card
                key={"method-" + method._id}
                methodInit={method}
                setAlert={setAlert}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AdminPaymentPage;
