import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../../Config";
import {
  checkCredentials,
  getUserTrackers,
  getUserGroups,
} from "../../../../services/User";
import { createProduct } from "../../../../services/Product";
import { sendLog } from "../../../../services/App";
import CreationTypeSelection from "./components/CreationTypeSelection";
import TrackerSelection from "./components/TrackerSelection";
import "./Create.css";
import moment from "moment";
import { useLanguage } from "../../../../languages/useLanguage";

export default function ProductCreatePage({ setLoader, setAlert }) {
  const { language } = useLanguage();
  const [creationType, setCreationType] = useState(null);
  const [form, setForm] = useState({});
  const [previewImage, setPreviewImage] = useState(
    "https://dummyimage.com/240x240?text=" +
      language.words.components.product.create.PRODUCT_IMAGE
  );
  const [previewScreenshot, setPreviewScreenshot] = useState(
    "https://dummyimage.com/240x240?text=" +
      language.words.components.product.create.PRODUCT_SCREENSHOT
  );
  const [image, setImage] = useState({});
  const [screenshot, setScreenshot] = useState({});
  const [trackers, setTrackers] = useState([]);
  const [groups, setGroups] = useState([]);
  const { type } = useParams({ type: "suscription" });
  const navigate = useNavigate();

  const fetchTrackers = async () => {
    const trackers = await getUserTrackers();

    return trackers;
  };

  const fetchGroups = async () => {
    const trackers = await getUserGroups();

    return trackers;
  };

  const update = (event) => {
    const payload = { ...form };

    payload[event.target.name] = event.target.value;

    setForm(payload);
  };

  const updateTrackers = (trackers) => setForm({ ...form, trackers });

  const uploadImage = (event) => {
    const previewImagePath = URL.createObjectURL(event.target.files[0]);

    setImage(event.target);
    setPreviewImage(previewImagePath);
  };

  const uploadScreenshot = (event) => {
    const previewImagePath = URL.createObjectURL(event.target.files[0]);

    setScreenshot(event.target);
    setPreviewScreenshot(previewImagePath);
  };

  const send = async () => {
    try {
      if (creationType === "promo" && !(form?.trackers || []).length) {
        return setAlert(
          language.words.components.product.create.UNSELECTED_TRACKERS
        );
      }

      if (form.type === "suscription" && !form.days) {
        const confirm = window.confirm(
          language.words.components.product.create.BUYER_NEVER_EXPELLED_CONFIRM
        );

        if (!confirm) return;
      }

      if (form.type === "tip" && !form.expirationDate) {
        return setAlert(
          language.words.components.product.create.MUST_SELECT_EXPIRATION_DATE
        );
      }

      if (+new Date(form.expirationDate) <= +new Date()) {
        return setAlert(
          language.words.components.product.create.HIGHER_EXPIRATION_DATE
        );
      }

      if (!form.price && !form.isPromo) {
        return setAlert(
          language.words.components.product.create.MUST_SELECT_PRICE
        );
      }

      if (
        form.type === "suscription" &&
        form.telegram ===
          language.words.components.product.create.CREATE_GROUP &&
        !form.newTelegram
      ) {
        return setAlert(
          language.words.components.product.create.MUST_SPECIFY_GROUP
        );
      }

      if (!form.title) {
        return setAlert(
          language.words.components.product.create.MUST_SPECIFY_GROUP_NAME
        );
      }

      if (!form.description) {
        const desc =
          form.type === "tip"
            ? language.words.components.product.create.MUST_SPECIFY_RESULT
            : language.words.components.product.create.MUST_SPECIFY_DESCRIPTION;

        return setAlert(desc);
      }

      const formData = new FormData();

      if (image?.files) {
        formData.append("image", image.files[0]);
      }

      if (screenshot?.files) {
        formData.append("screenshot", screenshot.files[0]);
      }

      Object.entries(form).forEach((obj) => {
        formData.append(obj[0], obj[1]);
      });

      setLoader(true);

      if (creationType === "both") {
        formData.set("isPayment", true);
        formData.set("isPromo", true);

        if ((form?.trackers || []).length) await createProduct(formData);
      } else if (creationType === "promo") {
        formData.set("isPayment", false);
        formData.set("isPromo", true);

        if ((form?.trackers || []).length) await createProduct(formData);
      } else if (creationType === "payment") {
        formData.set("isPayment", true);
        formData.set("isPromo", false);

        await createProduct(formData);
      }

      window.location.replace(config.client.uris[form.type + "s"]);
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message || error);
    }
  };

  useEffect(() => {
    checkCredentials();

    const isPromo = creationType === "promo";

    setForm({
      ...form,
      ...(isPromo ? { price: 249 } : {}),
      isPromo,
      type,
      telegram: language.words.components.product.create.CREATE_GROUP,
    });

    fetchTrackers().then(setTrackers).catch(console.error);

    fetchGroups().then(setGroups).catch(console.error);
  }, [creationType]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!creationType)
    return (
      <CreationTypeSelection setCreationType={setCreationType} type={type} />
    );

  if (
    ["both", "promo"].indexOf(creationType) >= 0 &&
    !(form?.trackers || []).length
  )
    return (
      <TrackerSelection trackers={trackers} updateTrackers={updateTrackers} />
    );

  return (
    <div
      className="container main"
      onKeyDown={(e) => e.key === "Enter" && send()}
    >
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <div className="separator" />
              <div className="h1-header">
                {type === "tip"
                  ? language.words.components.product.create.CREATE_TIP
                  : type === "suscription"
                  ? language.words.components.product.create.CREATE_SUBSCRIPTION
                  : ""}
              </div>
              <div className="separator" />
            </div>
          </div>
          <div className="form">
            <div className="preview-upload-file form-group">
              <img
                id="preview-upload-file__image"
                src={previewImage}
                alt="preview"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="image">
                {language.words.components.product.create.IMAGE}
              </label>
              <input
                className="form-control"
                type="file"
                name="image"
                id="image"
                onChange={uploadImage}
                accept="image/*"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="title">
                {`${language.words.components.product.create.PRODUCT_TITLE} *`}
              </label>
              <input
                className="form-control"
                type="text"
                name="title"
                id="title"
                onChange={update}
                placeholder={language.words.components.product.create.TITLE}
              />
            </div>
            <div className="form-group">
              <label
                className="control-label"
                id="description-label"
                htmlFor="description"
              >
                {form.type === "tip"
                  ? language.words.components.product.create.TIP
                  : language.words.components.product.create.DESCRIPTION}{" "}
                *
              </label>
              <textarea
                className="form-control"
                name="description"
                id="description"
                onChange={update}
                rows="11"
              />
            </div>
            {form.type === "suscription" ? (
              <Fragment>
                <div className="form-group">
                  <label htmlFor="days" className="control-label">
                    {language.words.components.product.create.SUBSCRIPTION_DAYS}
                  </label>
                  <input
                    id="days"
                    onChange={update}
                    type="number"
                    name="days"
                    className="form-control"
                    placeholder={
                      language.words.components.product.create
                        .ENTER_DURATION_DAYS
                    }
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="telegram" className="control-label">
                    {`${language.words.components.product.create.TELEGRAM_GROUP} *`}
                  </label>
                  <select
                    id="telegram"
                    onChange={update}
                    list="telegram"
                    name="telegram"
                    className="form-control"
                    placeholder={
                      language.words.components.product.create.TELEGRAM_GROUP
                    }
                  >
                    <option
                      defaultValue={
                        language.words.components.product.create.CREATE_GROUP
                      }
                    >
                      {language.words.components.product.create.CREATE_GROUP}
                    </option>
                    {groups.map((group, index) => (
                      <option key={`group-${index}`} defaultValue={group.title}>
                        {group.title}
                      </option>
                    ))}
                  </select>
                </div>
              </Fragment>
            ) : (
              <>
                <div className="preview-upload-file form-group">
                  <img
                    id="preview-upload-file__image"
                    src={previewScreenshot}
                    alt={language.words.components.product.create.PRODUCT}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="screenshot" className="control-label">
                    {language.words.components.product.create.SCREENSHOT_CLIENT}
                  </label>
                  <input
                    id="screenshot"
                    onChange={(event) => uploadScreenshot(event)}
                    type="file"
                    name="screenshot"
                    className="form-control"
                    accept="image/*"
                  />
                </div>
              </>
            )}
            {form.type === "suscription" && form.telegram === "Crear grupo" ? (
              <div className="form-group">
                <label htmlFor="new-telegram" className="control-label">
                  {`${language.words.components.product.create.GROUP_NAME} *`}
                </label>
                <input
                  id="new-telegram"
                  onChange={update}
                  type="text"
                  name="newTelegram"
                  className="form-control"
                />
              </div>
            ) : (
              ""
            )}
            {creationType !== "promo" ? (
              <div className="form-group">
                <label htmlFor="price" className="control-label">
                  {`${language.words.components.product.create.PRICE} *`}
                </label>
                <input
                  id="price"
                  onChange={update}
                  type="text"
                  name="price"
                  className="form-control"
                  placeholder={
                    language.words.components.product.create.MAX_PRICE
                  }
                  pattern="^([0-9]{1,3})(\.|\,)?([0-9]{1,3})?$"
                />
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <label htmlFor="startDate" className="control-label">
                {language.words.components.product.create.START_DATE}
              </label>
              <input
                id="startDate"
                onChange={update}
                type="datetime-local"
                name="startDate"
                className="form-control"
                defaultValue={moment(Date.now()).format("YYYY-MM-DDTHH:mm")}
              />
            </div>
            <div className="form-group">
              <label htmlFor="expirationDate" className="control-label">
                {language.words.components.product.create.EXPIRATION_DATE}
              </label>
              <input
                id="expirationDate"
                onChange={update}
                type="datetime-local"
                name="expirationDate"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <input
                id="create__product--button"
                className="btn btn-primary create"
                type="button"
                onClick={send}
                defaultValue={
                  type === "tip"
                    ? language.words.components.product.create.CREATE_TIP
                    : type === "suscription"
                    ? language.words.components.product.create
                        .CREATE_SUBSCRIPTION
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
