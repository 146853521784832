import { useState } from "react";
import { useLanguage } from "../languages/useLanguage";
import "./ScrollButton.css";

const ScrollButton = () => {
  const { language } = useLanguage();
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    const scrollTrip = 300;
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > scrollTrip) {
      setShow(true);
    } else if (scrolled <= scrollTrip) {
      setShow(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleShow);

  return (
    <button
      className="btn btn-success btn-scroll"
      style={{ visibility: show ? "visible" : "hidden" }}
      onClick={() => scrollToTop()}
    >
      <h2>
        <span
          className="bi bi-caret-up-fill"
          title={language.words.components.general.scrollButton.GO_BACK_UP}
        ></span>
      </h2>
    </button>
  );
};

export default ScrollButton;
