import React from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../../../languages/useLanguage";
import "./CreationTypeSelection.css";

export default function CreationTypeSelection({ type, setCreationType }) {
  const { language } = useLanguage();
  const navigate = useNavigate();

  return (
    <div className="container main products type-selection">
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {type === "suscription"
              ? language.words.components.product.creationTypeSelection
                  .CREATE_SUBSCRIPTION
              : language.words.components.product.creationTypeSelection
                  .CREATE_TIP}
          </div>
          <div className="separator" />
          <p>
            {type === "suscription"
              ? language.words.components.product.creationTypeSelection
                  .SUBSCRIPTION_PAYMENT_TEXT
              : language.words.components.product.creationTypeSelection
                  .TIP_PAYMENT_TEXT}
          </p>
          <input
            className="btn btn-primary creation-button"
            type="button"
            defaultValue={
              language.words.components.product.creationTypeSelection
                .PAYMENT_BUTTON
            }
            onClick={() => setCreationType("payment")}
          />
          <div className="gray-bar" />
          <p>
            {type === "suscription"
              ? language.words.components.product.creationTypeSelection
                  .SUBSCRIPTION_TRACKER_TEXT
              : language.words.components.product.creationTypeSelection
                  .TIP_TRACKER_TEXT}
          </p>
          <input
            className="btn btn-primary creation-button"
            type="button"
            defaultValue={
              language.words.components.product.creationTypeSelection
                .TRACKER_BUTTON
            }
            onClick={() => setCreationType("promo")}
          />
        </div>
      </div>
      <div className="separator" />
      <span style={{ display: "block", color: "#3DBA86", textAlign: "center" }}>
        {`${language.words.components.product.creationTypeSelection.BOTH}: `}
      </span>
      <div className="separator" />
      <div className="gray-block">
        <div className="container-fluid">
          <p style={{ paddingTop: 15 }}>
            {`${language.words.components.product.creationTypeSelection.BOTH_TEXT}: `}
          </p>
          <input
            className="btn btn-primary creation-button"
            type="button"
            defaultValue={
              language.words.components.product.creationTypeSelection
                .BOTH_BUTTON
            }
            onClick={() => setCreationType("both")}
          />
        </div>
      </div>
    </div>
  );
}
