import { getApiUri } from "../Utils";

export const getTrackerRedirect = async (
  userBetsfyId,
  promoBetsfyId,
  referrer,
  source
) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = new URL(`${apiUri}/redirect/${userBetsfyId}/${promoBetsfyId}`);

  const params = {
    referrer,
    source,
  };

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      uri.searchParams.append(key, params[key]);
    }
  });

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const data = await response.json();

  return data.url;
};

export const getTrackerVerified = async (hash) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = `${apiUri}/redirect/age-verification/${hash}`;

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
    },
  });

  if (!response.status === 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const data = await response.json();

  return data.url;
};

export const getTrackersByUser = async (userBetsfyId) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = new URL(`${apiUri}/redirect/${userBetsfyId}`);

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
    },
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  const data = await response.json();

  return data;
};
