import { useEffect, useState } from "react";
import useInput from "../../../../../../components/hooks/useInput";
import InputImage from "../../../../../../components/InputImage";
import { useLanguage } from "../../../../../../languages/useLanguage";
import { getPublicUri } from "../../../../../../Utils";

const MatchOptions = ({ setAlert, match, setMatchs, index }) => {
  const { language } = useLanguage();

  const homeName = useInput("");
  const [homeBadge, setHomeBadge] = useState("");
  const awayName = useInput("");
  const [awayBadge, setAwayBadge] = useState("");

  useEffect(() => {
    setMatchs((prev) => {
      if (prev) {
        prev[index].home.name = homeName.value;
        prev[index].home.badge = homeBadge;
        prev[index].away.name = awayName.value;
        prev[index].away.badge = awayBadge;

        return [...prev];
      }
    });
  }, [homeName.value, homeBadge, awayName.value, awayBadge]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    homeName.setValue(match.home.name);
    setHomeBadge(match.home.badge);
    awayName.setValue(match.away.name);
    setAwayBadge(match.away.badge);
  }, [match]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <h4>
        <span
          className="bi bi-trash text-danger"
          role="button"
          title={
            language.words.components.admin.predictContest.create.REMOVE_MATCH
          }
          onClick={() => {
            setMatchs((prev) => {
              return prev?.filter((c, i) => i !== index);
            });
          }}
        ></span>
        <span className="text-light">
          {`${language.words.components.admin.predictContest.create.MATCH} ${
            index + 1
          }:`}
        </span>
      </h4>
      <div className="form-group">
        <label htmlFor="homeName" className="control-label">
          {`${
            language.words.components.admin.predictContest.create.NAME_HOME
          } ${index + 1} *`}
        </label>
        <input
          type="text"
          className="form-control"
          id="homeName"
          placeholder={`${
            language.words.components.admin.predictContest.create.NAME_HOME
          } ${index + 1}`}
          required
          {...homeName.inputAttributes}
        />
      </div>
      <div className="form-group">
        <label className="control-label" htmlFor="homeBadge">
          {`${
            language.words.components.admin.predictContest.create.BADGE_HOME
          } ${index + 1}`}
        </label>
        <div className="badge">
          <img
            src={
              homeBadge
                ? `${getPublicUri(window.location.hostname)}/${homeBadge}`
                : "https://dummyimage.com/240x80?text=" +
                  language.words.components.admin.predictContest.create
                    .NO_BADGE_IMAGE
            }
            alt={
              language.words.components.admin.predictContest.create.BADGE_HOME
            }
          />
          <InputImage
            {...{
              type: "team-badge",
              setImage: setHomeBadge,
              setAlert,
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="awayName" className="control-label">
          {`${
            language.words.components.admin.predictContest.create.NAME_AWAY
          } ${index + 1} *`}
        </label>
        <input
          type="text"
          className="form-control"
          id="awayName"
          placeholder={`${
            language.words.components.admin.predictContest.create.NAME_AWAY
          } ${index + 1}`}
          required
          {...awayName.inputAttributes}
        />
      </div>
      <div className="form-group">
        <label className="control-label" htmlFor="awayBadge">
          {`${
            language.words.components.admin.predictContest.create.BADGE_AWAY
          } ${index + 1}`}
        </label>
        <div className="badge">
          <img
            src={
              awayBadge
                ? `${getPublicUri(window.location.hostname)}/${awayBadge}`
                : "https://dummyimage.com/240x80?text=" +
                  language.words.components.admin.predictContest.create
                    .NO_BADGE_IMAGE
            }
            alt={
              language.words.components.admin.predictContest.create.BADGE_AWAY
            }
          />
          <InputImage
            {...{
              type: "team-badge",
              setImage: setAwayBadge,
              setAlert,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MatchOptions;
