import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../../../languages/useLanguage";
import { downloadLogs, getLogs } from "../../../../../services/Admin";
import "./Logs.css";

export default function AdminLogsPage() {
  const { language } = useLanguage();
  const [logs, setLogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getLogs().then(setLogs);
  }, []);

  return (
    <div className="container main products list">
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {language.words.components.admin.logs.LOGS}
          </div>
          <div className="separator" />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <input
                type="button"
                onClick={downloadLogs}
                className="btn btn-primary"
                defaultValue={
                  language.words.components.admin.logs.DOWNLOAD_LOGS
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="container-fluid">
              <div className="separator" />
              <div className="log-container">
                {logs.map((l, index) => (
                  <div key={"log-" + index}>
                    <span className="log">{l}</span>
                    <br />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
