import { useLanguage } from "../../../../../languages/useLanguage";
import { getPublicUri } from "../../../../../Utils";
import "./Card.css";

const CardComponent = ({ user, tracker, number, webLanding }) => {
  const { language } = useLanguage();

  return (
    <div
      className="card-tracker"
      style={{ borderColor: webLanding.header.background }}
    >
      <div
        className="card-title"
        style={{ backgroundColor: webLanding.header.background }}
      >
        {/* {language.words.components.redirect.trackerCard.OPTION} {number} */}
        <div className="country-image">
          <img
            src={
              getPublicUri(window.location.hostname) +
              "/countries/" +
              tracker.promo?.country?.replace(/ /g, "_") +
              "-light.png"
            }
            alt={tracker.promo?.country}
          />
        </div>
      </div>
      <div className="card-subtitle">
        {language.words.components.redirect.trackerCard.ADD}
      </div>
      <div className="card-content">
        <div className="card-img">
          <img
            src={`${getPublicUri(window.location.hostname)}/betting-sites/${
              tracker.promo.image
            }`}
            alt={tracker.promo.bettingSite}
          />
        </div>
        <br />
        <div className="card-conditions">
          {`${language.words.components.redirect.trackerCard.CONDITIONS.toUpperCase()}:`}
          <p>{tracker.promo.conditions.replace(/<br>/g, "\n")}</p>
        </div>
      </div>
      <div className="card-link">
        <a
          href={`${window.location.protocol}//${window.location.host}/${user.betsfyId}/${tracker.promo.betsfyId}?s=landing`}
          target="_blank"
          rel="noreferrer"
        >
          <span>
            {language.words.components.redirect.trackerCard.SIGN_UP_HERE}
          </span>
        </a>
      </div>
    </div>
  );
};

export default CardComponent;
