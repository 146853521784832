import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getPaymentMethod,
  updatePaymentMethod,
} from "../../../../../services/Admin";
import { sendLog } from "../../../../../services/App";
import useInput from "../../../../../components/hooks/useInput";
import config from "../../../../../Config";
import { useLanguage } from "../../../../../languages/useLanguage";

const PaymentMethodEditPage = ({ setLoader, setAlert }) => {
  const { language } = useLanguage();
  const [method, setMethod] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  const minValue = useInput("0");

  const send = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      await updatePaymentMethod(method.name, {
        minValue: minValue.value,
      });

      navigate(config.client.uris.adminPayment);
    } catch (error) {
      sendLog("error", error);
      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getPaymentMethod(params.methodName)
      .then(setMethod)
      .catch((error) => {
        setAlert(error?.message || error);
      });
  }, [params.methodId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (method._id) {
      minValue.setValue(method.minValue);
    }
  }, [method]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    method?._id && (
      <div className="container main">
        <div className="btn-navigate-back" onClick={() => navigate(-1)}>
          {`<< ${language.words.navigation.BACK}`}
        </div>
        <div className="gray-block">
          <div className="container-fluid">
            <div className="row">
              <div className="container-fluid">
                <div className="separator" />
                <div className="h1-header">
                  {language.words.components.admin.paymentEdit.EDIT_PAYMENT}{" "}
                  {method.name}
                </div>
                <div className="separator" />
              </div>
            </div>
            <div className="form">
              <form onSubmit={send}>
                <div className="form-group">
                  <label htmlFor="minValue" className="control-label">
                    {`${language.words.components.admin.paymentEdit.MIN_PAYMENT_AMOUNT} *`}
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      step={0.01}
                      min={0}
                      id="minValue"
                      placeholder={
                        language.words.components.admin.paymentEdit
                          .MIN_PAYMENT_AMOUNT
                      }
                      required
                      {...minValue.inputAttributes}
                    />
                    <span className="input-group-text">€</span>
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary create">
                    {language.words.components.admin.paymentEdit.EDIT}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PaymentMethodEditPage;
