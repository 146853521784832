import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../../../Config";
import { getUser } from "../../../../services/User";
import { getTrackers } from "../../../../services/Tracker";
import { sendLog } from "../../../../services/App";
import useTrackersFilter from "./hooks/useTrackersFilter";
import PromoInfoModal from "./components/PromoInfoModal";
import CountryFilterModal from "./components/CountryFilterModal";
import SiteFilterModal from "./components/SiteFilterModal";
import TypeFilterModal from "./components/TypeFilterModal";
import TrackersSortModal from "./components/TrackersSortModal";
import Card from "./components/Card";
import "./List.css";
import { copyToClipboard, getDomain } from "../../../../Utils";
import { useLanguage } from "../../../../languages/useLanguage";

export default function TrackerListPage({ setAlert }) {
  const [domain, setDomain] = useState(null);
  const { language } = useLanguage();
  const [shownTrackers, setShownTrackers] = useState([]);
  const [user, setUser] = useState(null);
  const [menu, setMenu] = useState("all");
  const [sortType, setSortType] = useState("");
  const navigate = useNavigate();

  const { filter, setFilter, filteredTrackers } = useTrackersFilter(
    shownTrackers || []
  );
  const [showCountryFilter, setShowCountryFilter] = useState(false);
  const [showSiteFilter, setShowSiteFilter] = useState(false);
  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  const [promoId, setPromoId] = useState("");
  const [showSort, setShowSort] = useState(false);

  useEffect(() => {
    const fetchTrackers = async () => {
      try {
        const user = await getUser();
        
        if (!user) throw new Error(language.words.errors.INVALID_USER);

        const _trackers = await getTrackers();

        if (menu === "top") {
          setShownTrackers(_trackers.filter((tracker) => tracker.promo?.isTop));
        } else if (menu === "all") {
          setShownTrackers(_trackers);
        }

        if (_trackers.length > 0) {
          setUser(_trackers[0].user);
        }
      } catch (error) {
        sendLog("error", error);

        window.location.replace(config.client.uris.login);
      }
    };

    fetchTrackers();
  }, [menu, showSort]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDomain(() => {
      return getDomain(window.location.host).domain;
    });
  }, [window.location.host]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container main trackers home list">
      <PromoInfoModal
        {...{
          show: showPromo,
          setShow: setShowPromo,
          promoId,
        }}
      />
      <CountryFilterModal
        {...{
          show: showCountryFilter,
          setShow: setShowCountryFilter,
          filter,
          setFilter,
        }}
      />
      <SiteFilterModal
        {...{
          show: showSiteFilter,
          setShow: setShowSiteFilter,
          filter,
          setFilter,
        }}
      />
      <TypeFilterModal
        {...{
          show: showTypeFilter,
          setShow: setShowTypeFilter,
          filter,
          setFilter,
        }}
      />
      <TrackersSortModal
        {...{
          show: showSort,
          setShow: setShowSort,
          _trackers: filteredTrackers,
          type: sortType,
          filter,
          setFilter,
        }}
      />
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="home-header">
        <div
          className={
            "h1-header home-header-title home-header-pill" +
            (menu === "top" ? " active" : " inactive")
          }
          onClick={() => setMenu("top")}
        >
          {language.words.components.tracker.list.TOP_PROMOS}
        </div>
        <div
          className={
            "h1-header home-header-title home-header-pill" +
            (menu === "all" ? " active" : " inactive")
          }
          onClick={() => setMenu("all")}
        >
          {language.words.components.tracker.list.PROMOS}
        </div>
      </div>
      <div
        className={
          "gray-block" + (menu === "top" ? " left-menu" : " right-menu")
        }
      >
        {user && user.betsfyId && user.betsfyId !== "" && (
          <div className="landing">
            <a
              className="landing-link"
              id="botLink"
              href={`https://${config.client.subdomains.b.value}.${domain}/${user.betsfyId}`}
              target="_blank"
              rel="noreferrer"
            >
              {`${language.words.components.tracker.list.YOUR_LANDING} `}
              <span className="bi bi-box-arrow-up-right"></span>
            </a>
            <div className="copy-landing">
              <span className="bi bi-clipboard-plus"></span>
              <input
                className="copy-landing-input"
                type="button"
                onClick={(event) =>
                  copyToClipboard(
                    event,
                    `https://${config.client.subdomains.b.value}.${domain}/${user.betsfyId}`
                  )
                }
                defaultValue={
                  language.words.components.tracker.list.COPY_LANDING
                }
              />
            </div>
          </div>
        )}
        <div className="separator" />
        <div className="trackers-filters">
          <div className="row">
            <div className="col-4">
              <div
                className={
                  "filter" +
                  (filter.country.length === 0 ? " clear" : " active")
                }
                onClick={() => setShowCountryFilter(true)}
              >
                {`${language.words.components.tracker.list.COUNTRY}: `}
                <span
                  className={
                    "bi bi-caret-" + (showCountryFilter ? "up" : "down")
                  }
                ></span>
              </div>
            </div>
            <div className="col-4">
              <div
                className={
                  "filter" + (filter.site.length === 0 ? " clear" : " active")
                }
                onClick={() => setShowSiteFilter(true)}
              >
                {`${language.words.components.tracker.list.BETTING_SITE}: `}
                <span
                  className={"bi bi-caret-" + (showSiteFilter ? "up" : "down")}
                ></span>
              </div>
            </div>
            <div className="col-4">
              <div
                className={
                  "filter" + (filter.type.length === 0 ? " clear" : " active")
                }
                onClick={() => setShowTypeFilter(true)}
              >
                {`${language.words.components.tracker.list.TYPE}: `}
                <span
                  className={"bi bi-caret-" + (showTypeFilter ? "up" : "down")}
                ></span>
              </div>
            </div>
          </div>
        </div>
        {filteredTrackers.map((tracker) => (
          <Card
            key={"tracker-" + tracker._id}
            {...{
              trackerInit: tracker,
              setPromoId,
              setShowPromo,
              setShowSort,
              setSortType,
              setAlert,
            }}
          />
        ))}
      </div>
    </div>
  );
}
