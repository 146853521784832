import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../../../../Config";
import { useLanguage } from "../../../../../languages/useLanguage";
import { getPublicUri } from "../../../../../Utils";
import "./TrackerSelection.css";

const Card = ({ selectedTrackers, setTracker, tracker }) => {
  const { language } = useLanguage();

  const selected = selectedTrackers.find((t) => t === tracker._id)
    ? "selected"
    : "";

  return (
    <div className="container-fluid tracker-selector-wrapper">
      <div style={{ position: "relative" }}>
        <a
          href={config.client.uris.promos + "/" + tracker.promo._id}
          target="_blank"
          rel="noreferrer"
        >
          <span className="info-icon">i</span>
        </a>
      </div>
      <div
        className={`tracker-selector ${selected}`}
        onClick={() => setTracker(tracker._id)}
      >
        <div className="row">
          <div className="col-6">
            <div className="promo-betting-image fixed-height">
              <img
                src={
                  getPublicUri(window.location.hostname) +
                  "/betting-sites/" +
                  tracker.promo.image
                }
                alt={tracker.promo.bettingSite}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="promo-country-image fixed-height align-right">
              <img
                src={
                  getPublicUri(window.location.hostname) +
                  "/countries/" +
                  tracker.promo.country.replace(/ /g, "_") +
                  ".png"
                }
                alt={tracker.promo.country}
              />
            </div>
          </div>
        </div>
        <div className="gray-bar" />
        <div className="row">
          <div className="container-fluid">
            <div className="conditions">
              <h4>{`${language.words.components.product.trackerSelection.CONDITIONS}:`}</h4>
              <p>{tracker.promo.conditions.replace(/<br>/g, "\n")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function TrackerSelection({
  trackers,
  previousTrackers,
  updateTrackers,
}) {
  const { language } = useLanguage();
  const [selectedTrackers, setSelectedTrackers] = useState([]);

  const setTracker = (id) => {
    const newState = selectedTrackers.filter((t) => t !== id);

    if (!selectedTrackers.find((t) => t === id)) {
      newState.push(id);
    }

    return setSelectedTrackers(newState);
  };

  // select previous trackers
  useEffect(() => {
    setSelectedTrackers((prev) => previousTrackers ?? []);
  }, [previousTrackers]);

  return (
    <div className="container main tracker-selection-list">
      <Link className="btn-navigate-back" to={config.client.uris.products}>
        {`<< ${language.words.navigation.BACK}`}
      </Link>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <div className="separator" />
              <div className="h1-header">
                {
                  language.words.components.product.trackerSelection
                    .TRACKERS_SELECTION
                }
              </div>
              <div className="separator" />
            </div>
          </div>
        </div>
        {Object.keys(trackers).map((country) => {
          return trackers[country].map((tracker) => (
            <Card
              key={`tracker-${tracker._id}`}
              tracker={tracker}
              setTracker={setTracker}
              selectedTrackers={selectedTrackers}
            />
          ));
        })}
        <input
          type="button"
          className="btn btn-primary sticky-button"
          onClick={() => updateTrackers(selectedTrackers)}
          defaultValue={
            language.words.components.product.trackerSelection.SAVE_CHANGES
          }
        />
      </div>
    </div>
  );
}
