import { useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../../../../Config";
import { useLanguage } from "../../../../../../languages/useLanguage";
import { updatePaymentMethod } from "../../../../../../services/Admin";
import { sendLog } from "../../../../../../services/App";
import { getUser } from "../../../../../../services/User";

const CardComponent = ({ methodInit, setAlert }) => {
  const { language } = useLanguage();
  const [method, setMethod] = useState(methodInit);
  const [isLoading, setIsLoading] = useState(false);

  const updateStatus = async (methodName, status) => {
    try {
      setIsLoading(true);

      const user = await getUser();

      if (!user) throw new Error(language.words.errors.INVALID_USER);

      const response = await updatePaymentMethod(methodName, { status });

      setMethod(response);
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="list-card">
      <div className="row">
        <div className="container-fluid">
          <div className="h1-header">{method.name}</div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="container-fluid">
          <div>
            {`${language.words.components.admin.paymentCard.MIN_PAYMENT}: `}
            <b>
              {new Intl.NumberFormat("es-ES", {
                style: "currency",
                currency: "EUR",
              }).format(method.minValue)}
            </b>
          </div>
        </div>
      </div>
      <div className="separator" />
      <div className="status-wrapper">
        <div
          className={
            "status-info" +
            (method.status === "enabled" ? " success" : " danger")
          }
        >
          {method.status === "enabled" ? (
            <>
              {language.words.components.admin.paymentCard.ENABLED}
              <span className="bi bi-check-circle"></span>
            </>
          ) : method.status === "disabled" ? (
            <>
              {language.words.components.admin.paymentCard.DISABLED}
              <span className="bi bi-x-circle"></span>
            </>
          ) : (
            <>
              {language.words.components.admin.paymentCard.UNKNOWN}
              <span className="bi bi-question-circle"></span>
            </>
          )}
        </div>
        <div className="status-wrapper">
          {method.status === "enabled" ? (
            <button
              className="action-button"
              disabled={isLoading}
              onClick={() => {
                updateStatus(method.name, "disabled");
              }}
            >
              <span className="bi bi-pause-circle"></span>
              {language.words.components.admin.paymentCard.DISABLE}
            </button>
          ) : method.status === "disabled" ? (
            <button
              className="action-button"
              disabled={isLoading}
              onClick={() => {
                updateStatus(method.name, "enabled");
              }}
            >
              <span className="bi bi-play-circle"></span>
              {language.words.components.admin.paymentCard.ENABLE}
            </button>
          ) : (
            ""
          )}
          <Link
            className="btn btn-success"
            to={config.client.uris.adminPayment + "/" + method.name}
          >
            <span className="bi bi-pencil"></span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
