import { useEffect } from "react";
import { useState } from "react";

const useTrackersFilter = (trackers) => {
  const [filteredTrackers, setFilteredTrackers] = useState(trackers);
  const [filter, setFilter] = useState({
    countries: [],
    country: [],
    sites: [],
    site: [],
    types: [],
    type: [],
  });

  useEffect(() => {
    setFilteredTrackers(
      trackers.filter((tracker) => {
        const countryFilter =
          filter.country.length > 0 ? filter.country : filter.countries;
        const siteFilter = filter.site.length > 0 ? filter.site : filter.sites;
        const typeFilter = filter.type.length > 0 ? filter.type : filter.types;
        
        return (
          countryFilter.some((country) => country === tracker.promo?.country) &&
          siteFilter.some(
            (site) => site.bettingSite === tracker.promo?.bettingSite
          ) &&
          typeFilter.some((type) => type === tracker.promo?.type)
        );
      })
    );
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const countries = [];
    const sites = [];
    const types = [];

    trackers.forEach((tracker) => {
      if (!countries.some((country) => country === tracker.promo?.country)) {
        countries.push(tracker.promo?.country);
      }
      if (
        !sites.some((site) => site.bettingSite === tracker.promo?.bettingSite)
      ) {
        sites.push({
          bettingSite: tracker.promo?.bettingSite,
          image: tracker.promo?.image,
        });
      }
      if (!types.some((type) => type === tracker.promo?.type)) {
        types.push(tracker.promo?.type);
      }
    });

    setFilter((prev) => {
      return {
        countries: countries,
        country: prev.country,
        sites: sites,
        site: prev.site,
        types: types,
        type: prev.type,
      };
    });
  }, [trackers]);

  return { filter, setFilter, filteredTrackers };
};

export default useTrackersFilter;
