import moment from "moment";
import { useLanguage } from "../../../../../languages/useLanguage";
import "./DatePicker.css";

export default function DatePicker({ from, to, setDates }) {
  const { language } = useLanguage();
  const fromDate = moment(from).format("YYYY-MM-DD HH:mm:ss");
  const toDate = moment(to).format("YYYY-MM-DD HH:mm:ss");

  return (
    <div className="container-flex row">
      <div className="col-6">
        <label className="date-element date-element--label" htmlFor="from">
          {language.words.components.billing.datePicker.FROM}
        </label>
        <input
          className="date-element text-center"
          id="from"
          type="datetime-local"
          name="from"
          onChange={setDates}
          defaultValue={fromDate}
        />
      </div>
      <div className="col-6">
        <label className="date-element date-element--label" htmlFor="to">
          {language.words.components.billing.datePicker.TO}
        </label>
        <input
          className="date-element text-center"
          id="to"
          type="datetime-local"
          name="to"
          onChange={setDates}
          defaultValue={toDate}
        />
      </div>
      <div className="separator" />
    </div>
  );
}
