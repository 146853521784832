import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../components/Loader";
import MissingPage from "../../components/Missing";
import AgeVerification from "../b/components/redirect/AgeVerification";
import Trackers from "./components/trackers/Trackers";
import Tracker from "./components/redirect/Tracker";
import Layout from "./components/general/layout/Layout";
import NotificationBarB from "../../components/NotificationBarB";

const App = () => {
  const [isLoading, setLoader] = useState(false);
  const [notifications, notify] = useState({ visible: false, text: "" });

  const setAlert = (text) => notify({ visible: true, text });
  const unsetAlert = () => notify({ visible: false, text: "" });

  return (
    <>
      <Routes>
        <Route
          path="/:userBetsfyId"
          element={
            <Layout
              header={false}
              footer={true}
              children={<Trackers setLoader={setLoader} setAlert={setAlert} />}
            />
          }
        />
        <Route path="/:userBetsfyId/:promoBetsfyId" element={<Tracker />} />
        <Route
          path="/age-verification/:hash"
          element={
            <Layout
              header={false}
              footer={true}
              children={<AgeVerification />}
            />
          }
        />

        {/* catch all */}
        <Route path="*" element={<MissingPage />} />
      </Routes>
      <Loader isLoading={isLoading} />
      <NotificationBarB {...notifications} unsetAlert={unsetAlert} />
    </>
  );
};

export default App;
