import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../../../Config";
import { getUser } from "../../../../services/User";
import { getProducts } from "../../../../services/Product";
import { sendLog } from "../../../../services/App";
import { getTrackers } from "../../../../services/Tracker";
import { getApprovals } from "../../../../services/Approval";
import "./Home.css";
import { useLanguage } from "../../../../languages/useLanguage";

export default function ProductHomePage({ setLoader, setAlert }) {
  const { language } = useLanguage();
  const [counter, setCounter] = useState({});

  const fetchCounter = async () => {
    try {
      setLoader(true);

      const user = await getUser();

      if (!user) throw new Error(language.words.errors.INVALID_USER);

      const [suscriptions, tips, approvals, trackers] = await Promise.all([
        getProducts("suscription"),
        getProducts("tip"),
        getApprovals("in_progress"),
        getTrackers(),
      ]);

      setCounter({
        suscriptions: suscriptions.length,
        tips: tips.length,
        approvals: approvals.length,
        trackers: trackers.length,
      });

      setLoader(false);
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message || error);
    }
  };

  useEffect(() => {
    fetchCounter();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container main products list home">
      <div className="gray-block">
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <div className="separator" />
              <div className="h1-header">
                {language.words.components.product.home.MY_PRODUCTS}
              </div>
              <div className="separator" />
            </div>
          </div>
          <ul>
            <li className="nav-item">
              <Link className="nav-link" to={config.client.uris.suscriptions}>
                <span className="nav-tag">{counter.suscriptions}</span>
                {language.words.components.product.home.ACTIVE_SUBSCRIPTIONS}
              </Link>
              <Link
                className="create-product"
                to={config.client.uris.products + "/create/suscription"}
              >
                <div className="btn btn-primary">
                  <p>
                    {language.words.components.product.home.CREATE_SUBSCRIPTION}
                  </p>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={config.client.uris.tips}>
                <span className="nav-tag">{counter.tips}</span>
                {language.words.components.product.home.ACTIVE_TIPS}
              </Link>
              <Link
                className="create-product"
                to={config.client.uris.products + "/create/tip"}
              >
                <div className="btn btn-primary">
                  <p>{language.words.components.product.home.CREATE_TIP}</p>
                </div>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={config.client.uris.approvals}>
                <span className="nav-tag">{counter.approvals}</span>
                {language.words.components.product.home.REQUESTS}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={config.client.uris.trackers}>
                <span className="nav-tag">{counter.trackers}</span>
                {language.words.components.product.home.ACTIVE_TRACKERS}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
