import React, { useEffect, useState } from "react";
import ShopLayout from "../components/Layout";
import ShopErrorLayout from "../components/ErrorLayout";
import { useParams } from "react-router-dom";
import { fetchOrder } from "../../../../../services/Order";
import { getDiscount } from "../../../../../services/Coupon";
import { getProductToBuy } from "../../../../../services/Product";
import { getPaymentMethod } from "../../../../../services/Admin";
import "../Landing.css";
import "./Shop.css";
import { useLanguage } from "../../../../../languages/useLanguage";

export default function AstropayShopPage({ setLoader, setAlert }) {
  const { language } = useLanguage();
  const [discount, setDiscount] = useState(0);
  const [payload, setPayload] = useState({});
  const [product, setProduct] = useState({});
  const [paymentMethod, setPaymentMethod] = useState({});
  const params = useParams({});

  const setCoupon = async () => {
    const node = document.querySelector("#couponCode");

    try {
      setLoader(true);
      const discount = await getDiscount(product.user, node.value, product._id);

      setDiscount(discount);
    } catch (error) {
      node.value = "";

      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  const send = async () => {
    try {
      setLoader(true);

      const order = await fetchOrder("astropay", {
        ...payload,
        discountId: discount?._id,
      });

      window.location.replace(order.url);
    } catch (error) {
      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    try {
      const decoded = JSON.parse(atob(decodeURIComponent(params.hash)));

      const type = decoded.type;
      const productId = decoded.productId;
      const userId = decoded.userId;

      setPayload({
        type: type,
        productId: productId,
        userId: userId,
      });

      const fetchProduct = async () => {
        try {
          setLoader(true);

          const _product = await getProductToBuy(productId);

          setProduct(_product);
        } catch (error) {
          console.error(error);
          setAlert(error?.message || error);
        } finally {
          setLoader(false);
        }
      };

      if (productId) {
        fetchProduct();
      }
    } catch (error) {
      console.error(error);
    }
  }, [params.hash]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      try {
        const paymentMethod = await getPaymentMethod("astropay");

        setPaymentMethod(paymentMethod);
      } catch (error) {
        console.error(error);
        setAlert(error?.message || error);
      }
    };

    fetchPaymentMethod();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return !product?._id ? (
    <ShopErrorLayout
      error={{
        message: language.words.components.shop.landing.PRODUCT_NOT_FOUND,
      }}
    />
  ) : (
    <ShopLayout
      product={product}
      discount={discount}
      setCoupon={setCoupon}
      send={send}
      buttonValue={language.words.components.shop.landing.PAY_WITH_ASTROPAY}
      minPrice={paymentMethod.minValue}
    />
  );
}
