import { useEffect } from "react";
import { useState } from "react";
import { useLanguage } from "../../../../../languages/useLanguage";
import { getPublicUri } from "../../../../../Utils";
import "./FilterModal.css";

const SiteFilterModal = ({ show, setShow, filter, setFilter }) => {
  const { language } = useLanguage();
  const [prefilter, setPrefilter] = useState([]);

  const toggleShow = () => {
    setShow((prev) => !prev);
  };

  const updatePrefilter = (_site) => {
    setPrefilter((prev) => {
      const exists = prev.some((s) => s.bettingSite === _site.bettingSite);

      if (exists) {
        return prev.filter(
          (__site) => __site.bettingSite !== _site.bettingSite
        );
      } else {
        return [...prev, _site];
      }
    });
  };

  const clearAll = () => {
    setFilter((prev) => {
      return {
        ...prev,
        site: [],
      };
    });
    setShow(false);
  };

  const checkAll = () => {
    setPrefilter(filter.sites);
  };

  const uncheckAll = () => {
    setPrefilter([]);
  };

  const applyFilter = () => {
    setFilter((prev) => {
      return {
        ...prev,
        site: prefilter,
      };
    });
    setShow(false);
  };

  useEffect(() => {
    setPrefilter(filter.site);
  }, [show, filter.site]);

  return (
    <div
      className={"modal fade" + (show && " show")}
      style={{
        display: "block",
        visibility: show ? "visible" : "hidden",
        maxHeight: "100vh",
        maxWidth: "100vw",
      }}
      onClick={toggleShow}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div
          className="filter-modal  modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-header">
            <div className="filter-title">
              <span
                className="bi bi-x-circle modal-close"
                onClick={toggleShow}
              ></span>{" "}
              {language.words.components.tracker.filter.BETTING_SITE_FILTER}
            </div>
            <span className="filter-clear" onClick={() => clearAll()}>
              {language.words.components.tracker.filter.CLEAR_ALL}
            </span>
          </div>
          <div
            className="modal-body"
            style={{ maxHeight: "65vh", overflowY: "auto" }}
          >
            <div className="filter-general">
              <div
                onClick={() => {
                  checkAll();
                }}
              >
                {language.words.components.tracker.filter.SELECT_ALL}
              </div>
              <div
                onClick={() => {
                  uncheckAll();
                }}
              >
                {language.words.components.tracker.filter.DESELECT_ALL}
              </div>
            </div>
            <div className="filter-content">
              {filter.sites?.map((_site) => {
                return (
                  <div
                    key={"bettingSite-" + _site.bettingSite}
                    className={
                      "filter-choice" +
                      (prefilter?.some(
                        (__site) => __site.bettingSite === _site.bettingSite
                      )
                        ? " active"
                        : " inactive")
                    }
                  >
                    <img
                      src={
                        getPublicUri(window.location.hostname) +
                        "/betting-sites/" +
                        _site.image
                      }
                      alt={_site.bettingSite}
                      onClick={() => {
                        updatePrefilter(_site);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => applyFilter()}
            >
              {language.words.components.tracker.filter.APPLY}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteFilterModal;
