import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useInput from "../../../../../components/hooks/useInput";
import useSelect from "../../../../../components/hooks/useSelect";
import InputImage from "../../../../../components/InputImage";
import config from "../../../../../Config";
import { useLanguage } from "../../../../../languages/useLanguage";
import { getWebLanding, updateWebLanding } from "../../../../../services/Admin";
import { sendLog } from "../../../../../services/App";
import { getUser } from "../../../../../services/User";
import { getPublicUri } from "../../../../../Utils";
import "./Edit.css";

const WebLandingEditPage = ({ setAlert, setLoader }) => {
  const { language } = useLanguage();
  const [webLanding, setWebLanding] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  const name = useInput("default");
  const [image, setImage] = useState(null);
  const headerBackground = useInput("#ffffff");
  const contentBackground = useInput("#ffffff");
  const cardType = useSelect("dark");

  const setAllValues = (data) => {
    name.setValue(data.name);
    setImage(data.header.image);
    headerBackground.setValue(data.header.background);
    contentBackground.setValue(data.content.background);
    cardType.setValue(data.content.card);
  };

  const send = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);

      const payload = {
        name: name.value,
        header: {
          image: image,
          background: headerBackground.value,
        },
        content: {
          background: contentBackground.value,
          card: cardType.value,
        },
      };

      await updateWebLanding(webLanding._id, payload);

      navigate(config.client.uris.adminWebLanding);
    } catch (error) {
      sendLog("error", error);
      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const fetchWebLanding = async (id) => {
      try {
        setLoader(true);
        const user = await getUser();

        if (!user) throw new Error(language.words.errors.INVALID_USER);

        const response = await getWebLanding(id);

        setWebLanding(response);
      } catch (error) {
        setAlert(error?.message || error);
      } finally {
        setLoader(false);
      }
    };

    if (params.id) {
      fetchWebLanding(params.id);
    }
  }, [params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (webLanding?._id) {
      setAllValues(webLanding);
    }
  }, [webLanding]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    webLanding?._id && (
      <div className="container main">
        <div className="btn-navigate-back" onClick={() => navigate(-1)}>
          {`<< ${language.words.navigation.BACK}`}
        </div>
        <div className="gray-block">
          <div className="container-fluid">
            <div className="row">
              <div className="container-fluid">
                <div className="separator" />
                <div className="h1-header">
                  {
                    language.words.components.admin.webLandingEdit
                      .EDIT_WEB_LANDING
                  }{" "}
                  {webLanding.name}
                </div>
                <div className="separator" />
              </div>
            </div>
            <div className="form">
              <form onSubmit={send}>
                <div className="form-group">
                  <label htmlFor="name" className="control-label">
                    {`${language.words.components.admin.webLandingEdit.NAME} *`}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder={
                      language.words.components.admin.webLandingEdit.NAME
                    }
                    required
                    {...name.inputAttributes}
                  />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="image">
                    {
                      language.words.components.admin.webLandingEdit
                        .HEADER_IMAGE
                    }
                  </label>
                  <img
                    id="preview-upload-file__image"
                    src={
                      image
                        ? `${getPublicUri(window.location.hostname)}/${image}`
                        : "https://dummyimage.com/240x80?text=" +
                          language.words.components.admin.webLandingEdit
                            .NO_HEADER_IMAGE
                    }
                    alt={
                      language.words.components.admin.webLandingEdit
                        .HEADER_IMAGE
                    }
                    style={{ backgroundColor: headerBackground.value }}
                  />
                  <InputImage
                    {...{
                      type: "web-landing",
                      setImage,
                      setAlert,
                    }}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="headerBackground" className="form-label">
                    {
                      language.words.components.admin.webLandingEdit
                        .HEADER_BACKGROUND
                    }
                  </label>
                  <input
                    type="color"
                    className="form-control form-control-color"
                    id="headerBackground"
                    title="Elige el color del borde"
                    {...headerBackground.inputAttributes}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contentBackground" className="form-label">
                    {
                      language.words.components.admin.webLandingEdit
                        .CONTENT_BACKGROUND
                    }
                  </label>
                  <input
                    type="color"
                    className="form-control form-control-color"
                    id="contentBackground"
                    title="Elige el color del borde"
                    {...contentBackground.inputAttributes}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="cardType">
                    {language.words.components.admin.webLandingEdit.CARD_TYPE}
                  </label>
                  <select
                    id="cardType"
                    className="form-control form-select"
                    {...cardType.selectAttributes}
                  >
                    <option value="dark">Dark</option>
                    <option value="light">Light</option>
                  </select>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary">
                    {language.words.components.admin.webLandingEdit.EDIT}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default WebLandingEditPage;
