import moment from "moment";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Config from "../../../../../../Config";
import config from "../../../../../../Config";
import { useLanguage } from "../../../../../../languages/useLanguage";
import { updatePredictContest } from "../../../../../../services/Admin";
import { getPublicUri } from "../../../../../../Utils";
import "./Card.css";

const CardComponent = ({ setLoader, setAlert, _contest }) => {
  const { language } = useLanguage();
  const [contest, setContest] = useState(_contest);
  const navigate = useNavigate();

  const updateStatus = async (contestId, status) => {
    try {
      setLoader(false);

      const response = await updatePredictContest(contestId, { status });

      setContest(response);
    } catch (error) {
      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="list-card predict-contest-card">
      <div className="row">
        <div className="container-fluid">
          <div className="h1-header">
            {moment(contest.expirationDate).format("DD-MM-YYYY HH:mm:ss")}
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="container-fluid">
          <div className="header">
            <img
              src={
                contest.header.image
                  ? `${getPublicUri(window.location.hostname)}/${
                      contest.header.image
                    }`
                  : "https://dummyimage.com/240x80?text=" +
                    language.words.components.admin.predictContest.card
                      .NO_HEADER_IMAGE
              }
              alt={language.words.components.admin.predictContest.card.HEADER}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container-fluid">
          {contest.matchs?.map((match, index) => {
            return (
              <div key={"match-" + index} className="badge">
                {match.home.badge ? (
                  <img
                    src={`${getPublicUri(window.location.hostname)}/${
                      match.home.badge
                    }`}
                    alt={
                      language.words.components.admin.predictContest.card.BADGE
                    }
                  />
                ) : (
                  match.home.name
                )}{" "}
                vs{" "}
                {match.away.badge ? (
                  <img
                    src={`${getPublicUri(window.location.hostname)}/${
                      match.away.badge
                    }`}
                    alt={
                      language.words.components.admin.predictContest.card.BADGE
                    }
                  />
                ) : (
                  match.away.name
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="row">
        <div className="container-fluid">
          <div className="footer">
            <img
              src={
                contest.footer.image
                  ? `${getPublicUri(window.location.hostname)}/${
                      contest.footer.image
                    }`
                  : "https://dummyimage.com/240x80?text=" +
                    language.words.components.admin.predictContest.card
                      .NO_FOOTER_IMAGE
              }
              alt={language.words.components.admin.predictContest.card.FOOTER}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container-fluid">
          {`${language.words.components.admin.predictContest.card.STARTS_ON}: `}
          <b>{moment(contest.startDate).format("DD-MM-YYYY HH:mm:ss")}</b>
        </div>
      </div>
      <div className="row">
        <div className="container-fluid">
          {`${language.words.components.admin.predictContest.card.EXPIRES_ON}: `}
          <b>{moment(contest.expirationDate).format("DD-MM-YYYY HH:mm:ss")}</b>
        </div>
      </div>
      <div className="row">
        <div className="container-fluid">
          {`${language.words.components.admin.predictContest.card.USERS}: `}
          <b>{contest.predictions.length}</b>
          <div className="status-wrapper">
            <span
              className="action-button"
              onClick={() => {
                navigate(
                  Config.client.uris.adminPredictContest +
                    "/" +
                    contest._id +
                    "/predictions"
                );
              }}
            >
              <span className="bi bi-eye"></span>
              {
                language.words.components.admin.predictContest.card
                  .PREDICTIONS_LIST
              }
            </span>
          </div>
        </div>
      </div>
      <div className="separator" />
      <div className="status-wrapper">
        <div
          className={
            "status-info" +
            (contest.status === "unreleased"
              ? " warning"
              : contest.status === "enabled"
              ? " success"
              : contest.status === "disabled"
              ? " warning"
              : contest.status === "expired"
              ? " danger"
              : " danger")
          }
        >
          {contest.status === "unreleased" ? (
            <>
              {language.words.components.admin.predictContest.card.UNRELEASED}
              <span className="bi bi-stopwatch"></span>
            </>
          ) : contest.status === "enabled" ? (
            <>
              {language.words.components.admin.predictContest.card.ENABLED}
              <span className="bi bi-check-circle"></span>
            </>
          ) : contest.status === "disabled" ? (
            <>
              {language.words.components.admin.predictContest.card.DISABLED}
              <span className="bi bi-stopwatch"></span>
            </>
          ) : contest.status === "expired" ? (
            <>
              {language.words.components.admin.predictContest.card.EXPIRED}
              <span className="bi bi-calendar-check"></span>
            </>
          ) : (
            <>
              {language.words.components.admin.predictContest.card.UNKNOWN}
              <span className="bi bi-question-circle"></span>
            </>
          )}
        </div>
        <div className="status-wrapper">
          {contest.status === "enabled" ? (
            <div
              className="action-button"
              onClick={() => {
                updateStatus(contest._id, "disabled");
              }}
            >
              <span className="bi bi-pause-circle"></span>
              {language.words.components.admin.predictContest.card.DISABLE}
            </div>
          ) : contest.status === "disabled" ||
            contest.status === "unreleased" ? (
            <div
              className="action-button"
              onClick={() => {
                updateStatus(contest._id, "enabled");
              }}
            >
              <span className="bi bi-play-circle"></span>
              {contest.status === "unpublished"
                ? language.words.components.admin.predictContest.card.START
                : language.words.components.admin.predictContest.card.ENABLE}
            </div>
          ) : (
            ""
          )}
          <Link
            className="btn btn-success"
            to={config.client.uris.adminPredictContest + "/" + contest._id}
          >
            <span className="bi bi-pencil"></span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
