import { sendLog } from "./App";
import { getApiUri } from "../Utils";

export const getTracker = async (trackerId) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/trackers/" + trackerId;

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) throw new Error("Error getting tracker");

  const tracker = await response.json();

  return tracker.tracker || tracker;
};

export const getTrackers = async () => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/trackers";

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) throw new Error("Error getting trackers");

  const trackers = await response.json();

  return trackers?.trackers || trackers;
};

export const sortTrackers = async (orderIds, type) => {
  if (type !== "web" && type !== "telegram")
    throw new Error("Tipos permitidos: web y telegram");

  const payload = { orderIds, type };

  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/trackers/sort", {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const updateTelegramTracker = async (trackerId, show) => {
  const payload = { show };

  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(
    apiUri + "/trackers/" + trackerId + "/telegram",
    {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(payload),
    }
  );

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const updateWebTracker = async (trackerId, show) => {
  const payload = { show };

  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/trackers/" + trackerId + "/web", {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }

  return await response.json();
};

export const deleteTracker = async (trackerId) => {
  try {
    const apiUri = getApiUri(window.location.hostname);

    const response = await fetch(
      apiUri + "/trackers/" + trackerId + "/delete/",
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status !== 200) throw new Error("Error deleting tracker");

    const tracker = await response.json();

    return tracker;
  } catch (error) {
    sendLog("error", error);

    return null;
  }
};

export const updateTracker = async (trackerId, body) => {
  const apiUri = getApiUri(window.location.hostname);

  const response = await fetch(apiUri + "/trackers/" + trackerId, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(body),
  });

  if (response.status !== 200) {
    const body = await response.json();

    throw new Error(body);
  }
};
