import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import MissingPage from "../../../../components/Missing";
import { getActivePredictContests } from "../../../../services/Admin";
import { getTrackersByUser } from "../../../../services/Redirect";
import { getPublicUri } from "../../../../Utils";
import Card from "./components/Card";
import "./Trackers.css";
import PredictContest from "./components/PredictContest";
import LegalModal from "./components/LegalModal";

const Trackers = ({ setLoader, setAlert }) => {
  const [user, setUser] = useState({});
  const [trackers, setTrackers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userBetsfyId } = useParams();
  const [contests, setContests] = useState([]);
  const [legal, setLegal] = useState("");
  const [showLegalModal, setShowLegalModal] = useState(false);

  // get trackers
  useEffect(() => {
    const getTrackers = async (user) => {
      try {
        setLoader(true);
        setIsLoading(true);

        const response = await getTrackersByUser(user);

        setUser(response.user);
        setTrackers(
          (prev) => response.trackers
          // .filter(
          //   (tracker) => tracker.web?.show || tracker.web?.show === undefined
          // )
          // .sort((a, b) => {
          //   if (
          //     a.web?.order !== null &&
          //     a.web?.order !== undefined &&
          //     b.web?.order !== null &&
          //     b.web?.order !== undefined
          //   ) {
          //     return a.web.order - b.web.order;
          //   } else if (b.web?.order !== null && b.web?.order !== undefined) {
          //     return 1;
          //   } else {
          //     return b.promo.isTop - a.promo.isTop;
          //   }
          // })
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
        setIsLoading(false);
      }
    };

    if (userBetsfyId) {
      getTrackers(userBetsfyId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // get active contests
  useEffect(() => {
    const fetcContests = async () => {
      try {
        const response = await getActivePredictContests();

        setContests(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetcContests();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return user?._id ? (
    <div
      className="web-landing"
      style={{ backgroundColor: user.webLanding.content.background }}
    >
      <LegalModal
        show={showLegalModal}
        setShow={setShowLegalModal}
        legal={legal}
      />
      {user.webLanding.header.image ? (
        <div
          className="landing-header"
          style={{ backgroundColor: user.webLanding.header.background }}
        >
          <div className="header-image">
            <img
              src={
                getPublicUri(window.location.hostname) +
                "/" +
                user.webLanding.header.image
              }
              alt="header"
            />
          </div>
        </div>
      ) : (
        <div
          className="landing-header-mini"
          style={{ backgroundColor: user.webLanding.header.background }}
        ></div>
      )}
      <div className="landing-content">
        <div className="landing-user">
          <div className="profile-pic">
            {user?.profilePic && (
              <div
                className="profile-pic-wrapper"
                style={{
                  borderColor: user.webLanding.content.background,
                  backgroundColor: user.webLanding.content.background,
                  zIndex: 5000,
                }}
              >
                <img
                  src={`${getPublicUri(window.location.hostname)}/${
                    user.profilePic
                  }`}
                  alt="Profile pic"
                />
              </div>
            )}
          </div>
          <div
            className="username"
            style={{ color: user.webLanding.header.background }}
          >
            {user.nick && user.nick !== ""
              ? user.nick.toUpperCase()
              : user.firstname.toUpperCase()}
          </div>
          <div className="biography">{user?.biography && user?.biography}</div>
        </div>

        <div className="trackers">
          <div className="trackers-wrapper">
            {trackers?.length > 0 &&
              trackers?.map((tracker, index) => {
                return (
                  <div key={tracker._id} className="tracker-card">
                    <Card
                      user={user}
                      tracker={tracker}
                      number={index + 1}
                      webLanding={user.webLanding}
                    />
                  </div>
                );
              })}
          </div>
        </div>

        {contests.length > 0 &&
          contests.map((contest) => {
            return (
              <div key={contest._id}>
                <PredictContest
                  setLoader={setLoader}
                  setAlert={setAlert}
                  contest={contest}
                  setShowLegal={setShowLegalModal}
                  setLegal={setLegal}
                />
              </div>
            );
          })}
      </div>
    </div>
  ) : isLoading ? (
    ""
  ) : (
    <MissingPage />
  );
};

export default Trackers;
