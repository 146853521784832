import moment from "moment";
import { useLanguage } from "../../../../../languages/useLanguage";
import "./DatePicker.css";
import { useState } from "react";
import { useEffect } from "react";

export default function MonthPicker({ date, setMonth }) {
  const { language } = useLanguage();
  const [_date, set_date] = useState(moment(date).format("YYYY-MM"));

  const addMonth = () => {
    set_date(
      moment(new Date(_date).setMonth(new Date(_date).getMonth() + 1)).format(
        "YYYY-MM"
      )
    );
  };

  const substractMonth = () => {
    set_date(
      moment(new Date(_date).setMonth(new Date(_date).getMonth() - 1)).format(
        "YYYY-MM"
      )
    );
  };

  useEffect(() => {
    set_date(moment(date).format("YYYY-MM"));
  }, [date]);

  useEffect(() => {
    setMonth(_date);
  }, [_date]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container-flex">
      <div className="date-element date-element--label">
        <span onClick={substractMonth}>
          &nbsp;&nbsp;&nbsp;{"<<"}&nbsp;&nbsp;&nbsp;
        </span>
        <label htmlFor="month">
          {language.words.components.billing.monthPicker.MONTH}
        </label>
        <span onClick={addMonth}>
          &nbsp;&nbsp;&nbsp;{">>"}&nbsp;&nbsp;&nbsp;
        </span>
      </div>
      <input
        className="date-element text-center"
        id="month"
        type="month"
        name="month"
        onChange={(e) => setMonth(e.target.value)}
        value={_date}
      />
      <div className="separator" />
    </div>
  );
}
