import { Link } from "react-router-dom";
import { getUser, logout } from "../services/User";
import config from "../Config";
import "./Menu.css";
import { stopModeration } from "../services/Admin";
import { useLanguage } from "../languages/useLanguage";
import Logo from "./Logo";

const _logout = async () => {
  await closeMenu();
  await logout();
};

const closeMenu = () => document.querySelector("#toggle").click();

const MenuLinks = ({ userLoggedIn, userRoles }) => {
  return userLoggedIn ? (
    <UserMenuLinks userRoles={userRoles} />
  ) : (
    <AnonMenuLinks />
  );
};

const UserMenuLinks = ({ userRoles }) => {
  const { language } = useLanguage();

  return (
    <>
      {userRoles?.includes("isTipster") && (
        <>
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={closeMenu}
              to={config.client.uris.products}
            >
              {language.words.components.general.menu.MY_PRODUCTS}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={closeMenu}
              to={config.client.uris.approvals}
            >
              {language.words.components.general.menu.MY_REQUESTS}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={closeMenu}
              to={config.client.uris.coupons}
            >
              {language.words.components.general.menu.MY_COUPONS}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={closeMenu}
              to={config.client.uris.billing}
            >
              {language.words.components.general.menu.MY_BILLING}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={closeMenu}
              to={config.client.uris.promos}
            >
              {language.words.components.general.menu.PROMOS}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={closeMenu}
              to={config.client.uris.trackers}
            >
              {language.words.components.general.menu.MY_DEALS}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={closeMenu}
              to={config.client.uris.profile}
            >
              {language.words.components.general.menu.MY_PROFILE}
            </Link>
          </li>
        </>
      )}
      {(userRoles?.includes("isAdmin") || userRoles?.includes("isManager")) && (
        <>
          <li className="nav-item">
            <Link
              className="nav-link"
              onClick={closeMenu}
              to={config.client.uris.admin}
            >
              {language.words.components.general.menu.ADMIN}
            </Link>
          </li>
        </>
      )}
      <li className="nav-item">
        <Link
          className="nav-link"
          onClick={_logout}
          to={config.client.uris.login}
        >
          {" "}
          {language.words.components.general.menu.LOGOUT}{" "}
          <i className="fa fa-sign-out" aria-hidden="true"></i>
        </Link>
      </li>
    </>
  );
};

const AnonMenuLinks = () => {
  const { language } = useLanguage();

  return (
    <li className="nav-item">
      <Link
        className="nav-link"
        onClick={closeMenu}
        to={config.client.uris.login}
      >
        {language.words.components.general.menu.LOGIN}
      </Link>
    </li>
  );
};

export default function Menu({ user, isModerating, setAlert }) {
  const { language } = useLanguage();

  const fetchStopModeration = async () => {
    try {
      const user = await getUser();

      if (!user) throw new Error(language.words.errors.INVALID_USER);

      await stopModeration();

      window.location.replace(config.client.uris.products);
    } catch (error) {
      setAlert(error?.message, error);
    }
  };

  const userLoggedIn = Boolean(user?.status === "active");
  const userRoles = ["isAdmin", "isManager", "isTipster"].filter(
    (key) => user?.meta[key]
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <Link className="logo" to={user.status === "active" ? "/" : "#"}>
        <Logo />
        {isModerating && (
          <button
            className="action-button danger"
            onClick={() => {
              fetchStopModeration();
            }}
          >
            <span className="bi bi-person-dash"></span>
            {user.firstname}
          </button>
        )}
      </Link>
      <input className="input-toggler" id="toggle" type="checkbox" />
      <label className="menu-toggler" htmlFor="toggle">
        <span className="menu-toggler-line"></span>
        <span className="menu-toggler-line"></span>
        <span className="menu-toggler-line"></span>
      </label>
      <aside className="sidebar">
        <ul className="menu">
          <MenuLinks userLoggedIn={userLoggedIn} userRoles={userRoles} />
        </ul>
      </aside>
    </nav>
  );
}
