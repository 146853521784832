import { useEffect, useState } from "react";
import config from "../../../../../Config";
import { useLanguage } from "../../../../../languages/useLanguage";
import {
  updateWebTracker,
  updateTelegramTracker,
} from "../../../../../services/Tracker";
import { copyToClipboard, getDomain, getPublicUri } from "../../../../../Utils";
import "./Card.css";

export default function CardComponent({
  trackerInit,
  setShowPromo,
  setPromoId,
  setShowSort,
  setSortType,
  setAlert,
}) {
  const [domain, setDomain] = useState(null);
  const { language } = useLanguage();
  const [tracker, setTracker] = useState(trackerInit);

  const updateTelegramStatus = async (trackerId, show) => {
    try {
      const _tracker = await updateTelegramTracker(trackerId, show);
      setTracker(_tracker);
    } catch (error) {
      setAlert(error?.message || error);
    }
  };

  const updateWebStatus = async (trackerId, show) => {
    try {
      const _tracker = await updateWebTracker(trackerId, show);
      setTracker(_tracker);
    } catch (error) {
      setAlert(error?.message || error);
    }
  };

  useEffect(() => {
    setDomain(() => {
      return getDomain(window.location.host).domain;
    });
  }, [window.location.host]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="list-card tracker">
      <div className="betting-site">
        <div className="betting-site-image">
          <img
            src={
              getPublicUri(window.location.hostname) +
              "/betting-sites/" +
              tracker.promo?.image
            }
            alt={tracker.promo?.bettingSite}
          />
        </div>
        <div className="country-image">
          <img
            src={
              getPublicUri(window.location.hostname) +
              "/countries/" +
              tracker.promo?.country?.replace(/ /g, "_") +
              ".png"
            }
            alt={tracker.promo?.country}
          />
        </div>
      </div>
      <div className="betting-site-info">
        <div className="copy-tracker">
          <span className="bi bi-clipboard-plus"></span>
          <input
            className="copy-tracker-input"
            type="button"
            onClick={(event) => {
              console.log(config.client.subdomains.b.uri);
              copyToClipboard(
                event,
                `https://${config.client.subdomains.b.value}.${domain}/${tracker.user.betsfyId}/${tracker.promo.betsfyId}`
              );
            }}
            defaultValue={language.words.components.tracker.card.COPY_TRACKER}
          />
        </div>
        <div className="info-icon-wrapper">
          <span
            className="info-icon"
            onClick={() => {
              setPromoId(tracker.promo?._id || "");
              setShowPromo(true);
            }}
          >
            i
          </span>
        </div>
      </div>
      <div className="gray-bar" />
      <div className="status-wrapper">
        <div className="status-wrapper">
          <div
            className={
              "status-info" +
              (tracker.telegram?.show !== false ? " success" : " danger")
            }
          >
            {language.words.components.tracker.card.TELEGRAM}
          </div>
          {tracker.telegram?.show !== false ? (
            <div
              className="action-button"
              onClick={() => updateTelegramStatus(tracker._id, false)}
            >
              <span
                className="bi bi-eye"
                title={language.words.components.tracker.card.HIDE}
              ></span>
            </div>
          ) : (
            <div
              className="action-button"
              onClick={() => updateTelegramStatus(tracker._id, true)}
            >
              <span
                className="bi bi-eye-slash"
                title={language.words.components.tracker.card.SHOW}
              ></span>
            </div>
          )}
        </div>
        <div className="status-wrapper">
          <div
            className="action-button"
            onClick={() => {
              setShowSort(true);
              setSortType("telegram");
            }}
          >
            {language.words.components.tracker.card.SORT}
          </div>
        </div>
      </div>
      <div className="separator" />
      <div className="status-wrapper">
        <div className="status-wrapper">
          <div
            className={
              "status-info" +
              (tracker.web?.show !== false ? " success" : " danger")
            }
          >
            {language.words.components.tracker.card.WEB}
          </div>
          {tracker.web?.show !== false ? (
            <div
              className="action-button"
              onClick={() => updateWebStatus(tracker._id, false)}
            >
              <span
                className="bi bi-eye"
                title={language.words.components.tracker.card.HIDE}
              ></span>
            </div>
          ) : (
            <div
              className="action-button"
              onClick={() => updateWebStatus(tracker._id, true)}
            >
              <span
                className="bi bi-eye-slash"
                title={language.words.components.tracker.card.SHOW}
              ></span>
            </div>
          )}
        </div>
        <div className="status-wrapper">
          <div
            className="action-button"
            onClick={() => {
              setShowSort(true);
              setSortType("web");
            }}
          >
            {language.words.components.tracker.card.SORT}
          </div>
        </div>
      </div>
      <div className="gray-bar" />
      <div className="row">
        <div className="container-fluid">
          <div className="conditions">
            <h4>{`${language.words.components.tracker.card.CONDITIONS}:`}</h4>
            <p>{tracker.promo?.summary?.replace(/<br>/g, "\n")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
