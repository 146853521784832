import { useState, useEffect } from "react";
import config from "../../../../Config";
import { getUserProfile, updateUser } from "../../../../services/User";
import { sendLog } from "../../../../services/App";
import { copyToClipboard, getDomain, getPublicUri } from "../../../../Utils";
import "./Profile.css";
import { useLanguage } from "../../../../languages/useLanguage";
import useInput from "../../../../components/hooks/useInput";
import useTextarea from "../../../../components/hooks/useTextarea";
import InputImage from "../../../../components/InputImage";

export default function ProfilePage({ setLoader, setAlert }) {
  const [domain, setDomain] = useState(null);
  const { language } = useLanguage();
  const [user, setUser] = useState({});

  const [showBotToken, setShowBotToken] = useState(false);
  const [showPromoBotToken, setShowPromoBotToken] = useState(false);

  const firstname = useInput("");
  const nick = useInput("");
  const [profilePic, setProfilePic] = useState("");
  const biography = useTextarea("");
  const phoneNumber = useInput("");
  const email = useInput("");
  const betsfyId = useInput("");
  const token = useInput("");
  const promoToken = useInput("");
  const password = useInput("");
  const _password = useInput("");

  const setAllValues = (data) => {
    setProfilePic(data.profilePic || "");
    firstname.setValue(data.firstname || "");
    nick.setValue(data.nick || "");
    biography.setValue(data.biography || "");
    phoneNumber.setValue(data.phoneNumber || "");
    email.setValue(data.email || "");
    betsfyId.setValue(data.betsfyId || "");
    token.setValue(data.bot?.token || "");
    promoToken.setValue(data.promoBot?.token || "");
  };

  const send = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        profilePic: profilePic,
        firstname: firstname.value,
        nick: nick.value,
        biography: biography.value,
        phoneNumber: phoneNumber.value,
        email: email.value,
        betsfyId: betsfyId.value,
        token: token.value,
        promoToken: promoToken.value,
      };

      if (
        password.value &&
        password.value !== "" &&
        password.value !== _password.value
      ) {
        return setAlert(
          language.words.components.user.profile.PASSWORDS_NOT_MATCH
        );
      }

      if (password.value && password.value !== "") {
        payload.password = password.value;
        payload._password = _password.value;
      }

      const _user = await updateUser(payload);

      setUser(_user);

      setAlert(language.words.components.user.profile.DATA_UPDATED);
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message || error);
    }
  };

  useEffect(() => {
    if (user?._id) {
      setAllValues(user);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDomain(() => {
      return getDomain(window.location.host).domain;
    });
  }, [window.location.host]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUser = async () => {
    setLoader(true);
    const _user = await getUserProfile();

    setUser(_user);
    setLoader(false);
  };

  useEffect(() => {
    fetchUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="container user-profile"
      onKeyDown={(e) => e.key === "Enter" && send()}
    >
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {language.words.components.user.profile.MY_PROFILE}
          </div>
          <div className="separator" />
          {user.betsfyId && user.betsfyId !== "" && (
            <div className="landing">
              <a
                className="landing-link"
                id="botLink"
                href={`https://${config.client.subdomains.b.value}.${domain}/${user.betsfyId}`}
                target="_blank"
                rel="noreferrer"
              >
                {`${language.words.components.user.profile.YOUR_LANDING} `}
                <span className="bi bi-box-arrow-up-right"></span>
              </a>
              <div className="copy-landing">
                <span className="bi bi-clipboard-plus"></span>
                <input
                  className="copy-landing-input"
                  type="button"
                  onClick={(event) =>
                    copyToClipboard(
                      event,
                      `https://${config.client.subdomains.b.value}.${domain}/${user.betsfyId}`
                    )
                  }
                  defaultValue={
                    language.words.components.user.profile.COPY_LANDING
                  }
                />
              </div>
            </div>
          )}
          <form onSubmit={send}>
            <div className="form-group">
              <label className="control-label" htmlFor="image">
                {language.words.components.user.loginHash.AVATAR}
              </label>
              <div className="profile-pic">
                <div className="profile-pic-wrapper">
                  <img
                    src={
                      profilePic
                        ? `${getPublicUri(
                            window.location.hostname
                          )}/${profilePic}`
                        : "https://dummyimage.com/100x100?text=" +
                          language.words.components.user.loginHash.AVATAR
                    }
                    alt={language.words.components.user.loginHash.AVATAR}
                  />
                </div>
                <InputImage
                  {...{
                    type: "profile",
                    tipsterId: user._id,
                    setImage: setProfilePic,
                    setAlert,
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="firstname">
                {language.words.components.user.profile.NAME}
              </label>
              <input
                className="form-control"
                type="text"
                name="firstname"
                id="firstname"
                {...firstname.inputAttributes}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="nick">
                {language.words.components.user.profile.USERNAME}
              </label>
              <input
                className="form-control"
                type="text"
                name="nick"
                id="nick"
                {...nick.inputAttributes}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="biography">
                {language.words.components.user.profile.BIOGRAPHY}
              </label>
              <textarea
                className="form-control"
                name="biography"
                id="biography"
                maxLength={255}
                style={{ height: "150px" }}
                {...biography.textareaAttributes}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="phoneNumber">
                {`${language.words.components.user.profile.PHONE_NUMBER} *`}
              </label>
              <input
                className="form-control"
                id="phoneNumber"
                type="tel"
                name="phoneNumber"
                {...phoneNumber.inputAttributes}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="email">
                {`${language.words.components.user.profile.EMAIL} *`}
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                placeholder="Email"
                {...email.inputAttributes}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="password">
                {language.words.components.user.profile.NEW_PASSWORD}
              </label>
              <input
                className="form-control"
                type="password"
                name="password"
                id="password"
                placeholder={
                  language.words.components.user.profile.NEW_PASSWORD
                }
                {...password.inputAttributes}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="_password">
                {language.words.components.user.profile.REPEAT_NEW_PASSWORD}
              </label>
              <input
                className="form-control"
                type="password"
                name="_password"
                id="_password"
                placeholder={
                  language.words.components.user.profile.REPEAT_NEW_PASSWORD
                }
                {..._password.inputAttributes}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="betsfyId">
                {language.words.components.user.profile.AFFILIATE_ID}
              </label>
              <input
                className="form-control"
                type="text"
                name="betsfyId"
                id="betsfyId"
                disabled
                {...betsfyId.inputAttributes}
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="token">
                {language.words.components.user.profile.BOT_TOKEN}
              </label>
              <div className="d-flex felx-row">
                <input
                  className="form-control"
                  type={showBotToken ? "text" : "password"}
                  name="token"
                  id="token"
                  {...token.inputAttributes}
                />
                <i
                  role="button"
                  className={"bi bi-eye" + (showBotToken ? "-slash" : "")}
                  style={{
                    fontSize: "24px",
                    marginLeft: "-40px",
                    marginTop: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => setShowBotToken((prev) => !prev)}
                ></i>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="promoToken">
                {language.words.components.user.profile.PROMO_BOT_TOKEN}
              </label>
              <div className="d-flex felx-row">
                <input
                  className="form-control"
                  type={showPromoBotToken ? "text" : "password"}
                  name="promoToken"
                  id="promoToken"
                  {...promoToken.inputAttributes}
                />
                <i
                  role="button"
                  className={"bi bi-eye" + (showPromoBotToken ? "-slash" : "")}
                  style={{
                    fontSize: "24px",
                    marginLeft: "-40px",
                    marginTop: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => setShowPromoBotToken((prev) => !prev)}
                ></i>
              </div>
            </div>
            <div className="form-group">
              <input
                className="btn btn-primary submit"
                type="submit"
                value={language.words.components.user.profile.UPDATE}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
