import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import config from "../../../../Config";
import { getUser, getUserTrackers } from "../../../../services/User";
import {
  getProduct,
  updateProduct,
  deleteProduct,
} from "../../../../services/Product";
import { sendLog } from "../../../../services/App";
import TrackerSelection from "./components/TrackerSelection";
import "./Edit.css";
import { useLanguage } from "../../../../languages/useLanguage";
import { getPublicUri } from "../../../../Utils";

export default function ProductEditPage({ setAlert }) {
  const { language } = useLanguage();
  const [{ form, product }, setState] = useState({});
  const [trackers, setTrackers] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewScreenshot, setPreviewScreenshot] = useState(null);
  const [image, setImage] = useState(null);
  const [screenshot, setScreenshot] = useState(null);
  const [showTrackers, setShowTrackers] = useState(false);

  const params = useParams();

  const getForm = async (productId) => {
    const user = await getUser();

    if (!user) throw new Error(language.words.errors.INVALID_USER);

    const product = await getProduct(productId);

    const trackersIds = product.trackers?.map((_tracker) => _tracker._id);

    const form = {
      title: product.title || "",
      description: product.description || "",
      price: product.price || "",
      startDate: product.startDate
        ? moment(product.startDate).format("YYYY-MM-DDTHH:mm")
        : "",
      expirationDate: product.expirationDate
        ? moment(product.expirationDate).format("YYYY-MM-DDTHH:mm")
        : "",
      trackers: trackersIds || [],
    };

    return { form, product };
  };

  useEffect(() => {
    getForm(params.productId)
      .then(setState)
      .catch(() => window.location.replace(config.client.uris.login));

    getUserTrackers().then(setTrackers).catch(console.error);
  }, [params.productId]); // eslint-disable-line react-hooks/exhaustive-deps

  const update = (event) => {
    const payload = { ...form };

    payload[event.target.name] = event.target.value;

    setState({ form: payload, product });
  };

  const updateTrackers = (trackers) => {
    setState({ product, form: { ...form, trackers } });
    setShowTrackers(false);
  };

  const _delete = async () => {
    const _product = await deleteProduct(product._id);

    if (!_product)
      return setAlert(language.words.components.product.edit.DELETE_ERROR);

    window.location.replace(config.client.uris[product.type + "s"]);
  };

  const uploadImage = (event) => {
    const previewImagePath = URL.createObjectURL(event.target.files[0]);

    setImage(event.target);
    setPreviewImage(previewImagePath);
  };

  const uploadScreenshot = (event) => {
    const previewImagePath = URL.createObjectURL(event.target.files[0]);

    setScreenshot(event.target);
    setPreviewScreenshot(previewImagePath);
  };

  const send = async () => {
    try {
      const formData = new FormData();

      if (image?.files) {
        formData.append("image", image.files[0]);
      }

      if (screenshot?.files) {
        formData.append("screenshot", screenshot.files[0]);
      }

      Object.entries(form).forEach((obj) => {
        formData.append(obj[0], obj[1]);
      });

      await updateProduct(product._id, formData);

      window.location.replace(config.client.uris[product.type + "s"]);
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message || error);
    }
  };

  useEffect(() => {
    if (product) {
      setPreviewImage((prev) => {
        if (product.image) {
          return `${getPublicUri(window.location.hostname)}/${product.image}`;
        } else {
          return (
            "https://dummyimage.com/240x240?text=" +
            language.words.components.product.edit.PRODUCT_IMAGE
          );
        }
      });

      setPreviewScreenshot((prev) => {
        if (product.screenshot) {
          return `${getPublicUri(window.location.hostname)}/${product.screenshot}`;
        } else {
          return (
            "https://dummyimage.com/240x240?text=" +
            language.words.components.product.edit.PRODUCT_SCREENSHOT
          );
        }
      });
    }
  }, [product]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!product) return "";

  if (showTrackers) {
    return (
      <TrackerSelection
        trackers={trackers}
        previousTrackers={form.trackers}
        updateTrackers={updateTrackers}
      />
    );
  }

  return (
    <div
      className="container main products"
      onKeyDown={(e) => e.key === "Enter" && send()}
    >
      <div
        className="btn-navigate-back"
        onClick={() =>
          window.location.replace(config.client.uris[product.type + "s"])
        }
      >
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {product.type === "tip"
              ? language.words.components.product.edit.EDIT_TIP
              : product.type === "suscription"
              ? language.words.components.product.edit.EDIT_SUBSCRIPTION
              : ""}
          </div>
          <div className="separator" />
          <div className="preview-upload-file form-group">
            <img
              id="preview-upload-file__image"
              src={previewImage}
              alt={language.words.components.product.edit.PRODUCT}
            />
          </div>
          <div className="form-group">
            <label className="control-label" htmlFor="image">
              {language.words.components.product.edit.IMAGE}
            </label>
            <input
              className="form-control"
              type="file"
              name="image"
              onChange={(event) => uploadImage(event)}
              id="image"
              accept="image/*"
            />
          </div>
          <div className="form-group">
            <label className="control-label" htmlFor="title">
              {language.words.components.product.edit.PRODUCT_TITLE}
            </label>
            <input
              className="form-control"
              type="text"
              name="title"
              id="title"
              onChange={update}
              placeholder={language.words.components.product.edit.TITLE}
              defaultValue={product.title}
            />
          </div>
          <div className="form-group" id="description-wrapper">
            <label
              className="control-label"
              id="description-label"
              htmlFor="description"
            >
              {product.type === "tip"
                ? language.words.components.product.edit.TIP
                : language.words.components.product.edit.DESCRIPTION}
            </label>
            <textarea
              className="form-control"
              name="description"
              id="description"
              onChange={update}
              rows="11"
              defaultValue={product.description}
            />
          </div>
          {product.type === "tip" && (
            <>
              <div className="preview-upload-file form-group">
                <img
                  id="preview-upload-file__image"
                  src={previewScreenshot}
                  alt={language.words.components.product.edit.TIP}
                />
              </div>
              <div className="form-group">
                <label htmlFor="screenshot" className="control-label">
                  {language.words.components.product.edit.SCREENSHOT_CLIENT}
                </label>
                <input
                  id="screenshot"
                  onChange={(event) => uploadScreenshot(event)}
                  type="file"
                  name="screenshot"
                  className="form-control"
                  accept="image/*"
                />
              </div>
            </>
          )}
          {product.isPromo && (
            <div className="form-group">
              <button
                className="btn btn-primary"
                onClick={() => setShowTrackers(true)}
              >
                {language.words.components.product.edit.SHOW_TRACKERS}
              </button>
            </div>
          )}
          {(product.isPayment || (!product.isPromo && !product.isPayment)) && (
            <div className="form-group">
              <label className="control-label" htmlFor="price">
                {language.words.components.product.edit.PRICE}
              </label>
              <input
                className="form-control"
                type="text"
                name="price"
                id="price"
                onChange={update}
                placeholder={language.words.components.product.edit.MAX_PRICE}
                pattern="^([0-9]{1,3})(.|,)?([0-9]{1,3})?$"
                defaultValue={form.price}
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="startDate" className="control-label">
              {language.words.components.product.edit.START_DATE}
            </label>
            <input
              id="startDate"
              onChange={update}
              type="datetime-local"
              name="startDate"
              className="form-control"
              defaultValue={form.startDate}
            />
          </div>
          <div className="form-group" id="expiration-wrapper">
            <label className="control-label" htmlFor="expirationDate">
              {language.words.components.product.edit.EXPIRATION_DATE}
            </label>
            <input
              className="form-control"
              type="datetime-local"
              name="expirationDate"
              id="expirationDate"
              onChange={update}
              defaultValue={form.expirationDate}
            />
          </div>
          <div className="form-group">
            <input
              className="btn btn-primary update"
              type="button"
              onClick={send}
              defaultValue={
                language.words.components.product.edit.UPDATE_PRODUCT
              }
            />
          </div>
          <div className="form-group">
            <input
              className="btn btn-primary update"
              type="button"
              onClick={_delete}
              defaultValue={
                language.words.components.product.edit.DELETE_PRODUCT
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
