import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../../../languages/useLanguage";
import { importCSV, exportCSV } from "../../../../../services/Admin";
import { sendLog } from "../../../../../services/App";

export default function AdminBulkPage({ setAlert }) {
  const { language } = useLanguage();
  const [event, setEvent] = useState({});
  const navigate = useNavigate();

  const handleError = (error) => setAlert(error.message || error);

  const exportBilling = () => exportCSV("billing").catch(handleError);
  const exportTrackers = () => exportCSV("trackers").catch(handleError);
  const exportPromos = () => exportCSV("promos").catch(handleError);
  const exportUsers = () => exportCSV("users").catch(handleError);
  const exportAffiliationReport = () =>
    exportCSV("affiliation-reports").catch(handleError);

  const importTrackers = () => send("trackers");
  const importPromos = () => send("promos");
  const importUsers = () => send("users");
  const importAffiliationReport = () => send("affiliation-reports");

  const send = async (type) => {
    try {
      const formData = new FormData();

      if (event?.target?.files) {
        formData.append("csv", event.target.files[0]);
      }

      const errorLines = await importCSV(type, formData);

      const errorMessage = errorLines.length
        ? `${
            language.words.components.admin.bulk.OMITTED_LINES
          }:<br>${errorLines.join(", ")}`
        : "";

      setAlert(
        errorMessage || language.words.components.admin.bulk.UPDATED_DATA
      );
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message || error);
    }
  };

  return (
    <div className="container main products list">
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {language.words.components.admin.bulk.BULK}
          </div>
          <div className="separator" />
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <h2 className="fs-title center">
                {language.words.components.admin.bulk.ORDERS}
              </h2>
              <input
                type="button"
                className="btn btn-primary"
                defaultValue={
                  language.words.components.admin.bulk.EXPORT_ORDERS
                }
                onClick={exportBilling}
              />
              <div className="separator" />
              <h2 className="fs-title center">
                {language.words.components.admin.bulk.USERS}
              </h2>
              <input
                type="button"
                onClick={exportUsers}
                className="btn btn-primary"
                defaultValue={language.words.components.admin.bulk.EXPORT_USERS}
              />
              <div className="separator" />
              <div className="row">
                <div className="container-fluid">
                  <div className="form">
                    <div className="form-group">
                      <input
                        type="file"
                        name="csv"
                        onChange={setEvent}
                        className="form-control"
                        accept=".csv"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        className="btn btn-primary"
                        type="button"
                        onClick={importUsers}
                        defaultValue={
                          language.words.components.admin.bulk.IMPORT_USERS
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="fs-title center">
                {language.words.components.admin.bulk.TRACKERS}
              </h2>
              <input
                type="button"
                onClick={exportTrackers}
                className="btn btn-primary"
                defaultValue={
                  language.words.components.admin.bulk.EXPORT_TRACKERS
                }
              />
              <div className="separator" />
              <div className="form">
                <div className="form-group">
                  <input
                    type="file"
                    name="csv"
                    onChange={setEvent}
                    className="form-control"
                    accept=".csv"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    className="btn btn-primary"
                    type="button"
                    onClick={importTrackers}
                    defaultValue={
                      language.words.components.admin.bulk.IMPORT_TRACKERS
                    }
                  />
                </div>
              </div>
              <h2 className="fs-title center">
                {language.words.components.admin.bulk.PROMOS}
              </h2>
              <input
                type="button"
                onClick={exportPromos}
                className="btn btn-primary"
                defaultValue={
                  language.words.components.admin.bulk.EXPORT_PROMOS
                }
              />
              <div className="separator" />
              <div id="import" className="form">
                <div className="form-group">
                  <input
                    type="file"
                    name="csv"
                    onChange={setEvent}
                    className="form-control"
                    accept=".csv"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    className="btn btn-primary"
                    type="button"
                    onClick={importPromos}
                    defaultValue={
                      language.words.components.admin.bulk.IMPORT_PROMOS
                    }
                  />
                </div>
              </div>
              <h2 className="fs-title center">
                {language.words.components.admin.bulk.AFFILIATION_REPORT}
              </h2>
              <input
                type="button"
                onClick={exportAffiliationReport}
                className="btn btn-primary"
                defaultValue={
                  language.words.components.admin.bulk.EXPORT_AFFILIATION_REPORT
                }
              />
              <div className="separator" />
              <div id="import" className="form">
                <div className="form-group">
                  <input
                    type="file"
                    name="csv"
                    onChange={setEvent}
                    className="form-control"
                    accept=".csv"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    className="btn btn-primary"
                    type="button"
                    onClick={importAffiliationReport}
                    defaultValue={
                      language.words.components.admin.bulk
                        .IMPORT_AFFILIATION_REPORT
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
