import React from "react";
import "./NotificationBar.css";

export default function NotificationBar({ visible, text, unsetAlert }) {
  if (!visible || !text) return "";

  return (
    <div className="notification-bar">
      <input
        type="button"
        className="btn btn-primary inverted close-notification"
        onClick={unsetAlert}
        defaultValue="Cerrar"
      />
      <div className="row">
        <div className="container-fluid">
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </div>
    </div>
  );
}
