import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useInput from "../../../../../components/hooks/useInput";
import useTextarea from "../../../../../components/hooks/useTextarea";
import InputImage from "../../../../../components/InputImage";
import config from "../../../../../Config";
import { useLanguage } from "../../../../../languages/useLanguage";
import { createPredictContest } from "../../../../../services/Admin";
import { sendLog } from "../../../../../services/App";
import { getPublicUri } from "../../../../../Utils";
import MatchOptions from "./components/MatchOptions";
import "./Create.css";

const PredictContestCreatePage = ({ setLoader, setAlert }) => {
  const { language } = useLanguage();
  const navigate = useNavigate();

  const [headerImage, setHeaderImage] = useState("");
  const [matchs, setMatchs] = useState([
    {
      home: {
        name: "",
        badge: "",
      },
      away: {
        name: "",
        badge: "",
      },
    },
  ]);
  const legal = useTextarea("");
  const [footerImage, setFooterImage] = useState("");
  const startDate = useInput(moment(Date.now()).format("YYYY-MM-DDTHH:mm"));
  const expirationDate = useInput("");

  const send = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      await createPredictContest({
        header: {
          image: headerImage,
        },
        footer: {
          image: footerImage,
        },
        matchs: matchs,
        legal: legal.value,
        startDate: startDate.value,
        expirationDate: expirationDate.value,
      });

      navigate(config.client.uris.adminPredictContest);
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="container main">
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <div className="separator" />
              <div className="h1-header">
                {
                  language.words.components.admin.predictContest.create
                    .CREATE_PREDICT_CONTEST
                }
              </div>
              <div className="separator" />
            </div>
          </div>
          <div className="form create-predict-contest">
            <form onSubmit={send}>
              <div className="form-group">
                <label className="control-label" htmlFor="headerImage">
                  {language.words.components.admin.predictContest.create.HEADER}
                </label>
                <div className="header">
                  <img
                    src={
                      headerImage
                        ? `${getPublicUri(
                            window.location.hostname
                          )}/${headerImage}`
                        : "https://dummyimage.com/240x80?text=" +
                          language.words.components.admin.predictContest.create
                            .NO_HEADER_IMAGE
                    }
                    alt={
                      language.words.components.admin.predictContest.create
                        .HEADER
                    }
                  />
                  <InputImage
                    {...{
                      type: "predict-contest",
                      setImage: setHeaderImage,
                      setAlert,
                    }}
                  />
                </div>
              </div>
              <h3 className="text-light">
                {language.words.components.admin.predictContest.create.MATCHS}
              </h3>
              {matchs?.map((match, index) => {
                return (
                  <div key={"match-" + index}>
                    <MatchOptions
                      match={match}
                      setMatchs={setMatchs}
                      index={index}
                    />
                  </div>
                );
              })}
              <button
                type="button"
                className="btn btn-primary"
                title="Añadir red social"
                onClick={() => {
                  setMatchs((prev) => {
                    return [
                      ...(prev || []),
                      {
                        home: {
                          name: "",
                          badge: "",
                        },
                        away: {
                          name: "",
                          badge: "",
                        },
                      },
                    ];
                  });
                }}
              >
                {
                  language.words.components.admin.predictContest.create
                    .ADD_MATCH
                }
              </button>
              <div className="form-group mt-3">
                <label htmlFor="legal" className="control-label">
                  {`${language.words.components.admin.predictContest.create.LEGAL} *`}
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="legal"
                  placeholder={
                    language.words.components.admin.predictContest.create.LEGAL
                  }
                  required
                  {...legal.textareaAttributes}
                />
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="footerImage">
                  {language.words.components.admin.predictContest.create.FOOTER}
                </label>
                <div className="footer">
                  <img
                    src={
                      footerImage
                        ? `${getPublicUri(
                            window.location.hostname
                          )}/${footerImage}`
                        : "https://dummyimage.com/240x80?text=" +
                          language.words.components.admin.predictContest.create
                            .NO_FOOTER_IMAGE
                    }
                    alt={
                      language.words.components.admin.predictContest.create
                        .FOOTER
                    }
                  />
                  <InputImage
                    {...{
                      type: "predict-contest",
                      setImage: setFooterImage,
                      setAlert,
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="startDate" className="form-label">
                  {`${language.words.components.admin.predictContest.create.START_DATE} *`}
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  name="startDate"
                  id="startDate"
                  required
                  {...startDate.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label htmlFor="expirationDate" className="form-label">
                  {`${language.words.components.admin.predictContest.create.EXPIRATION_DATE} *`}
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  name="expirationDate"
                  id="expirationDate"
                  required
                  {...expirationDate.inputAttributes}
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary create">
                  {
                    language.words.components.admin.predictContest.create
                      .CREATE_PREDICT_CONTEST
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PredictContestCreatePage;
