import React, { useState, useEffect, Fragment } from "react";
import config from "../../../../../Config";
import {
  requestInformation,
  changeStatus,
} from "../../../../../services/Approval";
import { sendLog } from "../../../../../services/App";
import { getApproval } from "../../../../../services/Approval";
import { getUser } from "../../../../../services/User";
import "./DetailsModal.css";
import { useLanguage } from "../../../../../languages/useLanguage";
import { getPrivateUri, getPublicUri } from "../../../../../Utils";

export default function ApprovalDetailModal({
  setLoader,
  setAlert,
  show,
  setShow,
  approvalId,
}) {
  const { language } = useLanguage();
  const [isStarting, setIsStarting] = useState(true);
  const [approval, setApproval] = useState({});
  const [form, setForm] = useState({});

  const toggleShow = () => {
    setShow((prev) => !prev);
  };

  const update = (event) => {
    const payload = { ...form };

    payload[event.target.name] = event.target.value;

    setForm(payload);
  };

  const send = async () => {
    try {
      if (!form.info)
        return setAlert(
          language.words.components.approval.details.COMPLETE_FIELDS
        );

      await requestInformation(approval._id, JSON.stringify(form));

      setShow(false);
    } catch (error) {
      sendLog("error", error);

      setAlert(error.message || error);
    }
  };

  const action = async (status) => {
    try {
      await changeStatus(approval._id, status);

      setShow(false);
    } catch (error) {
      sendLog("error", error);

      setAlert(error.message || error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    const fetchApproval = async () => {
      try {
        setLoader(true);
        const user = await getUser();

        if (!user) throw new Error(language.words.errors.INVALID_USER);

        const _approval = await getApproval(approvalId);

        setApproval(_approval);
      } catch (error) {
        sendLog("error", error);

        window.location.replace(config.client.uris.login);
      } finally {
        setLoader(false);
        setIsStarting(false);
      }
    };

    if (approvalId) {
      fetchApproval();
    } else {
      setShow(false);
    }

    return () => {
      controller.abort();
    };
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const body = document.querySelector("body");

    if (show) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [show]);

  return (
    <div
      className="approval modal fade show"
      style={{
        display: "block",
        visibility: show ? "visible" : "hidden",
        maxHeight: "100vh",
        maxWidth: "100vw",
      }}
      onClick={toggleShow}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-xl"
        role="document"
      >
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div
            className="modal-body"
            style={{ maxHeight: "75vh", overflowY: "scroll" }}
          >
            {!isStarting && (
              <div className="gray-block">
                <div className="container-fluid">
                  <div className="form">
                    <div className="shop-image--wrapper">
                      <a
                        href={`${getPrivateUri(window.location.hostname)}/${
                          approval.image
                        }`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div style={{ position: "relative" }}>
                          <img
                            src={`${getPrivateUri(window.location.hostname)}/${
                              approval.image
                            }`}
                            alt="approval"
                          />
                          <span className="watermark">+</span>
                        </div>
                      </a>
                    </div>
                    <div className="separator" />
                    <div className="shop-body">
                      <div className="shop-product-title">
                        {(approval.product === null ||
                          approval.product === undefined) &&
                          language.words.components.approval.details
                            .NO_PRODUCT_APPROVAL}
                        {approval.product?.type === "suscription" ? (
                          <Fragment>
                            <b>{approval.group.title}</b>
                            <br />
                          </Fragment>
                        ) : (
                          ""
                        )}
                        {approval.product?.title}
                      </div>
                      <div className="gray-bar" />
                      <div className="shop-product-description">
                        <div>
                          <span className="shop-product-label">
                            {`${language.words.components.approval.details.BETTING_SITE}: `}
                          </span>
                          <img
                            className="betting-site"
                            src={
                              getPublicUri(window.location.hostname) +
                              "/betting-sites/" +
                              approval.tracker?.promo?.image
                            }
                            alt={approval.tracker?.promo?.bettingSite}
                          />
                          <img
                            className="country"
                            src={
                              getPublicUri(window.location.hostname) +
                              "/countries/" +
                              approval.tracker?.promo?.country.replace(
                                / /g,
                                "_"
                              ) +
                              ".png"
                            }
                            alt={approval.tracker?.promo?.country}
                          />
                        </div>
                      </div>
                      <div className="shop-product-description">
                        <span className="shop-product-label">
                          {`${language.words.components.approval.details.CONDITIONS}: `}
                        </span>
                        {approval.tracker?.promo?.summary.replace(
                          /<br>/g,
                          "\n"
                        )}
                      </div>
                      <div className="shop-product-description">
                        <span className="shop-product-label">
                          {`${language.words.components.approval.details.BETTING_SITE_EMAIL}: `}
                        </span>
                        {approval.meta.email}
                      </div>
                      <div className="shop-product-price">
                        <span className="shop-product-label">
                          {`${language.words.components.approval.details.BETTING_SITE_USERNAME}: `}
                        </span>{" "}
                        {approval.meta.username}
                      </div>
                      <div className="shop-product-duration">
                        <span className="shop-product-label">
                          {`${language.words.components.approval.details.TELEGRAM_USERNAME}: `}
                        </span>
                        @{approval.user?.email?.replace(/@.*/, "")}
                      </div>
                    </div>
                    {approval.status === "in_progress" ? (
                      <>
                        <div className="form-group">
                          <div className="separator" />
                          <input
                            type="button"
                            className="btn btn-primary inverted"
                            onClick={() => action("approved")}
                            defaultValue={
                              language.words.components.approval.details.ACCEPT
                            }
                          />
                          <input
                            type="button"
                            className="btn btn-primary"
                            onClick={() => action("declined")}
                            defaultValue={
                              language.words.components.approval.details.DECLINE
                            }
                          />
                        </div>
                        <div className="form-group">
                          {!approval.meta.returnedInfo ? (
                            <div className="form">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  name="info"
                                  onChange={update}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  className="btn btn-primary"
                                  type="button"
                                  onClick={send}
                                  defaultValue={
                                    language.words.components.approval.details
                                      .REQUEST_INFO
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="form-group">
                        <div className="separator" />
                        <div
                          className={
                            "status-info" +
                            (approval.status === "in_progress"
                              ? " warning"
                              : approval.status === "completed"
                              ? " success"
                              : " danger")
                          }
                        >
                          {approval.status === "in_progress" && (
                            <div>
                              {`${language.words.components.approval.details.PENDING} `}
                              <span className="bi bi-exclamation-square"></span>
                            </div>
                          )}
                          {approval.status === "declined" && (
                            <div>
                              {`${language.words.components.approval.details.DECLINED} `}
                              <span className="bi bi-x"></span>
                            </div>
                          )}
                          {approval.status === "completed" && (
                            <div>
                              {`${language.words.components.approval.details.ACCEPTED} `}
                              <span className="bi bi-check"></span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={toggleShow}
            >
              {language.words.components.approval.details.CLOSE}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
