import { useState } from "react";
import { useLanguage } from "../languages/useLanguage";
import ImageModal from "./ImageModal";

const InputImage = ({ type, tipsterId, setImage, setAlert }) => {
  const { language } = useLanguage();
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <ImageModal
        {...{
          show: showModal,
          setShow: setShowModal,
          type,
          tipsterId,
          setImage,
          setAlert,
        }}
      />
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-success"
          onClick={() => setShowModal(true)}
        >
          {language.words.components.images.input.SELECT_IMAGE}
        </button>
        <button
          type="button"
          className="btn btn-warning"
          onClick={() => setImage(null)}
        >
          {language.words.components.images.input.REMOVE_IMAGE}
        </button>
      </div>
    </div>
  );
};

export default InputImage;
