import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useInput from "../../../../../components/hooks/useInput";
import useTextarea from "../../../../../components/hooks/useTextarea";
import InputImage from "../../../../../components/InputImage";
import config from "../../../../../Config";
import { useLanguage } from "../../../../../languages/useLanguage";
import {
  deletePredictContest,
  getPredictContest,
  updatePredictContest,
} from "../../../../../services/Admin";
import { sendLog } from "../../../../../services/App";
import { getUser } from "../../../../../services/User";
import { getPublicUri } from "../../../../../Utils";
import MatchOptions from "./components/MatchOptions";
import "./Create.css";

const PredictContestEditPage = ({ setLoader, setAlert }) => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const { id } = useParams();
  const [contest, setContest] = useState({});

  const [headerImage, setHeaderImage] = useState("");
  const [matchs, setMatchs] = useState([
    {
      home: {
        name: "",
        badge: "",
      },
      away: {
        name: "",
        badge: "",
      },
    },
  ]);
  const legal = useTextarea("");
  const [footerImage, setFooterImage] = useState("");
  const startDate = useInput("");
  const expirationDate = useInput("");

  const setAllValues = (data) => {
    setHeaderImage(data.header?.image);
    setMatchs(data.matchs);
    setFooterImage(data.footer?.image);
    legal.setValue(data.legal || "");
    startDate.setValue(
      data.startDate ? moment(data.startDate).format("YYYY-MM-DDTHH:mm") : ""
    );
    expirationDate.setValue(
      data.expirationDate
        ? moment(data.expirationDate).format("YYYY-MM-DDTHH:mm")
        : ""
    );
  };

  const send = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      await updatePredictContest(id, {
        header: {
          image: headerImage,
        },
        footer: {
          image: footerImage,
        },
        matchs: matchs,
        legal: legal.value,
        startDate: startDate.value,
        expirationDate: expirationDate.value,
      });

      navigate(config.client.uris.adminPredictContest);
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  const deleteContest = async () => {
    try {
      setLoader(true);

      await deletePredictContest(id);

      navigate(config.client.uris.adminPredictContest);
    } catch (error) {
      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  // get contest
  useEffect(() => {
    const fetchContest = async () => {
      try {
        setLoader(true);
        const user = await getUser();

        if (!user) new Error(language.words.errors.INVALID_USER);

        const response = await getPredictContest(id);

        setContest(response);
      } catch (error) {
        setAlert(error?.message || error);
      } finally {
        setLoader(false);
      }
    };

    if (id) {
      fetchContest();
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (contest._id) {
      setAllValues(contest);
    }
  }, [contest]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container main">
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <div className="separator" />
              <div className="h1-header">
                {
                  language.words.components.admin.predictContest.edit
                    .EDIT_PREDICT_CONTEST
                }
              </div>
              <div className="separator" />
            </div>
          </div>
          <div className="form create-predict-contest">
            <form onSubmit={send}>
              <div className="form-group">
                <label className="control-label" htmlFor="headerImage">
                  {language.words.components.admin.predictContest.edit.HEADER}
                </label>
                <div className="header">
                  <img
                    src={
                      headerImage
                        ? `${getPublicUri(
                            window.location.hostname
                          )}/${headerImage}`
                        : "https://dummyimage.com/240x80?text=" +
                          language.words.components.admin.predictContest.edit
                            .NO_HEADER_IMAGE
                    }
                    alt={
                      language.words.components.admin.predictContest.edit.HEADER
                    }
                  />
                  <InputImage
                    {...{
                      type: "predict-contest",
                      setImage: setHeaderImage,
                      setAlert,
                    }}
                  />
                </div>
              </div>
              <h3 className="text-light">
                {language.words.components.admin.predictContest.create.MATCHS}
              </h3>{" "}
              {matchs?.map((match, index) => {
                return (
                  <div key={"match-" + index}>
                    <MatchOptions
                      match={match}
                      setMatchs={setMatchs}
                      index={index}
                    />
                  </div>
                );
              })}
              <button
                type="button"
                className="btn btn-primary"
                title="Añadir red social"
                onClick={() => {
                  setMatchs((prev) => {
                    return [
                      ...(prev || []),
                      {
                        home: {
                          name: "",
                          badge: "",
                        },
                        away: {
                          name: "",
                          badge: "",
                        },
                      },
                    ];
                  });
                }}
              >
                {
                  language.words.components.admin.predictContest.create
                    .ADD_MATCH
                }
              </button>
              <div className="form-group mt-3">
                <label htmlFor="legal" className="control-label">
                  {`${language.words.components.admin.predictContest.edit.LEGAL}: `}
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="legal"
                  placeholder={
                    language.words.components.admin.predictContest.edit.LEGAL
                  }
                  required
                  {...legal.textareaAttributes}
                />
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="footerImage">
                  {language.words.components.admin.predictContest.edit.FOOTER}
                </label>
                <div className="footer">
                  <img
                    src={
                      footerImage
                        ? `${getPublicUri(
                            window.location.hostname
                          )}/${footerImage}`
                        : "https://dummyimage.com/240x80?text=" +
                          language.words.components.admin.predictContest.edit
                            .NO_FOOTER_IMAGE
                    }
                    alt={
                      language.words.components.admin.predictContest.edit.FOOTER
                    }
                  />
                  <InputImage
                    {...{
                      type: "predict-contest",
                      setImage: setFooterImage,
                      setAlert,
                    }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="startDate" className="form-label">
                  {`${language.words.components.admin.predictContest.edit.START_DATE} *`}
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  name="startDate"
                  id="startDate"
                  required
                  {...startDate.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label htmlFor="expirationDate" className="form-label">
                  {`${language.words.components.admin.predictContest.edit.EXPIRATION_DATE} *`}
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  name="expirationDate"
                  id="expirationDate"
                  required
                  {...expirationDate.inputAttributes}
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary create">
                  {
                    language.words.components.admin.predictContest.edit
                      .EDIT_PREDICT_CONTEST
                  }
                </button>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-primary btn-danger"
                  onClick={() => {
                    if (
                      window.confirm(
                        language.words.components.admin.predictContest.edit
                          .DELETE_CONFIRM
                      )
                    ) {
                      deleteContest();
                    }
                  }}
                >
                  {
                    language.words.components.admin.predictContest.edit
                      .DELETE_PREDICT_CONTEST
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PredictContestEditPage;
