import moment from "moment/moment";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../../../Config";
import { sendLog } from "../../../../services/App";
import { createCoupon } from "../../../../services/Coupon";
import { getProducts } from "../../../../services/Product";
import { getUser } from "../../../../services/User";
import useInput from "../../../../components/hooks/useInput";
import ProductSelection from "../product/components/ProductSelection";
import { useLanguage } from "../../../../languages/useLanguage";

const CouponCreatePage = ({ setLoader, setAlert }) => {
  const { language } = useLanguage();
  const [products, setProducts] = useState([]);
  const [showProductSelection, setShowProductSelection] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const key = useInput("");
  const valueType = useInput("flat");
  const valueInput = useInput("");
  const totalUses = useInput("0");
  const startDate = useInput(moment(Date.now()).format("YYYY-MM-DDTHH:mm"));
  const expirationDate = useInput("");

  const fetchProducts = async () => {
    const user = await getUser();

    if (!user) throw new Error(language.words.errors.INVALID_USER);

    const _products = await getProducts();

    const selectableProducts = _products.filter(
      (__product) => __product.status === "published" && __product.isPayment
    );

    return selectableProducts;
  };

  const send = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      await createCoupon({
        key: key.value,
        value,
        startDate: startDate.value,
        expirationDate: expirationDate.value,
        uses: {
          total: totalUses.value,
        },
        products: selectedProducts.map((product) => product._id),
      });

      navigate(config.client.uris.coupons);
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    key.setValue(key.value.toUpperCase());
  }, [key.value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValue((prev) => {
      if (valueType.value === "flat") {
        return valueInput.value;
      } else {
        return valueInput.value + "%";
      }
    });
  }, [valueType.value, valueInput.value]);

  useEffect(() => {
    fetchProducts().then(setProducts).catch(console.error);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container main">
      <ProductSelection
        {...{
          show: showProductSelection,
          setShow: setShowProductSelection,
          products,
          selectedProducts,
          setSelectedProducts,
        }}
      />
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="row">
            <div className="container-fluid">
              <div className="separator" />
              <div className="h1-header">
                {language.words.components.coupon.create.CREATE_COUPON}
              </div>
              <div className="separator" />
            </div>
          </div>
          <div className="form">
            <form onSubmit={send}>
              <div className="form-group">
                <label htmlFor="key" className="control-label">
                  {`${language.words.components.coupon.create.KEY} *`}
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={language.words.components.coupon.create.KEY}
                  pattern="^\S+$"
                  required
                  {...key.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label htmlFor="value" className="control-label">
                  {`${language.words.components.coupon.create.DISCOUNT} *`}
                </label>
                <div {...valueType.inputAttributes}>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="valueType"
                      id="valueTypeFlat"
                      value="flat"
                      defaultChecked={true}
                    />
                    <label htmlFor="valueTypeFlat" className="form-check-label">
                      {language.words.components.coupon.create.FLAT_DISCOUNT}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      name="valueType"
                      id="valueTypePercentage"
                      value="percentage"
                    />
                    <label
                      htmlFor="valueTypePercentage"
                      className="form-check-label"
                    >
                      {
                        language.words.components.coupon.create
                          .PERCENTAGE_DISCOUNT
                      }
                    </label>
                  </div>
                </div>
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    step={valueType.value === "flat" ? 0.01 : 1}
                    min={valueType.value === "flat" ? 0.01 : 1}
                    max={valueType.value === "flat" ? 350 : 100}
                    id="value"
                    placeholder={
                      language.words.components.coupon.create.DISCOUNT
                    }
                    required
                    {...valueInput.inputAttributes}
                  />
                  <span className="input-group-text">
                    {valueType.value === "flat"
                      ? "€"
                      : valueType.value === "percentage"
                      ? "%"
                      : ""}
                  </span>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="totalUses" className="control-label">
                  {language.words.components.coupon.create.NUMBER_OF_COUPONS +
                    " (" +
                    language.words.components.coupon.create.ZERO_UNLIMITED +
                    ")"}
                </label>
                <input
                  type="number"
                  className="form-control"
                  step={1}
                  min={0}
                  id="totalUses"
                  placeholder={
                    language.words.components.coupon.create.NUMBER_OF_COUPONS
                  }
                  required
                  {...totalUses.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label htmlFor="startDate" className="form-label">
                  {`${language.words.components.coupon.create.START_DATE} *`}
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  name="startDate"
                  id="startDate"
                  required
                  {...startDate.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label htmlFor="expirationDate" className="form-label">
                  {language.words.components.coupon.create.EXPIRATION_DATE +
                    " (" +
                    language.words.components.coupon.create.EMPTY_NO_EXPIRE +
                    ")"}
                </label>
                <input
                  type="datetime-local"
                  className="form-control"
                  name="expirationDate"
                  id="expirationDate"
                  {...expirationDate.inputAttributes}
                />
              </div>
              <div className="form-group">
                {selectedProducts.length === 0 ? (
                  <label>
                    {language.words.components.coupon.create.APPLICABLE_TO +
                      " " +
                      language.words.components.coupon.create.ALL_PRODUCTS}
                  </label>
                ) : selectedProducts.length === 1 ? (
                  <label>
                    {language.words.components.coupon.create.APPLICABLE_TO +
                      " " +
                      language.words.components.coupon.create.ONE_PRODUCT}
                  </label>
                ) : (
                  <label>
                    {language.words.components.coupon.create.APPLICABLE_TO +
                      " " +
                      selectedProducts.length +
                      " " +
                      language.words.components.coupon.create.PRODUCTS}
                  </label>
                )}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowProductSelection(true)}
                >
                  {language.words.components.coupon.create.SELECT_PRODUCTS}
                </button>
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary create">
                  {language.words.components.coupon.create.CREATE_COUPON}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponCreatePage;
