import { Route, Routes } from "react-router";
import { BApp, BotApp, PayApp } from "../pages";

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<BotApp />} />
    </Routes>
  );
};

export const PayRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<PayApp />} />
    </Routes>
  );
};

export const BRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<BApp />} />
    </Routes>
  );
};
