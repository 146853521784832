import { useLanguage } from "../../../../../languages/useLanguage";
import { getPublicUri } from "../../../../../Utils";
import "./Card.css";

export default function CardComponent({
  approval,
  setShowDetails,
  setApprovalId,
}) {
  const { language } = useLanguage();

  return (
    <div className="list-card approval">
      <div className="row">
        <div className="container-fluid">
          <div className="h1-header">
            {(approval.product === null || approval.product === undefined) &&
              language.words.components.approval.card.NO_PRODUCT_APPROVAL}
            {approval.product?.type === "suscription" && (
              <>
                {approval.group.title}
                <br />
              </>
            )}
            {approval.product?.title}
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="container-fluid">
          <div>
            {`${language.words.components.approval.card.BETTING_SITE}: `}
            <img
              className="betting-site"
              src={
                getPublicUri(window.location.hostname) +
                "/betting-sites/" +
                approval.tracker?.promo?.image
              }
              alt={approval.tracker?.promo?.bettingSite}
            />{" "}
            <img
              className="country"
              src={
                getPublicUri(window.location.hostname) +
                "/countries/" +
                approval.tracker?.promo?.country.replace(/ /g, "_") +
                ".png"
              }
              alt={approval.tracker?.promo?.country}
            />
          </div>
        </div>
        <div className="container-fluid">
          <div>
            {`${language.words.components.approval.card.BETTING_SITE_USERNAME}: `}
            <b>{approval.meta?.username}</b>
          </div>
        </div>
        <div className="container-fluid">
          <div>
            {`${language.words.components.approval.card.BETTING_SITE_EMAIL}: `}
            <b>{approval.meta?.email}</b>
          </div>
        </div>
        <div className="container-fluid">
          <div>
            {`${language.words.components.approval.card.TELEGRAM_USERNAME}: `}
            <b>@{approval.user?.email?.replace(/@.*/, "")}</b>
          </div>
        </div>
      </div>
      <div className="separator" />
      <div className="status-wrapper">
        {approval.status === "in_progress" && (
          <div className="status-info warning">
            {language.words.components.approval.card.PENDING}
            <span className="bi bi-exclamation-circle"></span>
          </div>
        )}
        {approval.status === "declined" && (
          <div className="status-info danger">
            {language.words.components.approval.card.DECLINED}
            <span className="bi bi-x-circle"></span>
          </div>
        )}
        {approval.status === "completed" && (
          <div className="status-info success">
            {language.words.components.approval.card.ACCEPTED}
            <span className="bi bi-check-circle"></span>
          </div>
        )}
        <div
          className="btn btn-success"
          onClick={() => {
            setApprovalId(approval._id);
            setShowDetails(true);
          }}
        >
          <div>
            {approval.status === "in_progress"
              ? language.words.components.approval.card.CHECK
              : language.words.components.approval.card.DETAILS}
            &nbsp;<span className="bi bi-eye"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
