import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../../../Config";
import { useLanguage } from "../../../../../languages/useLanguage";
import { sendLog } from "../../../../../services/App";
import { getCoupon, updateCoupon } from "../../../../../services/Coupon";
import { randomIntBy } from "../../../../../Utils";
import "./Card.css";

const CardComponent = ({ couponInit, setAlert }) => {
  const { language } = useLanguage();
  const [coupon, setCoupon] = useState(couponInit);
  const [isLoading, setisLoading] = useState(false);

  const updateStatus = async (couponId, status) => {
    try {
      setisLoading(true);
      const _coupon = await updateCoupon(couponId, { status });

      if (!_coupon)
        return setAlert(language.words.components.coupon.card.UPDATE_ERROR);

      setCoupon(_coupon);
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message || error);
    } finally {
      setisLoading(false);
    }
  };

  const lookForStatusChanging = async (couponId) => {
    const _coupon = await getCoupon(couponId);

    if (_coupon.status === coupon.status) {
      await new Promise((resolve) =>
        setTimeout(resolve, randomIntBy(2, 5) * 1000)
      );

      return lookForStatusChanging(couponId);
    }

    setCoupon(_coupon);
  };

  useEffect(() => {
    const checkCandidate =
      (coupon.status === "unreleased" &&
        new Date(coupon.startDate).getTime() - Date.now() < 300000 &&
        new Date(coupon.startDate).getTime() - Date.now() > -300000) ||
      (coupon.status !== "expired" &&
        new Date(coupon.expirationDate).getTime() - Date.now() < 300000 &&
        new Date(coupon.expirationDate).getTime() - Date.now() > -300000);

    if (checkCandidate) {
      new Promise((resolve) => {
        setTimeout(resolve, randomIntBy(2, 5) * 200);
      }).then(() => {
        lookForStatusChanging(coupon._id);
      });
    } else {
      setCoupon(coupon);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="list-card">
      <div className="row">
        <div className="container-fluid">
          <div className="h1-header">{coupon.key}</div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-6">
          <div>
            {`${language.words.components.coupon.card.DISCOUNT}: `}
            <b>
              {coupon.value}
              {!/%/.test(coupon.value) && " €"}
            </b>
          </div>
        </div>
        <div className="col-6">
          {`${language.words.components.coupon.card.REDEMPTIONS}: `}
          <b>
            {coupon.uses?.used ?? "0"}{" "}
            {coupon.uses?.total && coupon.uses.total > 0
              ? " " +
                language.words.components.coupon.card.OF +
                " " +
                coupon.uses.total
              : ""}
          </b>
        </div>
      </div>
      {coupon.startDate && coupon.status === "unreleased" && (
        <div className="row">
          <div className="container-fluid">
            {`${language.words.components.coupon.card.START_DATE}: `}
            <b>{moment(coupon.startDate).format("DD-MM-YYYY HH:mm:ss")}</b>
          </div>
        </div>
      )}
      {coupon.expirationDate && (
        <div className="row">
          <div className="container-fluid">
            {`${language.words.components.coupon.card.EXPIRATION_DATE}: `}
            <b>{moment(coupon.expirationDate).format("DD-MM-YYYY HH:mm:ss")}</b>
          </div>
        </div>
      )}
      <div className="separator" />
      <div className="status-wrapper">
        <div
          className={
            "status-info" +
            (coupon.status === "enabled"
              ? " success"
              : coupon.status === "disabled"
              ? " warning"
              : coupon.status === "unreleased"
              ? " warning"
              : coupon.status === "expired"
              ? " danger"
              : coupon.status === "spent"
              ? " danger"
              : " danger")
          }
        >
          {coupon.status === "enabled" ? (
            <>
              {language.words.components.coupon.card.ENABLED}
              <span className="bi bi-check-circle"></span>
            </>
          ) : coupon.status === "disabled" ? (
            <>
              {language.words.components.coupon.card.DISABLED}
              <span className="bi bi-x-circle"></span>
            </>
          ) : coupon.status === "unreleased" ? (
            <>
              {language.words.components.coupon.card.UNRELEASED}
              <span className="bi bi-stopwatch"></span>
            </>
          ) : coupon.status === "expired" ? (
            <>
              {language.words.components.coupon.card.EXPIRED}
              <span className="bi bi-calendar-check"></span>
            </>
          ) : coupon.status === "spent" ? (
            <>
              {language.words.components.coupon.card.SPENT}
              <span className="bi bi-bag-x"></span>
            </>
          ) : (
            <>
              {language.words.components.coupon.card.UNKNOWN}
              <span className="bi bi-question-circle"></span>
            </>
          )}
        </div>
        <div className="status-wrapper">
          {coupon.status === "enabled" ? (
            <button
              className="action-button"
              disabled={isLoading}
              onClick={() => {
                updateStatus(coupon._id, "disabled");
              }}
            >
              <span className="bi bi-pause-circle"></span>
              {language.words.components.coupon.card.DISABLE}
            </button>
          ) : coupon.status === "disabled" || coupon.status === "unreleased" ? (
            <button
              className="action-button"
              disabled={isLoading}
              onClick={() => {
                updateStatus(coupon._id, "enabled");
              }}
            >
              <span className="bi bi-play-circle"></span>
              {language.words.components.coupon.card.ENABLE}
            </button>
          ) : (
            ""
          )}
          <Link
            className="btn btn-success"
            to={config.client.uris.coupons + "/" + coupon._id}
          >
            <span className="bi bi-pencil"></span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
