import Footer from "./footer/Footer";
import Header from "./header/Header";
import "./Layout.css";

const Layout = ({ header, footer, children }) => {
  return (
    <div className="container-flex layout">
      {header && (
        <div className="header">
          <Header />
        </div>
      )}
      <div className="content">{children}</div>
      {footer && (
        <div className="footer">
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Layout;
