import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getUser } from "../../../../services/User";
import { getProducts } from "../../../../services/Product";
import Card from "./components/Card";
import config from "../../../../Config";
import "./List.css";
import { useLanguage } from "../../../../languages/useLanguage";

export default function ProductListPage({ setAlert, type }) {
  const { language } = useLanguage();
  const [products, setProducts] = useState([]);

  const fetchProducts = async () => {
    const user = await getUser();

    if (!user) throw new Error(language.words.errors.INVALID_USER);

    const products = await getProducts(type);

    return products;
  };

  useEffect(() => {
    fetchProducts().then(setProducts);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container main products list">
      <div
        className="btn-navigate-back"
        onClick={() => window.location.replace(config.client.uris.products)}
      >
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {type === "suscription"
              ? language.words.components.product.list.MY_SUBSCRIPTIONS
              : type === "tip"
              ? language.words.components.product.list.MY_TIPS
              : ""}
          </div>
          <div className="separator" />
        </div>
        {products.map((product) => (
          <Card
            key={"product-" + product._id}
            setAlert={setAlert}
            productInit={product}
          />
        ))}
      </div>
      <Link
        type="button"
        className="btn btn-primary sticky-button"
        to={config.client.uris.products + "/create/" + type}
      >
        {type === "tip"
          ? language.words.components.product.list.CREATE_TIP
          : type === "suscription"
          ? language.words.components.product.list.CREATE_SUBSCRIPTION
          : ""}
      </Link>
    </div>
  );
}
