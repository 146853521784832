import { getApiUri } from "../Utils";
import { sendLog } from "./App";

export const getApprovals = async (status) => {
  const apiUri = getApiUri(window.location.hostname);

  const uri = apiUri + "/approvals" + (status ? "?status=" + status : "");

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) throw new Error("Error getting approvals");

  const approvals = await response.json();

  return approvals?.approvals || approvals;
};

export const getApproval = async (approvalId) => {
  try {
    const apiUri = getApiUri(window.location.hostname);

    const response = await fetch(apiUri + "/approvals/" + approvalId, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (response.status !== 200) throw new Error("Error getting approval");

    const approval = await response.json();

    return approval?.approval || approval;
  } catch (error) {
    sendLog("error", error);

    return null;
  }
};

export const requestInformation = async (approvalId, body) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/approvals/" + approvalId + "/review";

  const response = await fetch(uri, {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body,
  });

  if (response.status !== 200)
    throw new Error("Error requesting approval information");
};

export const changeStatus = async (approvalId, status) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/approvals/" + approvalId + "/" + status;

  const response = await fetch(uri, {
    method: "PUT",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) throw new Error("Error change approval status");
};
