import { useState } from "react";
import config from "../../../../../../Config";
import { useLanguage } from "../../../../../../languages/useLanguage";
import {
  moderateUser,
  setUserActive,
  updateUser,
} from "../../../../../../services/Admin";
import { getUser, resetApiToken } from "../../../../../../services/User";
import "./Card.css";
import { useNavigate } from "react-router-dom";

const CardComponent = ({ userInit, setAlert }) => {
  const { language } = useLanguage();
  const [user, setUser] = useState(userInit);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [showApiToken, setShowApiToken] = useState(false);

  const resetUserApiToken = async () => {
    const _user = await resetApiToken();

    setUser(_user);
  };

  const updateStatus = async (userId, status) => {
    try {
      setIsLoading(true);

      const user = await getUser();

      if (!user) throw new Error(language.words.errors.INVALID_USER);

      const response = await updateUser(userId, { status });

      setUser(response);
    } catch (error) {
      setAlert(error?.message, error);
    } finally {
      setIsLoading(false);
    }
  };

  const activateUser = async (userId, user) => {
    try {
      setIsLoading(true);

      const user = await getUser();

      if (!user) throw new Error(language.words.errors.INVALID_USER);

      const response = await setUserActive(userId, user);

      setUser(response);
    } catch (error) {
      setAlert(error?.message || error);
    } finally {
      setIsLoading(false);
    }
  };

  const moderate = async (userId) => {
    try {
      setIsLoading(true);

      const user = await getUser();

      if (!user) throw new Error(language.words.errors.INVALID_USER);

      await moderateUser(userId);

      window.location.replace(config.client.uris.products);
    } catch (error) {
      setAlert(error?.message, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="list-card user">
      <div className="title">
        <div className="h1-header">{user.firstname}</div>
        {user.status === "active" && (
          <button
            className="action-button"
            disabled={isLoading}
            onClick={() => {
              moderate(user._id);
            }}
          >
            <span className="bi bi-person-up"></span>
          </button>
        )}
      </div>
      <hr />
      <div className="row">
        <div className="container-fluid">
          <div>
            {language.words.components.admin.userCard.BETSFY_ID}:{" "}
            <b>{user.betsfyId}</b>
          </div>
          <div>
            {language.words.components.admin.userCard.EMAIL}:{" "}
            <b>{user.email}</b>
          </div>
          <div>
            {language.words.components.admin.userCard.PHONE_NUMBER}:{" "}
            <b>{user.phoneNumber}</b>
          </div>
          <div>
            {language.words.components.admin.userCard.NICKNAME}:{" "}
            <b>{user.nick}</b>
          </div>
          <div className="d-flex flex-row align-items-center">
            {language.words.components.admin.userCard.API_TOKEN}:{" "}
            <b
              className="d-flex flex-row align-items-center"
              style={{ textOverflow: "ellipsis" }}
            >
              <span
                role="button"
                title={
                  showApiToken
                    ? language.words.components.admin.userCard.API_TOKEN_HIDE
                    : language.words.components.admin.userCard.API_TOKEN_SHOW
                }
                className={"bi bi-eye" + (showApiToken ? "-slash" : "")}
                style={{
                  marginLeft: "5px",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
                onClick={(e) => setShowApiToken((prev) => !prev)}
              ></span>
              <span
                role="button"
                title={language.words.components.admin.userCard.API_TOKEN_COPY}
                className="bi bi-clipboard-check"
                style={{
                  marginLeft: "5px",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  navigator.clipboard.writeText(user.apiToken);
                  e.target.classList.add("color-green");
                  setTimeout(() => {
                    e.target.classList.remove("color-green");
                  }, 3000);
                }}
              ></span>
              <button
                className="btn btn-danger"
                style={{
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
                type="button"
                onClick={() => {
                  if (
                    window.confirm(
                      language.words.components.admin.userCard
                        .API_TOKEN_RESET_CONFIRM
                    )
                  ) {
                    resetUserApiToken();
                  }
                }}
              >
                {language.words.components.admin.userCard.API_TOKEN_RESET}
              </button>
            </b>
          </div>
          {showApiToken ? (
            <div style={{ wordBreak: "break-all" }}>
              <b>
                <span>{user.apiToken}</span>
              </b>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="separator" />
      <div className="status-wrapper">
        <div
          className={
            "status-info" +
            (user.status === "pending"
              ? " warning"
              : user.status === "inactive"
              ? " warning"
              : user.status === "active"
              ? " success"
              : " danger")
          }
        >
          {user.status === "pending" ? (
            <>
              {language.words.components.admin.userCard.PENDING}
              <span className="bi bi-hola"></span>
            </>
          ) : user.status === "inactive" ? (
            <>
              {language.words.components.admin.userCard.INACTIVE}
              <span className="bi bi-hola"></span>
            </>
          ) : user.status === "active" ? (
            <>
              {language.words.components.admin.userCard.ACTIVE}
              <span className="bi bi-hola"></span>
            </>
          ) : (
            <>
              {language.words.components.admin.userCard.BANNED}
              <span className="bi bi-hola"></span>
            </>
          )}
        </div>
        <div className="status-wrapper">
          {user.status === "pending" ? (
            <button
              className="action-button"
              disabled={isLoading}
              onClick={() =>
                navigate(config.client.uris.adminUsers + "/" + user._id)
              }
            >
              <span className="bi bi-eye"></span>
              {language.words.components.admin.userCard.REVIEW}
            </button>
          ) : user.status === "inactive" ? (
            <button
              className="action-button"
              disabled={isLoading}
              onClick={() => activateUser(user._id, user)}
            >
              <span className="bi bi-eye"></span>
              {language.words.components.admin.userCard.ACTIVATE}
            </button>
          ) : user.status === "active" ? (
            <button
              className="action-button"
              disabled={isLoading}
              onClick={() => {
                updateStatus(user._id, "ban");
              }}
            >
              <span className="bi bi-x-circle"></span>
              {language.words.components.admin.userCard.BAN}
            </button>
          ) : user.status === "ban" ? (
            <button
              className="action-button"
              disabled={isLoading}
              onClick={() => {
                updateStatus(user._id, "active");
              }}
            >
              <span className="bi bi-check-circle"></span>
              {language.words.components.admin.userCard.UNBAN}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
