import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../../Config";
import { useLanguage } from "../../../../languages/useLanguage";
import { getCoupons } from "../../../../services/Coupon";
import { getUser } from "../../../../services/User";
import Card from "./components/Card";
import "./List.css";

const CouponListPage = ({ setLoader, setAlert }) => {
  const { language } = useLanguage();
  const [coupons, setCoupons] = useState([]);

  const fetchCoupons = async () => {
    const user = await getUser();

    if (!user) throw new Error(language.words.errors.INVALID_USER);

    const _coupons = await getCoupons();

    return _coupons;
  };

  useEffect(() => {
    fetchCoupons().then(setCoupons);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container main products list">
      <div
        className="btn-navigate-back"
        onClick={() => window.location.replace(config.client.uris.products)}
      >
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {language.words.components.coupon.list.MY_COUPONS}
          </div>
          <div className="separator" />
        </div>
        {coupons.map((coupon) => {
          return (
            <Card
              key={"coupon-" + coupon._id}
              setAlert={setAlert}
              couponInit={coupon}
            />
          );
        })}
      </div>
      <Link
        type="button"
        className="btn btn-primary sticky-button"
        to={config.client.uris.coupons + "/create"}
      >
        {language.words.components.coupon.list.CREATE_COUPON}
      </Link>
    </div>
  );
};

export default CouponListPage;
