import React, { useState, useEffect } from "react";
import { useLanguage } from "../languages/useLanguage";

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export default function BotMenu(props) {
  const { language } = useLanguage();
  const [user, setUser] = useState(props.user);

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  if (
    !user?.status === "active" ||
    /login|register/.test(window.location.pathname)
  )
    return "";

  return (
    <div className="navbar mid">
      {user.bot && (
        <li className="nav-item">
          <span className="bot-info">
            <a
              className="bot-link"
              id="botLink"
              href={`https://t.me/${user.bot.username}`}
              target="_blank"
              rel="noreferrer"
            >
              {`${language.words.components.general.botMenu.SELLING_BOT}: `}
              <strong style={{ color: "white" }}>@{user.bot.username}</strong>
            </a>
          </span>
          <span>
            <input
              className="hide"
              id="botInput"
              type="text"
              defaultValue={`https://t.me/${user.bot.username}`}
            />
            <div className="thetooltip">
              <button
                className="copy-button"
                onClick={() =>
                  copyToClipboard(`https://t.me/${user.bot.username}`)
                }
              >
                <span className="copy-span">
                  <i className="fa fa-clipboard" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </span>
        </li>
      )}
      {user.promoBot && (
        <li className="nav-item">
          <span className="bot-info">
            <a
              className="bot-link"
              id="promoBotLink"
              href={`https://t.me/${user.promoBot.username}`}
              target="_blank"
              rel="noreferrer"
            >
              {`${language.words.components.general.botMenu.PROMO_BOT}: `}
              <strong style={{ color: "white" }}>
                @{user.promoBot.username}
              </strong>
            </a>
          </span>
          <span>
            <input
              className="hide"
              id="promoBotInput"
              type="text"
              defaultValue={`https://t.me/${user.promoBot.username}`}
            />
            <div className="thetooltip">
              <button
                className="copy-button"
                onClick={() =>
                  copyToClipboard(`https://t.me/${user.promoBot.username}`)
                }
              >
                <span className="copy-span">
                  <i className="fa fa-clipboard" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </span>
        </li>
      )}
    </div>
  );
}
