import { getApiUri } from "../Utils";

export const getPromo = async (promoId) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/promos/" + promoId;

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) throw new Error("Error getting promo");

  const promo = await response.json();

  return promo.promo || promo;
};

export const getPromos = async () => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = apiUri + "/promos";

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) throw new Error("Error getting dealings");

  const dealings = await response.json();

  return dealings?.dealings || dealings;
};

export const getPromosLanding = async (country = null) => {
  const apiUri = getApiUri(window.location.hostname);
  const uri = `${apiUri}/promos/landing${country ? `?country=${country}` : ""}`;

  const response = await fetch(uri, {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      // Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  if (response.status !== 200) throw new Error("Error getting dealings");

  const dealings = await response.json();

  return dealings?.dealings || dealings;
};
