import React, { Fragment } from "react";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import Logo from "../../../../../components/Logo";
import { useLanguage } from "../../../../../languages/useLanguage";
import { getPublicUri } from "../../../../../Utils";

export default function ShopLayout({
  product,
  discount,
  send,
  setCoupon,
  buttonValue,
  minPrice,
}) {
  const { language } = useLanguage();
  const [finalPrice, setFinalPrice] = useState(0);

  useEffect(() => {
    setFinalPrice((prev) => {
      return discount?.value
        ? /%/.test(discount.value)
          ? product.price * (1 - Number(discount.value.replace("%", "") / 100))
          : product.price - discount.value
        : product.price;
    });
  }, [product.price, discount]);

  return (
    <div className="container shop">
      <Logo />
      <div className="separator" />
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {language.words.components.shop.landing.PRODUCT_DATA}
          </div>
          <div className="separator" />
        </div>
        <div className="container-fluid">
          <div className="form">
            {product.image ? (
              <Fragment>
                <div className="shop-image--wrapper">
                  <img
                    className="shop-product-image"
                    src={
                      getPublicUri(window.location.hostname) + "/" + product.image
                    }
                    alt={language.words.components.shop.landing.PRODUCT}
                  />
                </div>
                <div className="separator" />
              </Fragment>
            ) : (
              ""
            )}
            <div className="shop-body">
              <div className="shop-product-title">{product.title}</div>
              <div className="gray-bar" />
              {product.type === "suscription" && (
                <Fragment>
                  <span className="shop-product-label">{`${language.words.components.shop.landing.DESCRIPTION}: `}</span>
                  <br />
                  <div className="shop-product-description">
                    {product.description}
                  </div>
                </Fragment>
              )}
              {product.type === "suscription" && (
                <div className="shop-product-duration">
                  <span className="shop-product-label">{`${language.words.components.shop.landing.DURATION}: `}</span>
                  {product.days < 36500 ? product.days : "\u221e"} dias
                </div>
              )}
              {product.type === "tip" && (
                <div className="shop-product-expiration">
                  <span className="shop-product-label">{`${language.words.components.shop.landing.EXPIRATION_DATE}: `}</span>
                  {moment(product.expirationDate).format("DD/MM/YYYY HH:mm:ss")}
                </div>
              )}
              <div className="shop-product-price">
                <span className="shop-product-label">{`${language.words.components.shop.landing.PRICE}:`}</span>{" "}
                {product.price} €
              </div>
              {discount?.value && (
                <>
                  <div className="shop-product-price">
                    <span className="shop-product-label">
                      {`${language.words.components.shop.landing.DISCOUNT_KEY}:`}
                    </span>{" "}
                    {discount.key +
                      ": -" +
                      discount.value +
                      (/%/.test(discount.value) ? "" : "€")}
                  </div>
                </>
              )}
              <div className="shop-product-title">
                {`${language.words.components.shop.landing.FINAL_PRICE}: `}
                {new Intl.NumberFormat("es-ES", {
                  style: "currency",
                  currency: "EUR",
                }).format(Math.max(minPrice, finalPrice))}
              </div>
              {finalPrice < minPrice ? (
                <div className="shop-product-info">
                  {`* ${language.words.components.shop.landing.FINAL_PRICE} `}
                  {new Intl.NumberFormat("es-ES", {
                    style: "currency",
                    currency: "EUR",
                  }).format(minPrice)}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="form-group">
              <div className="separator" />
              <label htmlFor="couponCode">
                {language.words.components.shop.landing.ENTER_DISCOUNT_KEY}
              </label>
              <input
                className="form-control"
                type="text"
                id="couponCode"
                name="couponCode"
                placeholder={
                  language.words.components.shop.landing.WRITE_IT_HERE
                }
              />
              <div className="separator" />
              <input
                className="btn btn-primary inverted"
                type="button"
                onClick={setCoupon}
                defaultValue={
                  language.words.components.shop.landing.APPLY_DISCOUNT
                }
              />
              <input
                className="btn btn-primary"
                type="button"
                onClick={send}
                value={buttonValue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
