import { useContext } from "react";
import { createContext, useEffect, useState } from "react";

export const LanguageContext = createContext({});

export const LanguageProvider = ({
  defaultValue,
  persisted,
  languages,
  children,
}) => {
  const [language, setLanguage] = useState(() => {
    if (persisted) {
      const storageValue = localStorage.getItem("tipster_language");

      const availableLanguages = languages.map((lang) => lang.value);

      if (
        storageValue &&
        availableLanguages.some((lang) => lang === storageValue)
      ) {
        return languages.filter(
          (lang) => lang.value === [JSON.parse(storageValue)]
        );
      } else {
        return defaultValue;
      }
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (persisted) {
      localStorage.setItem("tipster_language", JSON.stringify(language.value));
    }
  }, [language, persisted]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);

  return context;
};
