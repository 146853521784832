import React from "react";
import { Link, useLocation } from "react-router-dom";
import config from "../Config";
import { useLanguage } from "../languages/useLanguage";

export default function BottomMenu() {
  const { language } = useLanguage();
  const location = useLocation();

  const getActiveClassName = (section) => {
    return new RegExp(section, "i").test(location.pathname) ? "active" : "";
  };

  return (
    <div className="navbar bottom">
      <li className="nav-item">
        <Link
          className={"nav-link " + getActiveClassName("/products")}
          to={config.client.uris.products}
        >
          {language.words.components.general.bottomMenu.PRODUCTS}
        </Link>
        <span className="sepr-one"></span>
      </li>
      <li className="nav-item">
        <Link
          className={"nav-link " + getActiveClassName("/billing")}
          to={config.client.uris.billing}
        >
          {language.words.components.general.bottomMenu.BILLING}
        </Link>
        <span className="sepr-two"></span>
      </li>
      <li className="nav-item">
        <Link
          className={"nav-link " + getActiveClassName("/edit-profile")}
          to={config.client.uris.profile}
        >
          {language.words.components.general.bottomMenu.MY_PROFILE}
        </Link>
      </li>
    </div>
  );
}
