import { useEffect, useState } from "react";
import config from "../../../../../Config";
import { useLanguage } from "../../../../../languages/useLanguage";
import { useNavigate, useParams } from "react-router-dom";
import useInput from "../../../../../components/hooks/useInput";
import useTextarea from "../../../../../components/hooks/useTextarea";
import {
  getUserById,
  setUserPendingActive,
  setUserPendingIncomplete,
} from "../../../../../services/Admin";
import { getPublicUri } from "../../../../../Utils";
import "./Pending.css";

const UserPendingPage = ({ setLoader, setAlert }) => {
  const { language } = useLanguage();
  const [user, setUser] = useState({});
  const { userId } = useParams();
  const navigate = useNavigate();

  const firstname = useInput("");
  const nick = useInput("");
  const [profilePic, setprofilePic] = useState("");
  const biography = useInput("");
  const phoneNumber = useInput("");
  const [botAvatar, setBotAvatar] = useState("");
  const botName = useInput("");
  const promoBotName = useInput("");
  const betsfyId = useInput("");
  const notes = useTextarea("");

  const setAllValues = (data) => {
    setprofilePic(data.profilePic || "");
    firstname.setValue(data.firstname || "");
    nick.setValue(data.nick || "");
    biography.setValue(data.biography || "");
    phoneNumber.setValue(data.phoneNumber || "");
    setBotAvatar(data.pending?.botAvatar || "");
    botName.setValue(data.pending?.botName || "");
    promoBotName.setValue(data.pending?.promoBotName || "");
    betsfyId.setValue(data.betsfyId || "");
    notes.setValue(data.pending.notes || "");
  };

  const incompleteUser = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);

      const payload = {
        profilePic: profilePic,
        firstname: firstname.value,
        nick: nick.value,
        biography: biography.value,
        phoneNumber: phoneNumber.value,
        pending: {
          botAvatar: botAvatar,
          botName: botName.value,
          promoBotName: promoBotName.value,
          notes: notes.value,
        },
      };

      const user = await setUserPendingIncomplete(userId, payload);

      setUser(user);
    } catch (error) {
      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  const activateUser = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);

      const payload = {
        firstname: firstname.value,
        nick: nick.value,
        biography: biography.value,
        phoneNumber: phoneNumber.value,
        betsfyId: betsfyId.value,
      };

      const user = await setUserPendingActive(userId, payload);

      setUser(user);
    } catch (error) {
      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoader(true);

        const _user = await getUserById(userId);

        setUser(_user);
      } catch (error) {
        console.error(error);

        setAlert(error?.message || error);
      } finally {
        setLoader(false);
      }
    };

    fetchUser();
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?._id) {
      setAllValues(user);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    user?._id && (
      <div className="container main user-pending">
        <div
          className="btn-navigate-back"
          onClick={() => navigate(config.client.uris.adminUsers)}
        >
          {`<< ${language.words.navigation.BACK}`}
        </div>
        <div className="gray-block">
          <div className="container-fluid">
            <div className="separator" />
            <div className="h1-header">
              {language.words.components.admin.userPending.PENDING_USER}{" "}
              {user.firstname}
            </div>
            <div className="separator" />
            <div className="form">
              <div className="form-group">
                <label className="control-label" htmlFor="image">
                  {language.words.components.user.loginHash.AVATAR}
                </label>
                <div className="profile-pic">
                  <div className="profile-pic-wrapper">
                    <img
                      src={
                        profilePic
                          ? `${getPublicUri(
                              window.location.hostname
                            )}/${profilePic}`
                          : "https://dummyimage.com/100x100?text=" +
                            language.words.components.user.loginHash.AVATAR
                      }
                      alt={language.words.components.user.loginHash.AVATAR}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="firstname">
                  {language.words.components.user.loginHash.NAME}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="firstname"
                  id="firstname"
                  required={true}
                  {...firstname.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="nick">
                  {language.words.components.user.loginHash.USERNAME}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="nick"
                  id="nick"
                  {...nick.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="biography">
                  {language.words.components.user.loginHash.BIOGRAPHY}
                </label>
                <textarea
                  className="form-control"
                  name="biography"
                  id="biography"
                  maxLength={255}
                  style={{ height: "150px" }}
                  {...biography.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="phoneNumber">
                  {`${language.words.components.user.loginHash.PHONE_NUMBER} *`}
                </label>
                <input
                  className="form-control"
                  id="phoneNumber"
                  type="tel"
                  name="phoneNumber"
                  required={true}
                  {...phoneNumber.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="image">
                  {language.words.components.user.loginHash.BOT_AVATAR}
                </label>
                <div className="profile-pic">
                  <div className="profile-pic-wrapper">
                    <img
                      src={
                        botAvatar
                          ? `${getPublicUri(
                              window.location.hostname
                            )}/${botAvatar}`
                          : "https://dummyimage.com/100x100?text=" +
                            language.words.components.user.loginHash.BOT_AVATAR
                      }
                      alt={language.words.components.user.loginHash.BOT_AVATAR}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="botName">
                  {language.words.components.user.loginHash.BOT_NAME}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="botName"
                  id="botName"
                  {...botName.inputAttributes}
                />
              </div>
              <div className="form-group">
                <label className="control-label" htmlFor="promoBotName">
                  {language.words.components.user.loginHash.PROMO_BOT_NAME}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="promoBotName"
                  id="promoBotName"
                  {...promoBotName.inputAttributes}
                />
              </div>
              {user.status === "pending" ? (
                user.pending.status === "reviewing" ? (
                  <>
                    <form onSubmit={incompleteUser}>
                      <div className="form-group">
                        <label className="control-label" htmlFor="notes">
                          {language.words.components.admin.userPending.NOTES}
                        </label>
                        <textarea
                          className="form-control"
                          id="notes"
                          name="notes"
                          maxLength={255}
                          style={{ height: "150px" }}
                          required={true}
                          {...notes.textareaAttributes}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          value={
                            language.words.components.admin.userPending
                              .INCOMPLETE
                          }
                        />
                      </div>
                    </form>
                    <div className="separator" />
                    <form onSubmit={activateUser}>
                      <div className="form-group">
                        <label className="control-label" htmlFor="betsfyId">
                          Betsfy ID
                        </label>
                        <input
                          className="form-control"
                          id="betsfyId"
                          type="text"
                          name="betsfyId"
                          required={true}
                          {...betsfyId.inputAttributes}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          value={
                            language.words.components.admin.userPending.ACTIVATE
                          }
                        />
                      </div>
                    </form>
                  </>
                ) : (
                  <div className="info">
                    <label>
                      {
                        language.words.components.admin.userPending
                          .AWAITING_REGISTER
                      }
                    </label>
                  </div>
                )
              ) : (
                <div className="info">
                  <label>
                    {
                      language.words.components.admin.userPending
                        .NO_PENDING_USER
                    }
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default UserPendingPage;
