import { useEffect } from "react";
import { useState } from "react";
import { useLanguage } from "../../../../../languages/useLanguage";
import "./FilterModal.css";

const TypeFilterModal = ({ show, setShow, filter, setFilter }) => {
  const { language } = useLanguage();
  const [prefilter, setPrefilter] = useState([]);

  const toggleShow = () => {
    setShow((prev) => !prev);
  };

  const updatePrefilter = (_type) => {
    setPrefilter((prev) => {
      const exists = prev.includes(_type);

      if (exists) {
        return prev.filter((__type) => __type !== _type);
      } else {
        return [...prev, _type];
      }
    });
  };

  const clearAll = () => {
    setFilter((prev) => {
      return {
        ...prev,
        type: [],
      };
    });
    setShow(false);
  };

  const checkAll = () => {
    setPrefilter(filter.types);
  };

  const uncheckAll = () => {
    setPrefilter([]);
  };

  const applyFilter = () => {
    setFilter((prev) => {
      return {
        ...prev,
        type: prefilter,
      };
    });
    setShow(false);
  };

  useEffect(() => {
    setPrefilter(filter.type);
  }, [show, filter.type]);

  return (
    <div
      className={"modal fade" + (show && " show")}
      style={{
        display: "block",
        visibility: show ? "visible" : "hidden",
        maxHeight: "100vh",
        maxWidth: "100vw",
      }}
      onClick={toggleShow}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div
          className="filter-modal  modal-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-header">
            <div className="filter-title">
              <span
                className="bi bi-x-circle modal-close"
                onClick={toggleShow}
              ></span>{" "}
              {language.words.components.tracker.filter.TYPE_FILTER}
            </div>
            <span className="filter-clear" onClick={() => clearAll()}>
              {language.words.components.tracker.filter.CLEAR_ALL}
            </span>
          </div>
          <div
            className="modal-body"
            style={{ maxHeight: "65vh", overflowY: "auto" }}
          >
            <div className="filter-general">
              <div
                onClick={() => {
                  checkAll();
                }}
              >
                {language.words.components.tracker.filter.SELECT_ALL}
              </div>
              <div
                onClick={() => {
                  uncheckAll();
                }}
              >
                {language.words.components.tracker.filter.DESELECT_ALL}
              </div>
            </div>
            <div className="row">
              {filter.types?.map((_type) => {
                return (
                  <div key={_type} className="col-3">
                    <span
                      className={
                        "filter-choice" +
                        (prefilter?.some((__type) => __type === _type)
                          ? " active"
                          : " inactive")
                      }
                      onClick={() => {
                        updatePrefilter(_type);
                      }}
                    >
                      {_type?.toUpperCase()}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => applyFilter()}
            >
              {language.words.components.tracker.filter.APPLY}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeFilterModal;
