import useDragSort from "../../../../../components/hooks/useDragSort";
import { sortTrackers } from "../../../../../services/Tracker";
import "./TrackersSortModal.css";
import { useEffect } from "react";
import { useState } from "react";
import TypeFilterModal from "./TypeFilterModal";
import SiteFilterModal from "./SiteFilterModal";
import CountryFilterModal from "./CountryFilterModal";
import { useLanguage } from "../../../../../languages/useLanguage";
import { getPublicUri } from "../../../../../Utils";

const TrackersSortModal = ({
  show,
  setShow,
  _trackers,
  type,
  filter,
  setFilter,
}) => {
  const { language } = useLanguage();
  const [trackers, setTrackers] = useState([]);
  const {
    dragStart,
    drag,
    dragEnter,
    dragOver,
    drop,
    touchStart,
    touchMove,
    touchEnd,
    touchCancel,
    sortedData,
  } = useDragSort(trackers);
  const [showCountryFilter, setShowCountryFilter] = useState(false);
  const [showSiteFilter, setShowSiteFilter] = useState(false);
  const [showTypeFilter, setShowTypeFilter] = useState(false);

  const toggleShowSort = () => {
    setShow((prev) => !prev);
  };

  const sortTrackersOnDrop = async () => {
    const sortedIds = sortedData.map((tracker) => tracker._id);

    await sortTrackers(sortedIds, type);
  };

  useEffect(() => {
    setTrackers((prev) => {
      return _trackers
        ?.filter((tracker) => tracker[type]?.show)
        ?.sort((a, b) => {
          if (
            a[type]?.order !== null &&
            a[type]?.order !== undefined &&
            b[type]?.order !== null &&
            b[type]?.order !== undefined
          ) {
            return a[type].order - b[type].order;
          } else if (b[type]?.order !== null && b[type]?.order !== undefined) {
            return 1;
          } else {
            return -1;
          }
        });
    });
  }, [_trackers, type]);

  return (
    <div
      className={"modal fade" + (show && " show")}
      style={{
        display: "block",
        visibility: show ? "visible" : "hidden",
        maxHeight: "100vh",
        maxWidth: "100vw",
      }}
    >
      <CountryFilterModal
        {...{
          show: showCountryFilter,
          setShow: setShowCountryFilter,
          filter,
          setFilter,
        }}
      />
      <SiteFilterModal
        {...{
          show: showSiteFilter,
          setShow: setShowSiteFilter,
          filter,
          setFilter,
        }}
      />
      <TypeFilterModal
        {...{
          show: showTypeFilter,
          setShow: setShowTypeFilter,
          filter,
          setFilter,
        }}
      />
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div
          className="modal-content sort-modal"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-header">
            <div className="sort-header">
              <div className="sort-title">{`${language.words.components.tracker.sort.SORT_PROMOS} ${type}`}</div>
              <div className="trackers-filters">
                <div className="row">
                  <div className="col-4">
                    <div
                      className={
                        "filter" +
                        (filter.country.length === 0 ? " clear" : " active")
                      }
                      onClick={() => setShowCountryFilter(true)}
                    >
                      {`${language.words.components.tracker.sort.COUNTRY} `}
                      <span className="bi bi-caret-down"></span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      className={
                        "filter" +
                        (filter.site.length === 0 ? " clear" : " active")
                      }
                      onClick={() => setShowSiteFilter(true)}
                    >
                      {`${language.words.components.tracker.sort.BETTING_SITE} `}
                      <span className="bi bi-caret-down"></span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      className={
                        "filter" +
                        (filter.type.length === 0 ? " clear" : " active")
                      }
                      onClick={() => setShowTypeFilter(true)}
                    >
                      {`${language.words.components.tracker.sort.TYPE} `}
                      <span className="bi bi-caret-down"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-body"
            style={{
              position: "relative",
              maxHeight: "65vh",
              maxWidth: "100vw",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div className="card-wrapper">
              {sortedData?.map((tracker, index) => {
                return (
                  <div
                    key={"tracker-" + tracker._id}
                    className="draggable mini-card"
                    onDragEnter={(e) => dragEnter(e, index, tracker._id)}
                    onDragOver={(e) => dragOver(e)}
                    onDragEnd={(e) => {
                      drop(e);
                      sortTrackersOnDrop();
                    }}
                    data-index={index}
                    data-id={tracker._id}
                  >
                    <div
                      className="position"
                      draggable
                      onDragStart={(e) => dragStart(e, index, tracker._id)}
                      onDrag={(e) => drag(e)}
                      onTouchStart={(e) => touchStart(e, index, tracker._id)}
                      onTouchMove={(e) => touchMove(e)}
                      onTouchEnd={(e) => {
                        touchEnd(e);
                        sortTrackersOnDrop();
                      }}
                      onTouchCancel={(e) => touchCancel(e)}
                    >
                      <span
                        className="bi bi-grip-horizontal"
                        title="Ordenar arrastrando"
                      ></span>
                    </div>
                    <div className="promo-betting-image fixed-height">
                      <img
                        src={
                          getPublicUri(window.location.hostname) +
                          "/betting-sites/" +
                          tracker.promo.image
                        }
                        alt={tracker.promo.bettingSite}
                      />
                    </div>
                    <div className="country-image">
                      <img
                        src={
                          getPublicUri(window.location.hostname) +
                          "/countries/" +
                          tracker.promo.country.replace(/ /g, "_") +
                          ".png"
                        }
                        alt={tracker.promo.country}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={toggleShowSort}
            >
              {language.words.components.tracker.sort.CLOSE}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackersSortModal;
