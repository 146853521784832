import { useEffect } from "react";
import { useRef, useState } from "react";

const useDragSort = (data) => {
  const [sortedData, setSortedData] = useState(data);

  const dragElement = useRef();
  const dragOverElement = useRef();

  const elementDragged = useRef();
  const elementCloned = useRef();
  const offsetX = useRef();
  const offsetY = useRef();

  // drag sorting
  const dragStart = (e, index, id) => {
    e.dataTransfer.effectAllowed = "move";
    dragElement.current = { index, id };

    elementDragged.current = e.currentTarget.parentNode;

    if (elementDragged.current) {
      elementCloned.current = elementDragged.current.cloneNode(true);

      elementDragged.current.classList.add("dragging");

      elementCloned.current.classList.remove("draggable");
      elementCloned.current.classList.add("dragging-clone");
      elementDragged.current.parentNode?.append(elementCloned.current);

      offsetX.current =
        e.clientX - elementDragged.current.getBoundingClientRect().left;
      offsetY.current =
        e.clientY - elementDragged.current.getBoundingClientRect().top;
    }
  };
  const drag = (e) => {
    e.preventDefault();
    if (elementCloned.current) {
      const parent = elementCloned.current.parentNode;

      elementCloned.current.style.left = `${
        e.clientX - parent.getBoundingClientRect().left - (offsetX.current || 0)
      }px`;
      elementCloned.current.style.top = `${
        e.clientY - parent.getBoundingClientRect().top - (offsetY.current || 0)
      }px`;
    }
  };
  const dragEnter = (e, index, id) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    dragOverElement.current = { index, id };
    if (
      dragElement.current !== undefined &&
      dragOverElement.current !== undefined &&
      dragElement.current.id !== dragOverElement.current.id
    ) {
      const copyList = Object.assign([], sortedData);
      const dragContent = copyList[dragElement.current.index];
      copyList.splice(dragElement.current.index, 1);
      copyList.splice(dragOverElement.current.index, 0, dragContent);
      dragElement.current.index = dragOverElement.current.index;
      setSortedData(copyList);
    }
  };
  const dragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
  };
  const drop = (e) => {
    e.preventDefault();
    if (
      dragElement.current !== undefined &&
      dragOverElement.current !== undefined
    ) {
      const copyList = Object.assign([], sortedData);
      const dragContent = copyList[dragElement.current.index];
      copyList.splice(dragElement.current.index, 1);
      copyList.splice(dragOverElement.current.index, 0, dragContent);
      dragElement.current = undefined;
      dragOverElement.current = undefined;
      setSortedData(copyList);
    }
    if (elementDragged.current) {
      elementDragged.current.classList.remove("dragging");
      elementDragged.current = undefined;
    }
    if (elementCloned.current) {
      elementCloned.current.remove();
      elementCloned.current = undefined;
    }
  };

  // touch sorting
  const touchStart = (e, index, id) => {
    dragElement.current = { index, id };

    elementDragged.current = e.currentTarget.parentNode;

    if (elementDragged.current) {
      elementCloned.current = elementDragged.current.cloneNode(true);

      elementDragged.current.classList.add("dragging");

      elementCloned.current.classList.remove("draggable");
      elementCloned.current.classList.add("dragging-clone");
      elementDragged.current.parentNode?.append(elementCloned.current);

      offsetX.current =
        e.touches[0].clientX -
        elementDragged.current.getBoundingClientRect().left;
      offsetY.current =
        e.touches[0].clientY -
        elementDragged.current.getBoundingClientRect().top;

      const parent = elementCloned.current.parentNode;

      elementCloned.current.style.left = `${
        e.touches[0].clientX -
        parent.getBoundingClientRect().left -
        (offsetX.current || 0)
      }px`;
      elementCloned.current.style.top = `${
        e.touches[0].clientY -
        parent.getBoundingClientRect().top -
        (offsetY.current || 0)
      }px`;
    }
  };
  const touchMove = (e) => {
    const touches = e.touches[0];
    if (touches && elementCloned.current) {
      // positioning element dragged
      const parent = elementCloned.current.parentNode;

      elementCloned.current.style.left = `${
        touches.clientX -
        parent.getBoundingClientRect().left -
        (offsetX.current || 0)
      }px`;
      elementCloned.current.style.top = `${
        touches.clientY -
        parent.getBoundingClientRect().top -
        (offsetY.current || 0)
      }px`;

      // getting over event
      const elements = document.elementsFromPoint(
        touches.clientX,
        touches.clientY
      );

      for (let i = 0; i < elements.length; i++) {
        if (
          elements[i].classList.contains("draggable") &&
          !elements[i].classList.contains("dragging")
        ) {
          const index = elements[i].dataset.index;
          const id = elements[i].dataset.id;

          dragOverElement.current = { index, id };

          if (
            dragElement.current !== undefined &&
            dragOverElement.current !== undefined &&
            dragElement.current.id !== dragOverElement.current.id
          ) {
            const copyList = Object.assign([], sortedData);
            const dragContent = copyList[dragElement.current.index];
            copyList.splice(dragElement.current.index, 1);
            copyList.splice(dragOverElement.current.index, 0, dragContent);
            dragElement.current.index = dragOverElement.current.index;
            setSortedData(copyList);
          }

          return;
        }
      }
    }
  };
  const touchEnd = (e) => {
    if (
      dragElement.current !== undefined &&
      dragOverElement.current !== undefined
    ) {
      const copyList = Object.assign([], sortedData);
      const dragContent = copyList[dragElement.current.index];
      copyList.splice(dragElement.current.index, 1);
      copyList.splice(dragOverElement.current.index, 0, dragContent);
      dragElement.current = undefined;
      dragOverElement.current = undefined;
      setSortedData(copyList);
    }
    if (elementDragged.current) {
      elementDragged.current.classList.remove("dragging");
      elementDragged.current = undefined;
    }
    if (elementCloned.current) {
      elementCloned.current.remove();
      elementCloned.current = undefined;
    }
  };
  const touchCancel = (e) => {
    if (elementDragged.current) {
      elementDragged.current.classList.remove("dragging");
      elementDragged.current = undefined;
    }
    if (elementCloned.current) {
      elementCloned.current.remove();
      elementCloned.current = undefined;
    }
  };

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  return {
    dragStart,
    drag,
    dragEnter,
    dragOver,
    drop,
    touchStart,
    touchMove,
    touchEnd,
    touchCancel,
    sortedData,
  };
};

export default useDragSort;
