import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../../Config";
import { sendLog } from "../../../../services/App";
import { getCoupon, updateCoupon } from "../../../../services/Coupon";
import { getProducts } from "../../../../services/Product";
import { getUser } from "../../../../services/User";
import useInput from "../../../../components/hooks/useInput";
import ProductSelection from "../product/components/ProductSelection";
import { useLanguage } from "../../../../languages/useLanguage";

const CouponEditPage = ({ setLoader, setAlert }) => {
  const { language } = useLanguage();
  const [coupon, setCoupon] = useState({});
  const [products, setProducts] = useState([]);
  const [showProductSelection, setShowProductSelection] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  const key = useInput("");
  const valueType = useInput("flat");
  const valueInput = useInput("");
  const totalUses = useInput("0");
  const startDate = useInput(moment(Date.now()).format("YYYY-MM-DDTHH:mm"));
  const expirationDate = useInput("");

  const fetchProducts = async () => {
    const user = await getUser();

    if (!user) throw new Error(language.words.errors.INVALID_USER);

    const _products = await getProducts();

    const selectableProducts = _products.filter(
      (__product) => __product.status === "published" && __product.isPayment
    );

    coupon?.products?.forEach((product) => {
      if (!selectableProducts.some((p) => p._id === product._id)) {
        selectableProducts.unshift(product);
      }
    });

    return selectableProducts;
  };

  const send = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      await updateCoupon(coupon._id, {
        key: key.value,
        value,
        status: "unreleased",
        startDate: startDate.value,
        expirationDate: expirationDate.value,
        uses: {
          total: totalUses.value,
        },
        products: selectedProducts.map((product) => product._id),
      });

      navigate(config.client.uris.coupons);
    } catch (error) {
      sendLog("error", error);

      setAlert(error?.message || error);
    } finally {
      setLoader(false);
    }
  };

  // coupon key to uppercase
  useEffect(() => {
    key.setValue(key.value.toUpperCase());
  }, [key.value]); // eslint-disable-line react-hooks/exhaustive-deps

  // set value
  useEffect(() => {
    setValue((prev) => {
      if (valueType.value === "flat") {
        return valueInput.value;
      } else {
        return valueInput.value + "%";
      }
    });
  }, [valueType.value, valueInput.value]);

  // get products
  useEffect(() => {
    fetchProducts().then(setProducts).catch(console.error);
  }, [coupon.products]); // eslint-disable-line react-hooks/exhaustive-deps

  // get coupon to edit
  useEffect(() => {
    getCoupon(params.couponId)
      .then(setCoupon)
      .catch((error) => {
        console.error(error);
        setAlert(error?.message || error);
      });
  }, [params.couponId]); // eslint-disable-line react-hooks/exhaustive-deps

  // set all values
  useEffect(() => {
    if (coupon?._id) {
      key.setValue(coupon.key);
      /%/.test(coupon.value)
        ? valueType.setValue("percentage")
        : valueType.setValue("flat");
      valueInput.setValue(coupon.value.replace("%", ""));
      totalUses.setValue(coupon.uses.total);
      startDate.setValue((prev) => {
        return coupon.startDate
          ? moment(coupon.startDate).format("YYYY-MM-DDTHH:mm")
          : "";
      });
      expirationDate.setValue((prev) => {
        return coupon.expirationDate
          ? moment(coupon.expirationDate).format("YYYY-MM-DDTHH:mm")
          : "";
      });
      setSelectedProducts(coupon.products);
    }
  }, [coupon, products]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    coupon?._id && (
      <div className="container main">
        <ProductSelection
          {...{
            show: showProductSelection,
            setShow: setShowProductSelection,
            products,
            selectedProducts,
            setSelectedProducts,
          }}
        />
        <div className="btn-navigate-back" onClick={() => navigate(-1)}>
          {`<< ${language.words.navigation.BACK}`}
        </div>
        <div className="gray-block">
          <div className="container-fluid">
            <div className="row">
              <div className="container-fluid">
                <div className="separator" />
                <div className="h1-header">
                  {language.words.components.coupon.edit.EDIT_COUPON}{" "}
                </div>
                <div className="separator" />
              </div>
            </div>
            <div className="form">
              <form onSubmit={send}>
                <div className="form-group">
                  <label htmlFor="key" className="control-label">
                    {`${language.words.components.coupon.edit.KEY} *`}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={language.words.components.coupon.edit.KEY}
                    pattern="^\S+$"
                    required
                    {...key.inputAttributes}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="value" className="control-label">
                    {`${language.words.components.coupon.edit.DISCOUNT} *`}
                  </label>
                  <div {...valueType.inputAttributes}>
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="valueType"
                        id="valueTypeFlat"
                        value="flat"
                        defaultChecked={true}
                      />
                      <label
                        htmlFor="valueTypeFlat"
                        className="form-check-label"
                      >
                        {language.words.components.coupon.edit.FLAT_DISCOUNT}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="valueType"
                        id="valueTypePercentage"
                        value="percentage"
                      />
                      <label
                        htmlFor="valueTypePercentage"
                        className="form-check-label"
                      >
                        {
                          language.words.components.coupon.edit
                            .PERCENTAGE_DISCOUNT
                        }
                      </label>
                    </div>
                  </div>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      step={valueType.value === "flat" ? 0.01 : 1}
                      min={valueType.value === "flat" ? 0.01 : 1}
                      max={valueType.value === "flat" ? 350 : 100}
                      id="value"
                      placeholder={
                        language.words.components.coupon.edit.DISCOUNT
                      }
                      required
                      {...valueInput.inputAttributes}
                    />
                    <span className="input-group-text">
                      {valueType.value === "flat"
                        ? "€"
                        : valueType.value === "percentage"
                        ? "%"
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="totalUses" className="control-label">
                    {language.words.components.coupon.edit.NUMBER_OF_COUPONS +
                      " (" +
                      language.words.components.coupon.edit.ZERO_UNLIMITED +
                      ")"}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    step={1}
                    min={0}
                    id="totalUses"
                    placeholder={
                      language.words.components.coupon.edit.NUMBER_OF_COUPONS
                    }
                    required
                    {...totalUses.inputAttributes}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="expirationDate" className="form-label">
                    {`${language.words.components.coupon.edit.START_DATE} *`}
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    name="expirationDate"
                    id="expirationDate"
                    required
                    {...startDate.inputAttributes}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="expirationDate" className="form-label">
                    {language.words.components.coupon.edit.EXPIRATION_DATE +
                      " (" +
                      language.words.components.coupon.edit.EMPTY_NO_EXPIRE +
                      ")"}
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    name="expirationDate"
                    id="expirationDate"
                    {...expirationDate.inputAttributes}
                  />
                </div>
                <div className="form-group">
                  {selectedProducts.length === 0 ? (
                    <label>
                      {language.words.components.coupon.edit.APPLICABLE_TO +
                        " " +
                        language.words.components.coupon.edit.ALL_PRODUCTS}
                    </label>
                  ) : selectedProducts.length === 1 ? (
                    <label>
                      {language.words.components.coupon.edit.APPLICABLE_TO +
                        " " +
                        language.words.components.coupon.edit.ONE_PRODUCT}
                    </label>
                  ) : (
                    <label>
                      {language.words.components.coupon.edit.APPLICABLE_TO +
                        " " +
                        selectedProducts.length +
                        " " +
                        language.words.components.coupon.edit.PRODUCTS}
                    </label>
                  )}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setShowProductSelection(true)}
                  >
                    {language.words.components.coupon.edit.SELECT_PRODUCTS}
                  </button>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary create">
                    {language.words.components.coupon.edit.EDIT_COUPON}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default CouponEditPage;
