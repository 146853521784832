import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../../../languages/useLanguage";
import { getWebLandings } from "../../../../../services/Admin";
import { getUser } from "../../../../../services/User";
import Card from "./components/Card";

const AdminWebLandingPage = ({ setAlert }) => {
  const { language } = useLanguage();
  const [webLandings, setWebLandings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWebLandings = async () => {
      try {
        const user = await getUser();

        if (!user) new Error(language.words.errors.INVALID_USER);

        const response = await getWebLandings();

        setWebLandings(response);
      } catch (error) {
        setAlert(error?.message || error);
      }
    };

    fetchWebLandings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container main products list">
      <div className="btn-navigate-back" onClick={() => navigate(-1)}>
        {`<< ${language.words.navigation.BACK}`}
      </div>
      <div className="gray-block">
        <div className="container-fluid">
          <div className="separator" />
          <div className="h1-header">
            {language.words.components.admin.webLanding.WEB_LANDING}
          </div>
          <div className="separator" />
        </div>
        {webLandings &&
          webLandings.length > 0 &&
          webLandings.map((webLanding) => {
            return (
              <Card
                key={"web-landing-" + webLanding._id}
                webLanding={webLanding}
              />
            );
          })}
      </div>
    </div>
  );
};

export default AdminWebLandingPage;
